import Vue from 'vue'
import App from './App.vue'

import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { BAlert } from 'bootstrap-vue'
import VueConfetti from 'vue-confetti'
import { BootstrapVueIcons } from 'bootstrap-vue'


Vue.use(BootstrapVueIcons)




import router from '@/router/index.js'

import { dollarFilter, tezosFilter, mutezFilter, entryFilter } from '@/filters'

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(VueConfetti)

Vue.filter('dollar', dollarFilter)
Vue.filter('tezos', tezosFilter)
Vue.filter('mutez', mutezFilter)
Vue.filter('entry', entryFilter)

Vue.component('b-alert', BAlert)



Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
