import { BLIND_GALLERY_API } from '@/constants'

const claimPendingBadges = async (wallet, edition) => {

  let url = `https://${BLIND_GALLERY_API}/v1/mint`;

  let options = {
    method: 'POST',
    headers: {
      Accept: '*/*',
      // 'User-Agent': 'Blind Gallery App',
      'Content-Type': 'application/json'
    },
    body: `{"wallet":"${wallet}","edition_name":"${edition}"}`
  };
  const data = await fetch(url, options)
  const badges = await data.json()
  return badges
}

export default claimPendingBadges;
