<template>
  <body>
    <PxAlerts ref="alerts" />
    <b-container
      ><b-row>
        <b-col>
          <div class="position-relative academy-container">
            <div
              class="d-md-flex d-block flex-column align-items-center justify-content-center position-absolute w-100 h-100 academy-info p-4"
            >
              <img
                src="https://moccasin-perfect-trout-941.mypinata.cloud/ipfs/QmPTmYjGbv1RgTCfV8sPscvhFvECz2WSsNGuYsG8yNW2cQ"
                width="200px"
                class="mb-4"
              />
              <h1><b>Introducing Academy by Blind Gallery!</b></h1>
              <p>
                We are thrilled to present our new educational platform, your
                starting point to learn blockchain art.
              </p>
              <div>
                <a href="https://academy.blindgallery.xyz/" target="_blank">
                  <button class="academy-btn">Try it now</button>
                </a>
              </div>
            </div>
            <div class="academy-layer"></div>
            <div class="academy-background d-md-block d-none">
              <img
                src="https://moccasin-perfect-trout-941.mypinata.cloud/ipfs/QmdrfgeDKpZPubbMxnkYsc4yESpy84Kd11gujjp7pxAU3s"
                class="w-100"
                style="object-fit: cover; border-radius: 5px"
              />
            </div></div
        ></b-col> </b-row
    ></b-container>

    <b-container>
      <!--NEWS SECTION-->
      <b-row style="margin-top: 2rem">
        <h2 class="mb-4" style="font-weight: 600">Our latest updates</h2>

        <b-col
          cols="12"
          md="4"
          style="display: flex; justify-content: flex-end"
        >
          <b-card
            img-top
            style="max-width: 37rem; border: none"
            class="mb-2 shadow-sm"
          >
            <b-img
              src="https://moccasin-perfect-trout-941.mypinata.cloud/ipfs/Qmd3J5UR1k2RaXEqAid3nuEiq61jkA9s6j933CYnfcHDVk"
              style="height: 250px; width: 100%; object-fit: cover"
              alt="Club Membership"
            ></b-img>
            <h5 class="pt-4">Academy by Blind Gallery</h5>
            <b-card-text>
              Explore interactive courses led by industry experts, paving your
              way in the digital art revolution.
            </b-card-text>

            <a
              class="secondary-button"
              href="https://blindgallery.substack.com/p/introducing-academy-by-blind-gallery"
              target="_blank"
              >Learn more <b-icon icon="chevron-right" font-scale="0."></b-icon
            ></a>
          </b-card>
        </b-col>
        <b-col
          cols="12"
          md="4"
          style="display: flex; justify-content: flex-end"
        >
          <b-card
            img-top
            style="max-width: 37rem; border: none"
            class="mb-2 shadow-sm"
          >
            <b-img
              src="https://gateway.fxhash.xyz/ipfs/QmQXmrvnjRAtV4qdbZ83yZybziiHpnwKEwYc3bgDE1PCyn"
              style="height: 250px; width: 100%; object-fit: cover"
              alt="Club Membership"
            ></b-img>
            <h5 class="pt-4">Vistas Edition</h5>
            <b-card-text>
              In collaboration with Feral File and a group of talented artists,
              the Vistas Edition has been minted on fxhash.
            </b-card-text>

            <router-link class="secondary-button" to="/fourth-edition"
              >View edition
              <b-icon icon="chevron-right" font-scale="0."></b-icon
            ></router-link>
          </b-card>
        </b-col>
        <b-col cols="12" md="4" class="d-flex justify-content-start">
          <b-card
            img-top
            style="max-width: 37rem; border: none"
            class="mb-2 shadow-sm"
          >
            <div>
              <b-img
                src="https://gateway.fxhash.xyz/ipfs/QmRWw379jfmp7Ca2cARcg7fctVAK7qHEmTdznPrUgNQdgG"
                style="height: 250px; width: 100%; object-fit: cover"
                alt="Club Membership"
              ></b-img>
              <h5 class="pt-4">Introducing Limited Collections</h5>
              <b-card-text>
                Find out what's next for the Blind Gallery, including a new
                series of releases starting very soon...
              </b-card-text>
            </div>
            <a
              href="https://blindgallery.substack.com/limited-collections"
              target="_blank"
              class="secondary-button"
              >Learn more <b-icon icon="chevron-right" font-scale="0."></b-icon
            ></a>
          </b-card>
        </b-col>
      </b-row>

      <div
        class="w-100 d-flex justify-content-end flex-column p-4 rounded editions-collections mt-5 mb-5"
      >
        <div class="black-layer rounded p-4"></div>
        <div style="z-index: 2">
          <h1 style="color: #fff"><b>Editions and Limited Collections</b></h1>
          <p style="color: #fff">
            Discover all the editions and limited collections that have been
            launched at the Blind Gallery.
          </p>
          <router-link to="/art"
            ><button style="padding: 0.5rem 5.5rem" class="primary-button">
              Explore
            </button>
          </router-link>
        </div>
      </div>

      <b-row style="margin: 5rem 0rem">
        <b-col cols="12" lg="6"
          ><div class="d-md-flex align-items-center h-auto">
            <img
              src="https://moccasin-perfect-trout-941.mypinata.cloud/ipfs/QmVYvwevMZYw48uEiUvYrf8epvHuURi5BtsFrhBhxq4vYi"
              alt="Incubator"
              width="150px"
              class="m-4"
            />

            <div>
              <h4>Art Incubator</h4>
              <p class="text-secondary">
                The Art Incubator is a place for artists pushing the boundaries
                between technology and art to experiment and release artworks
                using the blind concept.
              </p>
              <router-link
                to="/incubator"
                style="text-decoration: none; color: inherit"
              >
                <button class="secondary-button">
                  Learn more
                  <b-icon icon="chevron-right" font-scale="0."></b-icon>
                </button>
              </router-link>
            </div></div></b-col
        ><b-col cols="12" lg="6"
          ><div class="d-md-flex align-items-center">
            <img
              src="https://moccasin-perfect-trout-941.mypinata.cloud/ipfs/QmS3nZKUNjffHFAHpsgJrG4fXcB9ANcbS2ZDPBnM6EtLqN"
              alt="Badges"
              width="160px"
              class="m-4"
            />

            <div>
              <h4>Blind Gallery Badges</h4>
              <p class="text-secondary">
                You can earn Badges by participating in Blind Gallery events.
              </p>
              <a
                href="https://blindgallery.substack.com/p/clubarea"
                target="_blank"
                class="secondary-button"
              >
                Learn more
                <b-icon icon="chevron-right" font-scale="0."></b-icon>
              </a>
            </div></div
        ></b-col>
      </b-row>
    </b-container>
  </body>
</template>

<script>
const signalR = require("@microsoft/signalr");

import get_fxhash_collection_info from "@/api/get_fxhash_collection_info.js";
import "swiper/swiper-bundle.css";

import { CONTRACTS } from "@/constants";
import { Tezos, get_contract } from "@/tezos/wallet";

import getFxhashCollectionBalance from "@/api/get_fxhash_collection_balance.js";
import { exchangeMintPass } from "@/tezos/bg_calls.js";
import PxAlerts from "@/components/PxAlerts.vue";

import store from "@/store";
export default {
  components: {
    PxAlerts,
  },
  name: "ViewHome",

  data() {
    const startDate = new Date();
    const endDate = new Date("2023-08-31T16:00:00Z");

    return {
      time: endDate - startDate,
      supply: 750,
      balance: 750,
      store,
      enableWatcher: false,
      mintPasses: {
        total: 0,
        previews: [],
        ids: [],
      },
    };
  },

  methods: {
    async updateContractStorage() {
      const contract = await Tezos.wallet.at(CONTRACTS.sales.address);
      const storage = await contract.storage();
      for (const collection in this.collectionsData) {
        const swaps = storage.FXcollections.get(collection);
        if (swaps) {
          this.collectionsData[collection].available = 150 - swaps.c[0];
        }

        if(!store.user_address) { continue }

        const exchanges = storage.FXExchanges.get({
          0: store.user_address,
          1: collection,
        });
        if (exchanges) {
          this.collectionsData[collection].collected = exchanges.c[0];
        }
      }
    },
    async updateCollectionInfo() {
      const collectionInfo = await get_fxhash_collection_info(28710);
      this.supply = collectionInfo.supply;
      this.balance = collectionInfo.balance;
    },
    async updateUserBalance() {
      if (!store.user_address) return;
      const mintPasses = await getFxhashCollectionBalance(store.user_address);
      this.mintPasses.total = mintPasses.total;
      this.mintPasses.previews = mintPasses.previews;
      this.mintPasses.ids = mintPasses.ids;
    },
    resetUserData() {
      this.mintPasses.total = 0;
      this.mintPasses.previews = [];
      this.mintPasses.ids = [];
      this.updateContractStorage()
    },
    async exchange(sku) {
      if (!store.user_address) return;
      if (!this.mintPasses.total) return;
      console.log(this.mintPasses.ids[0], sku);
      this.$refs.alerts.toggleTransaction();
      try {
        const op = await exchangeMintPass(this.mintPasses.ids[0], sku);
        if (op.completed) {
          this.collectionsData[sku].available -= 1;
          this.collectionsData[sku].collected += 1;
          this.mintPasses.ids.shift();
          this.mintPasses.total -= 1;
          this.$refs.alerts.showSuccessAlert("Transaction successfully");
        } else {
          this.$refs.alerts.showErrorAlert("Transaction failed");
        }
      } catch (e) {
        this.$refs.alerts.showErrorAlert(e.description);
      }
      this.$refs.alerts.toggleTransaction();
      this.updateContractStorage();
    },
    async initSubscription() {
      // open connection
      await this.connection.start();
      // subscribe to account transactions
      await this.connection.invoke("SubscribeToOperations", {
        address: CONTRACTS.sales.address,
        types: "transaction",
      });
      await this.connection.invoke("SubscribeToBigMaps", {
        contract: CONTRACTS.sales.address,
      });
    },
  },
  onMounted() {
    this.updateUserBalance();
  },
  created() {
    this.updateCollectionInfo();
    this.updateUserBalance();
    this.updateContractStorage();
    this.enableWatcher = true;

    // Blockchain watcher
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl("https://api.tzkt.io/v1/ws")
      .build();
    this.connection.onclose(this.initSubscription);

    this.connection.on("operations", (msg) => {
      this.collections.forEach((collection) => {
        console.log(msg.data[0].storage["FXSupply"][collection.title]);
        this.collectionsData[collection.title].available =
          msg.data[0].storage["FXSupply"][collection.title];
      });
      console.log(msg.data[0].storage["FXExchanges"]);

      // this.collected_pieces = Object.values(
      //   msg.data[0].storage["ObjktExchanges"]
      // ).filter((x) => x == store.user_address).length;
    });

    this.initSubscription();
  },
  watch: {
    // Note: only simple paths. Expressions are not supported.
    "store.user_address"(newAddress) {
      if (newAddress && this.enableWatcher) {
        this.resetUserData();
        this.updateUserBalance();
      } else {
        this.resetUserData();
      }
    },
  },
};
</script>

<style>
.editions-collections {
  height: 400px;
  position: relative;
  background-image: url("https://ipfs.io/ipfs/QmZ8wihiztCgHAVH8QBtDL1sDPiaS542wUk8ZjeJqYeqtd");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.black-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.658);
  z-index: 1;
}

.carrousel-container {
  margin: auto;
  position: relative;
  display: grid;
  place-items: center;
  overflow: hidden;
  width: 100%;
  margin-bottom: 2rem;
}

.carrousel-track {
  display: flex;
  width: calc(320px * 12);
  animation: scroll 40s linear infinite;
  position: relative;
}

.swiper-wrapper {
  align-items: center;
}

.swiper-container {
  height: 620px;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-320px * 6));
  }
}

.carrousel-item {
  height: 370px;
  margin: 0rem 1rem;
}

.carrousel-item img {
  height: 100%;
  object-fit: contain;
}

.step {
  background-color: #fff;
  padding: 1rem;
  border-radius: 5px;
  height: 95%;
}

.swiper-button-next,
.swiper-button-prev {
  background-color: #ffffff50;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 1rem;
  color: #000000;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: ease all 0.3s;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  background-color: #fff;
}

.swiper-button-next:after {
  font-size: 20px;
  font-weight: 600;
  margin-left: 2px;
}

.swiper-button-prev:after {
  font-size: 20px;
  font-weight: 600;
  margin-right: 2px;
}

.academy-container {
  position: relative;
  height: 400px;
}

.academy-info {
  color: #fff;
  z-index: 3;
}

.academy-layer {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  background-color: #0093a3;
  border-radius: 5px;
  mix-blend-mode: multiply;
}

.academy-background {
  z-index: 1;
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 0.2;
}

.academy-btn {
  background-color: transparent;
  border: 1px solid #ffff;
  color: #ffff;
  border-radius: 5px;
  padding: 0.5rem;
  width: 150px;
  transition: 0.3s;
}

.academy-btn:hover {
  transition: 0.3s;
  background-color: #fff;
  color: #0093a3;
}

@media (max-width: 425px) {
  .academy-container {
    height: 380px;
  }
  .carrousel-container {
    height: 40vh;
  }
  .carrousel-item {
    height: 300px;
  }
  .swiper-container {
    height: 500px;
  }
}
</style>
