var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('PxClubUnsync',{directives:[{name:"show",rawName:"v-show",value:(!_vm.store.wallet | !_vm.store.user_address),expression:"!store.wallet | !store.user_address"}]}),_c('b-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.store.wallet && _vm.store.user_address),expression:"store.wallet && store.user_address"}],staticClass:"club-desktop"},[_c('b-col',{staticClass:"side-nav-container",attrs:{"lg":"1"}},[_c('b-nav',{staticClass:"side-nav",attrs:{"vertical":""}},[_c('b-nav-item',{on:{"click":function($event){return _vm.toBenefitsClub()}}},[_c('span',{class:_vm.showBenefits === true
                ? 'material-symbols-sharp'
                : 'material-symbols-outlined',staticStyle:{"font-size":"26px"}},[_vm._v(" add_card ")]),_c('span',{staticStyle:{"font-size":"small"}},[_vm._v("Benefits")])]),_c('b-nav-item',{on:{"click":function($event){return _vm.toBadgesClub()}}},[_c('span',{class:_vm.showBadgesContainer === true
                ? 'material-symbols-sharp'
                : 'material-symbols-outlined',staticStyle:{"font-size":"26px"}},[_vm._v(" workspace_premium ")]),_c('span',{staticStyle:{"font-size":"small"}},[_vm._v("Badges")])]),_c('b-nav-item',{on:{"click":function($event){return _vm.toIncubatorClub()}}},[_c('span',{class:_vm.showIncubatorContainer === true
                ? 'material-symbols-sharp'
                : 'material-symbols-outlined',staticStyle:{"font-size":"26px"}},[_vm._v(" how_to_vote ")]),_c('span',{staticStyle:{"font-size":"small"}},[_vm._v("Incubator")])])],1)],1),_c('b-col',{staticStyle:{"margin-left":"10rem"},attrs:{"cols":"12","lg":"11"}},[_c('PxClubBenefits',{directives:[{name:"show",rawName:"v-show",value:(_vm.showBenefits),expression:"showBenefits"}],ref:"showBenefits",staticClass:"benefits-container",attrs:{"benefits":this.benefits}}),_c('PxClubBadges',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showBenefits && _vm.showBadgesContainer),expression:"!showBenefits && showBadgesContainer"}],ref:"showBadgesContainer",attrs:{"badges":this.badges,"pendingBadges":this.pendingBadges},on:{"update-badge":_vm.doUpdateBadge}}),_c('PxClubIncubator',{directives:[{name:"show",rawName:"v-show",value:(
          !_vm.showBenefits && !_vm.showBadgesContainer && _vm.showIncubatorContainer
        ),expression:"\n          !showBenefits && !showBadgesContainer && showIncubatorContainer\n        "}],ref:"showIncubatorContainer"})],1)],1),_c('b-container',{directives:[{name:"show",rawName:"v-show",value:(_vm.store.wallet && _vm.store.user_address),expression:"store.wallet && store.user_address"}],staticClass:"responsive-disclaimer"},[_c('b-icon',{staticStyle:{"width":"90px","height":"90px"},attrs:{"icon":"exclamation-circle-fill"}}),_c('h2',[_vm._v(" The Club Area is only available on "),_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v("Desktop")]),_vm._v(" at the moment. ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }