import Vue from "vue";
import Router from "vue-router";

import ViewHome from "@/views/ViewHome";
import ViewError from "@/views/ViewError";
import ViewOldBlindGallery from "@/views/ViewOldBlindGallery";
import ViewBearBlindGallery from "@/views/ViewBearBlindGallery";
import ViewSeedEd from "@/views/ViewSeedEd";
import ViewVistasEd from "@/views/ViewVistasEd";
import ViewIncubator from "@/views/ViewIncubator";
import ViewAdminPage from "@/views/ViewAdminPage";
import ViewClub from "@/views/ViewClub";
import ViewSponsor from "@/views/ViewSponsor";
import ViewArt from "@/views/ViewArt";
import ViewFAQ from "@/views/ViewFAQ";
import ViewAuction from "@/views/Auctions";
import { BIcon } from "bootstrap-vue";
Vue.component("b-icon", BIcon);

Vue.use(Router);

export default new Router({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },

  routes: [
    { path: "/", name: "home", component: ViewHome },
    {
      path: "/first-edition",
      name: "first_edition",
      component: ViewOldBlindGallery,
    },
    {
      path: "/second-edition",
      name: "second_edition",
      component: ViewBearBlindGallery,
    },
    {
      path: "/third-edition",
      name: "third_edition",
      component: ViewSeedEd,
    },
    {
      path: "/fourth-edition",
      name: "fourth_edition",
      component: ViewVistasEd,
    },
    { path: "/admin", name: "admin", component: ViewAdminPage },
    { path: "/club", name: "view_club", component: ViewClub },
    { path: "/incubator", name: "incubator", component: ViewIncubator },
    { path: "/sponsor", name: "view_sponsor", component: ViewSponsor },
    { path: "/art", name: "view_art", component: ViewArt },
    { path: "/auction", name: "view_auction", component: ViewAuction },
    { path: "*", name: "error", component: ViewError },

    { path: "/FAQ", name: "FAQ", component: ViewFAQ },
  ],
});
