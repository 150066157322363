<template>

  <body>
    <PxAlerts ref="alerts"/>
    <!-- Gallery -->
    <PxCardOld v-bind:tokens="tokens" @toggleTransaction="toggleTransaction" @updateErrorMessage="updateErrorMessage"
      @updateSucessMessage="updateSuccessMessage" />
  </body>
</template>

<script>
import PxCardOld from "@/components/PxCardOld.vue";
import PxAlerts from "@/components/PxAlerts.vue";

import update_token_metadata from "@/store/metadata/getters";

export default {
  name: "OldBlindGallery",
  components: {
    PxCardOld,
    PxAlerts
  },

  data() {
    return {
      tokens: [],
    };
  },

  created() {
    update_token_metadata("first_collection").then((tokens) => {
      this.tokens = tokens;
    });
  },
  methods: {
    toggleTransaction() {
      this.$refs.alerts.toggleTransaction();
    },
    updateErrorMessage(message) {
      this.$refs.alerts.showErrorAlert(message);
    },
    updateSuccessMessage(message) {
      this.$refs.alerts.showSuccessAlert(message);
    }
  }
};
</script>
