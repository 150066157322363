import { Tezos } from "@/tezos/wallet";
import { OpKind } from "@taquito/taquito";
import store from "@/store/index";
import { DEFAULT_CONFIRMATION_BLOCKS } from "@/constants.js";

export const fulfill_ask = async (bigmap_key, _token_id, amount, c = DEFAULT_CONFIRMATION_BLOCKS) => {
  if (!store.contracts.objkt) {
    await store.getContracts();
  }
  const batch_operation = await Tezos.wallet
    .batch([
      {
        kind: OpKind.TRANSACTION,
        ...store.contracts.objkt.methods
          .fulfill_ask(bigmap_key)
          .toTransferParams(),
        amount: amount / 1000000, //! the amount is in mutez
      },
    ])
    .send();

  const confirmation = await batch_operation.confirmation(c);
  return confirmation
}
