<template>

  <body>
    <PxAlerts ref="alerts"/>
    <div class="container mint-pass" id="mint-pass">
      <div class="row">
        <div class="col-md-12">
          <h1>Admin Page</h1>
        </div>
      </div>
    </div>

    <!-- Set pause -->
    <div class="container" style="padding-bottom: 30px">
      <div class="row">
        <div class="col-md-12">
          <h2>Set pause</h2>
          <b-row class="my-1">
            <b-col sm="2">
              <label for="input-default">Is paused:</label>
            </b-col>
            <b-col sm="10">
              {{ store.blind_gallery_storage.paused }}
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="2"></b-col>
            <b-col sm="10">
              <b-button variant="primary" @click="set_pause">Set pause</b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>

    <!-- Add moderator -->
    <div class="container" style="padding-bottom: 30px">
      <div class="row">
        <div class="col-md-12">
          <h2>Add new moderator</h2>
          <b-row class="my-1">
            <b-col sm="2">
              <label for="input-default">Alias:</label>
            </b-col>
            <b-col sm="10">
              <b-form-input v-model="newModerator.name" id="input-default" placeholder="Moderator alias">
              </b-form-input>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="2">
              <label for="input-default">Address:</label>
            </b-col>
            <b-col sm="10">
              <b-form-input v-model="newModerator.address" id="input-default" placeholder="Moderator public key">
              </b-form-input>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="2"></b-col>
            <b-col sm="10">
              <b-button variant="primary" @click="add_moderator">Register</b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>

    <!-- Remove moderator -->
    <div class="container" style="padding-bottom: 30px">
      <div class="row">
        <div class="col-md-12">
          <h2>Remove moderator</h2>
          <b-row class="my-1">
            <b-col sm="2">
              <label for="input-default">Address:</label>
            </b-col>
            <b-col sm="10">
              <b-form-input v-model="removeModerator" id="input-default" placeholder="Moderator public key">
              </b-form-input>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="2"></b-col>
            <b-col sm="10">
              <b-button variant="primary" @click="remove_moderator">Remove</b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>


    <div class="container" style="padding-bottom: 30px">
      <div class="row">
        <div class="col-md-12">
          <h2>Add user in to the allow list</h2>
          <b-row class="my-1">
            <b-col sm="2">
              <label for="input-default">Public key:</label>
            </b-col>
            <b-col sm="10">
              <b-form-input v-model="allowList.address" id="input-default" placeholder="User public key">
              </b-form-input>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="2">
              <label for="input-default">Amount:</label>
            </b-col>
            <b-col sm="10">
              <b-form-input v-model="allowList.amount" id="input-default" placeholder="Amount of reserves">
              </b-form-input>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="2"></b-col>
            <b-col sm="10">
              <b-button variant="primary" @click="add_into_allow_list">Register</b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>

    <!-- Register collection -->
    <div class="container" style="padding-bottom: 30px">
      <div class="row">
        <div class="col-md-12">
          <h2>Register new collection</h2>
          <b-row class="my-1">
            <b-col sm="2">
              <label for="input-default">Collection address:</label>
            </b-col>
            <b-col sm="10">
              <b-form-input v-model="registerCollection.address" id="input-default" placeholder="fa2 contract address">
              </b-form-input>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="2">
              <label for="input-default">Mint pass id:</label>
            </b-col>
            <b-col sm="10">
              <b-form-input v-model="registerCollection.mint_pass_id" id="input-default" placeholder="Mint pass id">
              </b-form-input>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="2">
              <label for="input-default">Certificate id:</label>
            </b-col>
            <b-col sm="10">
              <b-form-input v-model="registerCollection.certificate_id" id="input-default" placeholder="Certificate id">
              </b-form-input>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="2"></b-col>
            <b-col sm="10">
              <b-button variant="primary" @click="register_pass">Register</b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>

    <!-- Remove collection -->
    <div class="container" style="padding-bottom: 30px">
      <h2>Unregister a collection</h2>
      <b-row class="my-1">
        <b-col sm="2">
          <label for="input-default">Collection address:</label>
        </b-col>
        <b-col sm="10">
          <b-form-input v-model="unregisterCollection" id="input-default" placeholder="fa2 contract address">
          </b-form-input>
        </b-col>
      </b-row>
      <b-row class="my-1">
        <b-col sm="2"></b-col>
        <b-col sm="10">
          <b-button variant="primary" @click="unregister_pass">Unregister</b-button>
        </b-col>
      </b-row>
    </div>

  </body>


  <!-- <div class="row mint-pass" id="mint-pass">
      <div class="col-sm-12 col-lg-6 info-mint">
        <h1><strong>Admin page</strong></h1>
        <b-button @click="mint_fxhash(1, 1, 14770)" variant="primary">Toggle Me</b-button>
      </div>

      <div class="col-sm-12 col-lg-6">

      </div>

      <div class="col-sm-12 col-lg-6">
        <h2 style="padding-bottom: 20px"><strong>Admin page</strong></h2>
        <b-row class="my-1">
          <b-col sm="2">
            <label for="input-default">Default:</label>
          </b-col>
          <b-col sm="10">
            <b-form-input id="input-default" placeholder="Enter your name"></b-form-input>
          </b-col>
        </b-row>
      </div>
    </div>
  </div> -->
</template>

<script>
import PxAlerts from "@/components/PxAlerts.vue";

import store from "@/store/index";
import { mint } from "@/tezos/fxhash_calls.js";
import { set_administrator, add_moderator, remove_moderator, set_pause, add_discount,register_pass, unregister_pass } from "@/tezos/bg_calls.js";

export default {
  name: "ViewAdminPage",

  components: {
    PxAlerts,
  },

  data() {
    return {
      store,
      registerCollection: new Object(),
      unregisterCollection: undefined,
      newAdmin: undefined,
      newModerator: new Object(),
      allowList: new Object(),
      removeModerator: undefined,

    };
  },

  methods: {
    async mint_fxhash(_referrer, _reserve_input, issuer_id) {
      this.$refs.alerts.toggleTransaction();
      try {
        const op = await mint(_referrer, _reserve_input, issuer_id);
        if (op.completed) {
          this.$refs.alerts.showSuccessAlert("Minted successfully");
        } else {
          this.$refs.alerts.showErrorAlert("Something went wrong.");
        }
      } catch (e) {
        this.$refs.alerts.showErrorAlert(e.description);
      }

      this.$refs.alerts.toggleTransaction();
    },
    async set_administrator() {
      this.$refs.alerts.toggleTransaction();
      try {
        const op = await set_administrator(this.newAdmin);
        if (op.completed) {
          this.$refs.alerts.showSuccessAlert("Administrator set successfully");
        } else {
          this.$refs.alerts.showErrorAlert("Something went wrong.");
        }
      } catch (e) {
        this.$refs.alerts.showErrorAlert(e.description);
      }

      this.$refs.alerts.toggleTransaction();
    },
    async add_moderator() {
      this.$refs.alerts.toggleTransaction();
      try {
        const op = await add_moderator(this.newModerator.address, this.newModerator.name);
        if (op.completed) {
          this.$refs.alerts.showSuccessAlert("Added moderator successfully");
        } else {
          this.$refs.alerts.showErrorAlert("Something went wrong.");
        }
      } catch (e) {
        this.$refs.alerts.showErrorAlert(e.description);
      }

      this.$refs.alerts.toggleTransaction();
    },
    async remove_moderator() {
      this.$refs.alerts.toggleTransaction();
      try {
        const op = await remove_moderator(this.removeModerator);
        if (op.completed) {
          this.$refs.alerts.showSuccessAlert("Removed moderator successfully");
        } else {
          this.$refs.alerts.showErrorAlert("Something went wrong.");
        }
      } catch (e) {
        this.$refs.alerts.showErrorAlert(e.description);
      }

      this.$refs.alerts.toggleTransaction();
    },
    async set_pause() {
      this.$refs.alerts.toggleTransaction();
      try {
        const op = await set_pause(!store.blind_gallery_storage.paused);
        if (op.completed) {
          this.$refs.alerts.showSuccessAlert("Set pause successfully");
        } else {
          this.$refs.alerts.showErrorAlert("Something went wrong.");
        }

      } catch (e) {
        this.$refs.alerts.showErrorAlert(e.description);
      }

      this.$refs.alerts.toggleTransaction();
      this.$confetti.start();
    },
    async add_into_allow_list() {
      this.$refs.alerts.toggleTransaction();
      try {
        const op = await add_discount(this.allowList.address, this.allowList.amount);
        if (op.completed) {
          this.$refs.alerts.showSuccessAlert(`User ${this.allowList.address} added to allow list successfully`);
        } else {
          this.$refs.alerts.showErrorAlert("Something went wrong.");
        }
      } catch (e) {
         this.$refs.alerts.showErrorAlert(e.description);
      }

      this.$refs.alerts.toggleTransaction();
    },
    async register_pass() {
      this.$refs.alerts.toggleTransaction();
      try {
        const op = await register_pass(this.registerCollection.address, this.registerCollection.mint_pass_id ? this.registerCollection.mint_pass_id : 0, this.registerCollection.certificate_id);
        if (op.completed) {
          this.$refs.alerts.showSuccessAlert("Collection registered successfully");
        } else {
          this.$refs.alerts.showErrorAlert("Something went wrong.");
        }
      } catch (e) {
        this.$refs.alerts.showErrorAlert(e.description);
      }

      this.$refs.alerts.toggleTransaction();
    },
    async unregister_pass() {
      this.$refs.alerts.toggleTransaction();
      try {
        const op = await unregister_pass(this.unregisterCollection);
        if (op.completed) {
          this.$refs.alerts.showSuccessAlert("Collection unregistered successfully");
        } else {
          this.$refs.alerts.showErrorAlert("Something went wrong.");
        }
      } catch (e) {
        this.$refs.alerts.showErrorAlert(e.description);
      }

      this.$refs.alerts.toggleTransaction();
    },
  },
};
</script>

<style>
.mint-pass {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding-top: 90px;
}
</style>
