<template>
  <b-container>
    <b-row>
      <b-col
        v-for="token in tokens"
        :key="token.token_id"
        lg="4"
        md="6"
        cols="12"
      >
        <div class="shadow-sm m-3 rounded">
          <div class="ratio ratio-1x1">
            <img
              v-if="token.metadata == 'image'"
              class="w-100"
              :src="token.img"
              :alt="token.name"
            />
            <video v-else :src="token.img" class="img" controls loop>
              <p>
                Your browser doesn't support HTML5 video. Here is a
                <a :href="token.img">link to the video</a> instead.
              </p>
            </video>
          </div>
          <div class="card-body p-3 mt-2">
            <a class="secondary-button" :href="token.twitter" target="_blank">{{
              token.author
            }}</a>
            <p class="title-text text-secondary font-weight-bold">
              {{ token.name }}
            </p>
            <div
              class="d-flex align-items-center justify-content-center justify-content-sm-between flex-wrap"
            >
              <button
                class="primary-button mb-2"
                @click="
                  buy_objkt(
                    token.listing_id,
                    token.token_id,
                    token.floor,
                    token.name
                  )
                "
              >
                Buy for {{ token.floor | mutez }}
              </button>

              <a class="secondary-button" :href="token.link" target="_blank"
                >View on objkt
                <b-icon icon="chevron-right" font-scale="0."></b-icon>
              </a>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import IPFSGatewayTools from "@/ipfs-utils";
import { fulfill_ask } from "@/tezos/objkt_calls";

export default {
  name: "PxCardOld",

  props: {
    tokens: {
      type: Array,
      required: true,
    },
  },

  methods: {
    async buy_objkt(bigmap_key, token_id, amount, name) {
      this.$emit("toggleTransaction");
      try {
        const op = await fulfill_ask(bigmap_key, token_id, amount);
        if (op.completed) {
          this.$emit("updateSuccessMessage", `Successfully bought ${name}`);
        } else {
          this.$emit("updateErrorMessage", "Failed to buy OBJKT");
        }
      } catch (e) {
        this.$emit("updateErrorMessage", e.description);
      }
      this.$emit("toggleTransaction");
    },

    getThumbnail(cid) {
      const desiredGatewayPrefix = "https://mygateway.mypinata.cloud";

      return IPFSGatewayTools.convertToDesiredGateway(
        cid,
        desiredGatewayPrefix
      );
    },
  },
};
</script>

<style scoped>
.title-text {
  overflow: hidden;
  font-size: 1.25rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
