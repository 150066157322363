import get_objkt_listing from '@/api/get_objkst_listing'
import { CONTRACTS } from '@/constants';

const update_token_metadata = async (edition) => {
  const listings = await get_objkt_listing(CONTRACTS[edition].fa2);

  CONTRACTS[edition].tokens.forEach((token) => {
    token.listing_id = listings[token.token_id].listing_id;
    token.listing_contract = listings[token.token_id].listing_contract;
    token.floor = listings[token.token_id].floor;
  })

  return CONTRACTS[edition].tokens;

}

export default update_token_metadata;
