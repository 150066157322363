import { gql, request } from 'graphql-request';
import { FXHASH_API } from '@/constants';
import IPFSGatewayTools from "@pinata/ipfs-gateway-tools/dist/browser";
const gatewayTools = new IPFSGatewayTools();

const query = gql`
  query Query_root($userId: String, $filters: ObjktFilter) {
  user(id: $userId) {
    objkts(filters: $filters) {
      id
      onChainId
      name
      metadata
      metadataUri
      rarity
    }
  }
}
`;

const getFxhashCollectionBalance = async (pkh, collectionName = "Blind Gallery Mint Pass (Vistas Edition)") => {
  const response = await request(FXHASH_API, query, {
    userId: pkh,
    filters: {
      "searchQuery_eq": collectionName
    },
  });

  let mintPasses = { total: 0, previews: [], ids: [] };

  for (var i = 0; i < response.user.objkts.length; i++) {
    mintPasses.total += 1;
    mintPasses.ids.push(response.user.objkts[i].onChainId);
    mintPasses.previews.push(gatewayTools.convertToDesiredGateway(response.user.objkts[i].metadata.displayUri, 'https://ipfs.io'))
  }

  return mintPasses;
};

export default getFxhashCollectionBalance;
