<template>
  <body>
    <b-container>
      <b-row>
        <!--FIRST COLLECTION-->
        <b-col
          v-for="(collection, index) in collections"
          :key="index"
          cols="12"
          lg="6"
          style="gap: 1rem; padding: 1rem"
        >
          <div class="rounded shadow-sm d-flex flex-column p-4 my-4">
            <div class="ratio ratio-1x1">
              <img  
                v-if="collection.cover.includes('fxhash')"
                style="object-fit: contain"
                :src="collection.cover"
                alt=""
                class="w-100"
              />

              <video
                v-else
                width="100%"
                :src="collection.cover"
                muted
                loop
                autoplay
              >
                <p>
                  Your browser doesn't support HTML5 video. Here is a
                  <a
                    href="https://blind-gallery.infura-ipfs.io/ipfs/QmQAJheeyw994R9xTLpHHCKvAyxKMvcpDesXz2wwJ8d9Vx"
                    >link to the video</a
                  >
                  instead.
                </p>
              </video>
            </div>

            <div style="margin-top: 1rem">
              <h4>{{ collection.title }}</h4>
              <p>{{ collection.artist }}</p>
              <div
                class="d-flex justify-content-between w-100 align-items-center"
              >
                <a
                  class="primary-button"
                  :href="collection.link"
                  target="_blank"
                >
                  Collect on fxhash
                </a>
                <button
                  @click="$bvModal.show(`modal-${index}`)"
                  class="secondary-button"
                >
                  See more
                </button>
              </div>
            </div>
          </div>
          <b-modal
            centered
            size="lg"
            hide-header
            hide-footer
            :id="`modal-${index}`"
          >
            <span
              style="
                cursor: pointer;
                position: absolute;
                right: 1rem;
                z-index: 3;
              "
              @click="$bvModal.hide(`modal-${index}`)"
            >
              <Icon icon="material-symbols:close" width="32" height="32"
            /></span>

            <div class="d-lg-flex align-items-center">
              <b-col cols="12" lg="6">
                <div v-if="collection.cover.includes('fxhash')">
                  <swiper
                    style="height: 450px"
                    :modules="modules"
                    :effect="'fade'"
                    :spaceBetween="35"
                    :pagination="{
                      clickable: true,
                    }"
                    @swiper="updateSwiper"
                  >
                    <swiper-slide
                      v-for="preview in collection.previews"
                      :key="preview.id"
                      class="ratio ratio-1x1"
                      style="height: 85%"
                      ><img
                        width="100%"
                        style="object-fit: contain"
                        :src="preview.prev"
                        alt="preview"
                    /></swiper-slide>
                  </swiper>
                </div>
                <div style="height: 450px" class="ratio ratio-1x1" v-else>
                  <video
                    width="100%"
                    :src="collection.cover"
                    muted
                    loop
                    autoplay
                  >
                    <p>
                      Your browser doesn't support HTML5 video. Here is a
                      <a
                        href="https://blind-gallery.infura-ipfs.io/ipfs/QmQAJheeyw994R9xTLpHHCKvAyxKMvcpDesXz2wwJ8d9Vx"
                        >link to the video</a
                      >
                      instead.
                    </p>
                  </video>
                </div>
              </b-col>
              <b-col cols="12" lg="6" class="p-3">
                <h4>{{ collection.title }}</h4>
                <p>{{ collection.artist }}</p>
                <p class="small text-secondary">{{ collection.description }}</p>
                <a
                  :href="collection.article"
                  target="_blank"
                  class="secondary-button"
                  >Read article
                  <b-icon
                    icon="chevron-right
"
                    font-scale="0."
                  ></b-icon
                ></a>
              </b-col>
            </div>
          </b-modal>
        </b-col>
      </b-row>
    </b-container>
  </body>
</template>
<script>
import { Pagination, EffectFade, Navigation } from "swiper";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import { Icon } from "@iconify/vue2";
import "swiper/swiper-bundle.css";
SwiperCore.use([Pagination, Navigation]);

export default {
  name: "ViewSeedEd",
  components: { Icon, Swiper, SwiperSlide },

  data() {
    return {
      modules: [Pagination, EffectFade, Navigation],
      collections: [
        {
          cover:
            "https://gateway.fxhash.xyz/ipfs/QmTi1rWh8aFZDACFYpiEqmdGwXtPbTTw5rCpjbqxN4N6pJ",
          title: "Ciudad Central",
          description:
            "Ciudad Central (Spanish for Central City) marks the first Blind Gallery Seed Edition reveal and draws inspiration from threerenowned contemporary artists — James Turrell, Jesus Soto, andCarlos Cruz-Diez.<br><br>They committed their artist's careersto mastering optics, movement, and light, and the mysterious artistbehind Ciudad Central aims to blend and translate thosecharacteristics into the digital realm.",
          article: "https://blindgallery.substack.com/p/ciudad-central",
          link: "https://www.fxhash.xyz/generative/slug/ciudad-central",
          artist: "Thomas Noya",
          previews: [
            {
              prev: "https://gateway.fxhash.xyz/ipfs/QmQrHseQmZug2k3aEmJCSmhyhjbEMCExho9vuBXu9f4Yns",
            },
            {
              prev: "https://gateway.fxhash.xyz/ipfs/QmSG6UPyYkJja3FfdjRSR6KoD9mvJ5tH6kh3VADN4MCpsJ",
            },
            {
              prev: "https://gateway.fxhash.xyz/ipfs/QmRYXALegaaBATk6wnoDEoXvUyhJadSLSJt1qtzaYqUc9q",
            },
          ],
        },
        {
          cover:
            "https://gateway.fxhash.xyz/ipfs/QmP22nZ5DEd8p9dqsoZzLo1LnJVPSGNEALamQ5dkbALAy1",
          title: "FraKtuR",
          description:
            "I am fascinated by the fragmentation process. Chinese porcelain makers used it as a decorative technique for the so-called 'crackle' type of porcelain. The most famous crackle porcelains come from China from the 12th century to the Song period, and it is a pleasure to recreate such an ancient and rich technique into an algorithm. -FraKtuR creator.",
          article: "https://blindgallery.substack.com/p/fraktur",
          link: "https://www.fxhash.xyz/generative/25088",
          artist: "Olivier Bodini",
          previews: [
            {
              prev: "https://gateway.fxhash.xyz/ipfs/QmT6MRttGcvRAwB6qSd6ZCWaE3Lg6QyH5xTtyfPCChLeiX",
            },
            {
              prev: "https://gateway.fxhash.xyz/ipfs/QmTMiECvtTYcMzYhkPfycWX9oskfqYL9Uo295eHRPLHGTb",
            },
            {
              prev: "https://gateway.fxhash.xyz/ipfs/QmUMzgFezJtyEE1zttvBBnVpcKMGQYFeTUKBx8VNAkuDvr",
            },
          ],
        },
        {
          cover:
            "https://gateway.fxhash.xyz/ipfs/QmWJpGc4MvrpNe3wGjcVMTPLGaxT18iug26KyuM7vhLQwW",
          title: "Brutal Infection",
          description:
            "Brutal Infections explores the concept of structural organisms from a dystopian vision. The muted colors reflect cruel traits like decay, apathy, and detachment while emphasizing the sense of evolution, emergence, and life within these structures.",
          article: "https://blindgallery.substack.com/p/brutalinfection",
          link: "https://www.fxhash.xyz/generative/25132",
          artist: "DistCollective",
          previews: [
            {
              prev: "https://gateway.fxhash.xyz/ipfs/QmTQjRUyKhC3PGyQpHwqRvh56dqv2QkVRJfJ6U1KMSioaS",
            },
            {
              prev: "https://gateway.fxhash.xyz/ipfs/QmP1SJrYgJ6Letf6okFDuvtfCepL4rLystQjgY8uC8FFCR",
            },
            {
              prev: "https://gateway.fxhash.xyz/ipfs/QmWgotXpL5rnMma34WL6y8UgLB9fM3vRy8zgwVv1j23AQC",
            },
          ],
        },

        {
          cover:
            "https://blind-gallery.infura-ipfs.io/ipfs/QmQAJheeyw994R9xTLpHHCKvAyxKMvcpDesXz2wwJ8d9Vx",
          title: "The Dance of the Machines",
          description:
            "The Dance of the Machines produces animated outputs, in this case, a dance, representing complex, coordinated, and rigorous motions.",
          article: "https://blindgallery.substack.com/p/thedanceofthemachines",
          link: "https://www.fxhash.xyz/generative/25103",
          artist: "NIINOMI",
        },
      ],
    };
  },
  methods: {
    updateSwiper(swiper) {
      setTimeout(() => {
        swiper.update();
      }, 100);
    },
  },
};
</script>
<style scoped></style>
