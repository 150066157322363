<template>
  <div>
    <PxClubUnsync v-show="!store.wallet | !store.user_address" />
    <b-row class="club-desktop" v-show="store.wallet && store.user_address">
      <b-col class="side-nav-container" lg="1">
        <b-nav vertical class="side-nav">
          <b-nav-item @click="toBenefitsClub()"
            ><span
              style="font-size: 26px"
              :class="
                showBenefits === true
                  ? 'material-symbols-sharp'
                  : 'material-symbols-outlined'
              "
            >
              add_card </span
            ><span style="font-size: small">Benefits</span></b-nav-item
          >
          <b-nav-item @click="toBadgesClub()">
            <span
              style="font-size: 26px"
              :class="
                showBadgesContainer === true
                  ? 'material-symbols-sharp'
                  : 'material-symbols-outlined'
              "
            >
              workspace_premium </span
            ><span style="font-size: small">Badges</span></b-nav-item
          >

          <b-nav-item @click="toIncubatorClub()">
            <span
              style="font-size: 26px"
              :class="
                showIncubatorContainer === true
                  ? 'material-symbols-sharp'
                  : 'material-symbols-outlined'
              "
            >
              how_to_vote </span
            ><span style="font-size: small">Incubator</span></b-nav-item
          >
        </b-nav>
      </b-col>
      <b-col cols="12" lg="11" style="margin-left: 10rem">
        <PxClubBenefits
          v-show="showBenefits"
          ref="showBenefits"
          class="benefits-container"
          :benefits="this.benefits"
        />
        <PxClubBadges
          ref="showBadgesContainer"
          v-show="!showBenefits && showBadgesContainer"
          @update-badge="doUpdateBadge"
          :badges="this.badges"
          :pendingBadges="this.pendingBadges"
        />
        <PxClubIncubator
          ref="showIncubatorContainer"
          v-show="
            !showBenefits && !showBadgesContainer && showIncubatorContainer
          "
        />
      </b-col>
    </b-row>
    <b-container
      class="responsive-disclaimer"
      v-show="store.wallet && store.user_address"
    >
      <b-icon
        style="width: 90px; height: 90px"
        icon="exclamation-circle-fill"
      ></b-icon>
      <h2>
        The Club Area is only available on
        <span style="font-weight: 600">Desktop</span> at the moment.
      </h2>
    </b-container>
  </div>
</template>

<script>
import PxClubBenefits from "@/components/PxClubBenefits.vue";
import PxClubBadges from "@/components/PxClubBadges.vue";
import PxClubIncubator from "@/components/PxClubIncubator.vue";
import PxClubUnsync from "@/components/PxClubUnsync.vue";

import { CONTRACTS } from "@/constants";
import get_objkt_balance from "@/api/get_objkt_balance";
import store from "@/store";
import { get_discount } from "@/tezos/bg_calls";
import get_fxhash_club_member from "@/api/get_club_benefits";
import get_tokens_by_contract from "@/api/get_badges";
import getPendingBadges from "@/api/get_pending_badges";

export default {
  name: "ViewClub",
  components: {
    PxClubBenefits,
    PxClubBadges,
    PxClubIncubator,
    PxClubUnsync,
  },

  data() {
    return {
      sidebar: false,
      store,
      key: "",
      balanceInfo: [],
      enableWatcher: false,
      slide: 0,
      sliding: null,
      showBenefits: true,
      showBadgesContainer: false,
      showIncubatorContainer: false,
      benefits: new Object(),
      badges: [],
      pendingBadges: {
        wallet: "",
        first_edition_artists: false,
        first_edition_collector: false,
        bear_artists: false,
        bear_collector: false,
        club_members: false,
        seeds_artist: false,
        seeds_collector: false,
      },
    };
  },

  methods: {
    toggleSideBar() {
      this.$refs.sidebar.$refs.content.classList.toggle("b-sidebar_toggle");
    },
    showFirstAlert() {
      if (!store.wallet | !store.user_address) {
        store.createWallet();
      } else {
        this.$refs["blackBackground"].classList.toggle(
          "background-black-toggle"
        );
        this.$refs["alertContainer"].classList.toggle("popAlertParent-toggle");
        this.$refs["popAlertText"].classList.toggle("popAlertText-toggle");
      }
    },
    showSecondAlert() {
      this.$refs["popAlertText"].classList.toggle("popAlertText-toggle");
      this.$refs["popAlertText1"].classList.toggle("popAlertText1-toggle");
    },

    doUpdateBadge(badge) {
      this.pendingBadges[badge] = false;
      this.badges.push(CONTRACTS.badges.names[badge]);
    },

    async burnToken() {
      // hide both alerts
      this.showSecondAlert();
      this.showFirstAlert();

      let item_to_burn = new Object();

      this.balanceInfo.forEach((element) => {
        if (`x${element.balance} ${element.name}` == this.key) {
          item_to_burn["kind"] = element.kind;
          item_to_burn["address"] = element.key[0];
          item_to_burn["balance"] = element.balance;
        }
      });

      this.$refs.alerts.toggleTransaction();
      try {
        const op = await get_discount(
          item_to_burn.kind,
          item_to_burn.address,
          item_to_burn.balance
        );
        if (op.completed) {
          this.$refs.alerts.showSuccessAlert("Burned successfully");
        } else {
          this.$refs.alerts.showErrorAlert("Burn failed");
        }
      } catch (e) {
        this.$refs.alerts.showErrorAlert(
          e.description
            ? e.description
            : "Transaction time out, please check your wallet"
        );
      }
      await store.updateStorage();
      this.$refs.alerts.toggleTransaction();
      await this.getTokensBalance();
    },
    async getTokensBalance() {
      this.balanceInfo = [];

      if (!store.user_address) {
        return;
      }
      let key = new Object();

      const mint_pass_1_edition = await get_objkt_balance(
        store.user_address,
        CONTRACTS.first_collection.fa2,
        CONTRACTS.first_collection.mint_pass_id
      );

      if (mint_pass_1_edition) {
        key = {
          key: [
            CONTRACTS.first_collection.fa2,
            CONTRACTS.first_collection.mint_pass_id,
          ],
          name: "Mint Pass (1st edition)",
          balance: mint_pass_1_edition,
          kind: "mint_pass",
        };
        if (!this.balanceInfo.includes(key, 0)) {
          this.balanceInfo.push(key);
        }
      }

      const mint_pass_2_edition = await get_objkt_balance(
        store.user_address,
        CONTRACTS.second_collection.fa2,
        CONTRACTS.second_collection.mint_pass_id
      );

      if (mint_pass_2_edition) {
        key = {
          key: [
            CONTRACTS.second_collection.fa2,
            CONTRACTS.second_collection.mint_pass_id,
          ],
          name: "Mint Pass (2nd edition)",
          balance: mint_pass_2_edition,
          kind: "mint_pass",
        };
        if (!this.balanceInfo.includes(key, 0)) {
          this.balanceInfo.push(key);
        }
      }

      const certificate_1_edition = await get_objkt_balance(
        store.user_address,
        CONTRACTS.first_collection.fa2,
        CONTRACTS.first_collection.certificate_id
      );
      if (certificate_1_edition) {
        key = {
          key: [
            CONTRACTS.first_collection.fa2,
            CONTRACTS.first_collection.certificate_id,
          ],
          name: "Mint certificate (1st edition)",
          balance: certificate_1_edition,
          kind: "certificate",
        };
        if (!this.balanceInfo.includes(key, 0)) {
          this.balanceInfo.push(key);
        }
      }

      const certificate_2_edition = await get_objkt_balance(
        store.user_address,
        CONTRACTS.second_collection.fa2,
        CONTRACTS.second_collection.certificate_id
      );
      if (certificate_2_edition) {
        key = {
          key: [
            CONTRACTS.second_collection.fa2,
            CONTRACTS.second_collection.certificate_id,
          ],
          name: "Mint certificate (2nd edition)",
          balance: certificate_2_edition,
          kind: "certificate",
        };
        if (!this.balanceInfo.includes(key, 0)) {
          this.balanceInfo.push(key);
        }
      }
    },

    toBadgesClub() {
      this.showBenefits = false;
      this.showBadgesContainer = true;
      this.showIncubatorContainer = false;
    },

    toBenefitsClub() {
      this.showBenefits = true;
      this.showBadgesContainer = false;
      this.showIncubatorContainer = false;
    },

    toIncubatorClub() {
      this.showBenefits = false;
      this.showBadgesContainer = false;
      this.showIncubatorContainer = true;
    },
    async getBadges() {
      if (!store.user_address) {
        this.badges = [];
        this.pendingBadges = {
          wallet: "",
          first_edition_artirst: false,
          first_edition_collector: false,
          bear_artists: false,
          bear_collector: false,
          club_members: false,
          seeds_artist: false,
          seeds_collector: false,
        };
        return;
      }

      const badges = await get_tokens_by_contract(
        store.user_address,
        CONTRACTS.badges.fa2
      );
      const pendingBadges = await getPendingBadges(store.user_address);

      this.badges = badges;
      this.pendingBadges = pendingBadges;
    },
    async getBenefits() {
      if (!store.user_address) {
        this.benefits = {
          regular: 0,
          premium: 0,
          legendary: 0,
          reserves: 0,
          airdrop: 0,
          total: 0,
          previews: [],
        };

        return;
      }
      const benefits = await get_fxhash_club_member(store.user_address);
      this.benefits = benefits;
    },
    resetUserData() {
      this.balanceInfo = [];
      this.pendingBadges = {
        wallet: "",
        first_edition_artirst: false,
        first_edition_collector: false,
        bear_artists: false,
        bear_collector: false,
        club_members: false,
        seeds_artist: false,
        seeds_collector: false,
      };
      this.badges = [];
      this.benefits = {
        regular: 0,
        premium: 0,
        legendary: 0,
        reserves: 0,
        airdrop: 0,
        total: 0,
        previews: [],
      };
    },
  },
  created() {
    this.resetUserData();
    this.getBenefits();
    this.getBadges();
    this.getTokensBalance().then(() => {
      this.enableWatcher = true;
    });
  },

  onMounted() {
    this.toIncubatorClub();
  },

  watch: {
    // Note: only simple paths. Expressions are not supported.
    "store.user_address"(newAddress) {
      if (newAddress && this.enableWatcher) {
        this.resetUserData();
        this.getBenefits();
        this.getBadges();
        this.getTokensBalance().then(() => {
          store.updateStorage();
        });
      } else {
        this.resetUserData();
      }
    },
  },
};
</script>

<style>
.nav-link {
  color: rgb(0 0 0);
  display: unset;
  transition: ease all 0.3s;
}

.nav {
  --bs-nav-link-hover-color: none;
}

.main-row {
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.span-titles {
  font-size: 1.5rem;
  font-weight: 600;
  color: rgb(0, 147, 163);
}

.incubator-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.responsive-disclaimer {
  display: none;
}

.club-desktop {
  display: flex;
}

.bottom-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
  background-color: rgb(248, 249, 250);
  visibility: hidden;
}

@media (max-width: 768px) {
  .sidebar {
    display: none;
  }
  .bottom-menu {
    visibility: visible;
  }
}

@media (max-width: 768px) {
  .responsive-disclaimer {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    gap: 1rem;
    height: 100vh;
  }
  .club-desktop {
    display: none;
  }
}

@media (max-width: 425px) {
  .badget-container {
    width: 50%;
  }

  .badges-container-parent {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
  }
}
</style>
