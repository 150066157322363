import { TZKT_API_ENDPOINT } from '@/constants'

const get_badges = async (address, contract) => {
  let badges = [];
  const data = await fetch(`${TZKT_API_ENDPOINT}/v1/tokens/balances?account=${address}&token.contract=${contract}`)
  const tokens = await data.json()
  for (let i = 0; i < tokens.length; i++) {
    if (tokens[i].balance > 0) {
      if (tokens[i].token.metadata) {
        badges.push(tokens[i].token.metadata.name)
      }

    }
  }
  return badges
}

export default get_badges;
