<template>
  <body>
    <PxAlerts ref="alerts" />
    <b-container>
      <PxLimitedCollections style="margin: 3rem 0rem" />

      <b-row style="margin-top: 5rem">
        <h2 id="editions" style="margin-bottom: 2rem">Our latest updates</h2>
        <b-col
          cols="12"
          md="6"
          style="display: flex; justify-content: flex-start"
        >
          <b-card
            img-top
            tag="article"
            style="
              max-width: 37rem;
              border: 1px solid #dcdcdc;
              border-radius: 0;
            "
            class="mb-2"
          >
            <b-img
              src="https://cdn.discordapp.com/attachments/987378128106168403/1098316682965037197/website.png"
              style="height: 350px; width: 100%; object-fit: cover"
              alt="Club Membership"
            ></b-img>
            <h4 style="padding-top: 1rem">Introducing Limited Collections</h4>
            <b-card-text>
              Find out what's next for the Blind Gallery, including a new series
              of releases starting very soon...
            </b-card-text>

            <a
              href="https://blindgallery.substack.com/limited-collections"
              target="_blank"
              style="
                text-decoration: none;

                font-size: 1rem;
                padding: 0;
              "
              class="secondary-button"
              >LEARN MORE
              <b-icon
                icon="chevron-right
"
                font-scale="0."
              ></b-icon
            ></a>
          </b-card>
        </b-col>

        <b-col
          cols="12"
          md="6"
          style="display: flex; justify-content: flex-end"
        >
          <b-card
            img-top
            tag="article"
            style="
              max-width: 37rem;
              border: 1px solid #dcdcdc;
              border-radius: 0;
            "
            class="mb-2"
          >
            <b-img
              src="https://cdn.discordapp.com/attachments/987378128106168403/1077294324666089614/Sin-titulo-1.png"
              style="height: 350px; width: 100%; object-fit: cover"
              alt="Club Membership"
            ></b-img>
            <h4 style="padding-top: 1rem">
              The Seed Edition collections have been minted
            </h4>
            <b-card-text>
              The four generative collections created by Thomas Noya,
              DistCollective, Olivier Bodini and NIINOMI have been minted on
              fxhash.
            </b-card-text>

            <router-link
              class="secondary-button"
              style="
                text-decoration: none;

                font-size: 1rem;
                padding: 0;
              "
              to="/third-edition"
              >LEARN MORE
              <b-icon
                icon="chevron-right
"
                font-scale="0."
              ></b-icon
            ></router-link>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <b-container style="margin-top: 3rem">
      <div class="editions-collections">
        <div class="black-layer"></div>
        <div style="z-index: 2">
          <h1 style="color: #fff"><b>Editions and Limited Collections</b></h1>
          <p style="color: #fff">
            Discover all the editions and limited collections that have been
            launched at the Blind Gallery.
          </p>
          <router-link to="/art"
            ><button class="primary-button">EXPLORE</button>
          </router-link>
        </div>
      </div>

      <b-row class="main-row" style="margin: 5rem 0rem 5rem 0rem">
        <b-col cols="12" lg="6">
          <div style="display: flex; justify-content: center">
            <img
              src="https://cdn.discordapp.com/attachments/987378128106168403/1055561011412865145/Recurso_19.png"
              alt="Incubator"
              class="incubator-img"
            /></div
        ></b-col>
        <b-col cols="12" lg="6" style="padding: 0">
          <h2 style="font-weight: 600; font-size: 2.5rem; color: #0093a3">
            Art Incubator
          </h2>
          <p>
            The Art Incubator is a
            <span style="font-weight: 600">place for artists</span> pushing the
            boundaries between
            <span style="font-weight: 600">technology and art</span> to
            experiment and release artworks using the blind concept.
          </p>
          <router-link
            class="secondary-button"
            style="
              text-decoration: none;

              font-size: 1rem;
              padding: 0;
            "
            to="/incubator"
            >LEARN MORE
            <b-icon
              icon="chevron-right
"
              font-scale="0."
            ></b-icon
          ></router-link>
        </b-col>
      </b-row>

      <b-row class="main-row"
        ><b-col cols="12" lg="6" style="margin-bottom: 1rem">
          <h2 style="font-weight: 600; font-size: 2.5rem; color: #0093a3">
            <span>Blind Gallery Badges</span>
          </h2>
          <p>
            You can earn Badges by
            <span style="font-weight: 600">participating</span> in Blind Gallery
            events.
          </p>
          <a
            href="https://blindgallery.substack.com/p/clubarea"
            target="_blank"
            class="secondary-button"
            style="font-size: 1rem; padding: 0"
            >LEARN MORE
            <b-icon
              icon="chevron-right
"
              font-scale="0."
            ></b-icon
          ></a>
        </b-col>

        <b-col cols="12" lg="6">
          <div class="badges-container-parent">
            <div class="badget-container">
              <img
                src="https://cdn.discordapp.com/attachments/1063563362488832090/1063563556848676894/EMember.png"
                style="width: 100%"
              />
              <p class="badge-title">CLUB MEMBER 2022</p>
            </div>
            <div class="badget-container">
              <img
                src="https://cdn.discordapp.com/attachments/1063563362488832090/1063563559050678412/ECollector.png"
                style="width: 100%"
              />
              <p class="badge-title">1ST EDITION COLLECTOR</p>
            </div>
            <div class="badget-container">
              <img
                src="https://cdn.discordapp.com/attachments/1063563362488832090/1063563558534787082/EArtist.png"
                style="width: 100%"
              />
              <p class="badge-title">1ST EDITION ARTIST</p>
            </div>
            <div class="badget-container">
              <img
                src="https://cdn.discordapp.com/attachments/1063563362488832090/1063563557301653576/BArtist.png"
                style="width: 100%"
              />
              <p class="badge-title">BEAR ARTIST</p>
            </div>
            <div class="badget-container">
              <img
                src="https://cdn.discordapp.com/attachments/1063563362488832090/1063563557985329283/BCollecor.png"
                style="width: 100%"
              />
              <p class="badge-title">BEAR COLLECTOR</p>
            </div>

            <div class="badget-container">
              <img
                src="https://cdn.discordapp.com/attachments/978720964533690449/1073362054821458121/Recurso_18-8.png"
                style="width: 100%"
              />
              <p class="badge-title">SEED COLLECTOR</p>
            </div>

            <div class="badget-container">
              <img
                src="https://cdn.discordapp.com/attachments/978720964533690449/1073362055018598460/Recurso_19-8.png"
                style="width: 100%"
              />
              <p class="badge-title">SEED ARTIST</p>
            </div>

            <div class="badget-container">
              <img
                src="https://cdn.discordapp.com/attachments/1073362054880165889/1075821240306118817/Recurso_21-8.png"
                style="width: 100%"
              />
              <p class="badge-title">COLLECTORS CHAT</p>
            </div>
          </div>
        </b-col></b-row
      >
    </b-container>
  </body>
</template>

<script>
import PxAlerts from "@/components/PxAlerts.vue";
import PxLimitedCollections from "./PxLimitedCollections.vue";

import store from "@/store";

export default {
  name: "AuctionsTest",

  components: {
    PxAlerts,
    PxLimitedCollections,
  },

  data() {
    return {
      store,
    };
  },

  // watch: {
  //   // Note: only simple paths. Expressions are not supported.
  //   "store.user_address"(newAddress) {
  //     if (newAddress && this.enableWatcher) {
  //       store.updateStorage();
  //     } else {
  //       store.updateStorage();
  //     }
  //   },
  // },
};
</script>

<style>
.main-container {
  padding-top: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-img {
  object-fit: cover;
}

.latest-updates {
  display: flex;
  margin-bottom: 5rem;
  align-items: center;
  justify-content: space-between;
}

.gallery-card {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  background-clip: border-box;

  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  border: 1px solid #dcdcdc;
  margin-bottom: 1.5rem;
  height: max(511px);
}

.editions-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gallery-card-hidden {
  width: 100%;
  background: linear-gradient(90deg, #5e7d8c 10%, #8ea98d 100%);
  padding: 4rem;
  position: relative;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  background-clip: border-box;
  border-radius: 0.5rem;
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;

  margin-bottom: 1.5rem;

  display: flex;
  align-items: center;
  justify-content: center;
}

.gallery-card-hidden p {
  color: white;
}
.main-text {
  font-weight: 800;
  font-size: calc(0.625vw + 4em);
}

.card-img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.info-mint {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 7rem;
  text-align: center;
}

.card-body p {
  font-size: small;
}

.main-info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
}

.gradient-button {
  background: linear-gradient(90deg, #c56368, #4e8294, #86b091);
  border: none;
  padding: 5px 10px 5px 10px;
  color: white;
  border-radius: 0.2rem;
  width: 220px;
}

.gallery-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.substack {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-direction: column;
}

.twitter {
  background-color: #00acee;
  color: white;
  border: none;
  border-radius: 5px;
  width: 3.125rem;
  transition: 0.4s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-weight: 600;
  text-decoration: none;
  transition: 0.4s ease;
}

.discord {
  background-color: #5865f2;
  color: white;
  border: none;
  border-radius: 5px;
  width: 3.125rem;
  transition: 0.4s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10px;
  font-weight: 600;
  text-decoration: none;
}

.text-ellipsis {
  font-size: 1.4rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.discord p {
  margin: 0;
}

.twitter p {
  margin: 0;
}

.buy-mint {
  background-color: #0093a3;
  border-radius: 5px;
  border: none;
  width: 250px;
  padding: 10px;
  font-size: 16px;
  color: #ffffff;
  font-weight: 600;
  transition: 0.4s ease;
}

.buy-mint:hover {
  background-color: #016570;
  color: white;
}

.twitter:hover {
  background-color: #0095cf;
}

.discord:hover {
  background-color: #4a54c9;
}

.open-call-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 3rem;
}

.open-call {
  margin-top: 3rem;
  display: flex;
}

.fxhash-button {
  background: #0093a3;
  border: none;
  color: white;
  padding: 0.5rem;
  border-radius: 0.2rem;
  margin-top: 2rem;
  width: 50%;
  font-size: small;
  transition: all 0.4s;
  font-weight: 600;
}

.fxhash-button:disabled {
  background: #b4b4b4;
  border: none;
  color: white;
  padding: 0.5rem;
  border-radius: 0.2rem;
  margin-top: 2rem;
  width: 50%;
  font-size: small;
  transition: all 0.4s;
  font-weight: 600;
}

.counters-container {
  display: flex;
  margin-top: 2rem;
  justify-content: space-around;
  gap: 1rem;
}

.phases-col {
  width: 100%;
  height: 250px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  margin: 1rem;
  border-radius: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.phases-icon {
  display: flex;
  width: 200px;
  height: 200px;
  border-radius: 150px;
  background-color: rgb(255, 255, 255);
  position: absolute;
  border: 8px solid white;
  top: 3rem;
}

.token-BGC {
  padding: 3rem 2.5rem 2.5rem 2.5rem;
}

.date {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 50px;
  background-color: rgb(255, 255, 255);
  position: absolute;
  border: 8px solid white;
  bottom: 5.5rem;
}

.MADESunflower-title {
  font-family: made_sunflowerregular;
  font-size: 3rem;
}

.phases-body {
  padding: 6rem 1rem 1rem 1rem;
  text-align: center;
}

.past-galleries {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.puttingart-text {
  text-align: center;

  font-weight: 900;
  font-size: 3em;
}

#countdown-text {
  margin: 0;
  font-weight: 600;
}

#countdown2 {
  font-size: small;
}

.header-updates {
  background: linear-gradient(90.04deg, #57838c 21.73%, #e66972 88.53%);
  color: white;
  padding: 1rem;
  border-radius: 10px 10px 0px 0px;
  margin: 0;
  font-weight: 600;
}
.card-updates {
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(212, 212, 212);
  min-height: 530px;
}

.collection-container {
  position: relative;
  margin-bottom: 1.5rem;
}

.overlay,
.collection-container img {
  border-radius: 0.5rem;
}

.overlay {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  background-color: #08080865;
  transition: all ease 0.3s;
}

.overlay-text {
  position: absolute;
  left: 0px;
  padding: 1rem;
  right: 0px;
  bottom: 0;
  color: white;
}

.overlay-text p {
  color: white;
}

.collection-container:hover > .overlay {
  opacity: 1;
  z-index: 2;
}

.img-token-container {
  margin: 2rem;
  border: 1px solid rgb(212, 212, 212);
}

.timeline-container {
  display: flex;
  align-items: center;
  gap: 3rem;
  flex-wrap: wrap;
}

.date-child {
  border-right: 1px solid rgb(212, 212, 212);
  height: 100%;
}

.modal-footer {
  margin-top: 0;
  align-items: center;
  justify-content: center;
}

.container-buttons {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.overflow-hidden {
  transition: all 0.3s;
}

.overflow-hidden:hover {
  background-color: #f7f7f7;
}

.incubator-img {
  width: 80%;
  padding: 2rem;
}

.editions-collections {
  width: 100%;
  height: 400px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1rem;
  background-image: url("https://ipfs.io/ipfs/QmZ8wihiztCgHAVH8QBtDL1sDPiaS542wUk8ZjeJqYeqtd");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.black-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.658);
  z-index: 1;
}

@media (max-width: 995px) {
  .phases-col {
    border: none;
    height: auto;
    flex-direction: column;
  }
  .card-updates {
    margin-bottom: 1rem;
    min-height: 530px;
  }
}

@media (max-width: 1024px) {
  .card-updates {
    min-height: 580px;
  }
}

@media (max-width: 768px) {
  .timeline-container {
    gap: 1rem;
  }
  .img-token-container {
    margin: 0rem 0rem 1rem 0rem;
  }
  .date-child {
    border-bottom: 1px solid rgb(212, 212, 212);
    border-right: none;
    padding-bottom: 1rem;
    height: 100%;
  }
  .main-info {
    flex-direction: column;
  }

  .main-info h2 {
    text-align: left;
  }
  .main-container {
    flex-direction: column;
  }
  .phases-col {
    border: none;
    height: auto;
    flex-direction: column;
  }
  .section__item {
    scroll-snap-align: start;
    display: flex;
    flex: 0 0 350px;
  }

  .past-galleries {
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    flex-direction: row;
    padding-bottom: 2rem;
  }

  .phases-icon {
    position: inherit;
  }

  .phases-body {
    padding: 1rem 1rem 1rem 1rem;
    text-align: left;
  }
  .gradient-button {
    width: 100%;
  }

  .MADESunflower-title {
    font-size: 2.5rem;
  }

  .open-call-text {
    align-items: center;
    text-align: center;
  }

  .mint-t {
    text-align: center;
  }

  .buy-mint {
    padding-bottom: 10px;
    margin-left: 0;
    margin-right: 0;
    width: 160px;
  }

  .galleries-buttons {
    flex-direction: column;
  }

  .gallery-t {
    text-align: center;
  }
  .token-BGC {
    margin-bottom: 1rem;
    padding: 1rem;
  }
}

@media (max-width: 425px) {
  .incubator-img {
    width: 100%;
    padding: 1rem;
    margin-bottom: 1rem;
  }

  .main-container {
    padding-top: 5rem;
  }

  .container-buttons {
    flex-direction: column;
    text-align: center;
    align-items: inherit;
    gap: 0;
  }

  .latest-updates {
    margin-bottom: 0;
  }

  .header-updates {
    border-radius: 0rem;
  }

  .card-updates {
    border: none;
  }
  .main-info {
    flex-direction: column;
  }
  #countdown-text,
  #countdown2 {
    text-align: center;
  }
  .fxhash-button,
  .fxhash-button:disabled {
    width: 100%;
  }
  .puttingart-text {
    margin-top: 0.5rem;
    font-size: 2.5rem;
  }
  .main-text {
    font-size: calc(0.625vw + 3.5em);
    padding-bottom: 1rem;
    text-align: center;
  }

  .editions-buttons {
    flex-direction: column;
    text-align: center;
    align-items: inherit;
    gap: 1rem;
  }

  .editions-text {
    padding-top: 5rem;
  }
  .phases-col {
    border: none;
    height: auto;
    flex-direction: column;
  }
  .section__item {
    scroll-snap-align: center;
    display: flex;
    flex: 0 0 350px;
  }

  .past-galleries {
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    flex-direction: row;
  }

  .phases-icon {
    position: inherit;
  }

  .phases-body {
    padding: 1rem 1rem 1rem 1rem;
    text-align: center;
  }
  .gradient-button {
    width: 100%;
  }

  .MADESunflower-title {
    font-size: 2.5rem;
  }

  .open-call-text {
    align-items: center;
    text-align: center;
  }

  .mint-t {
    text-align: center;
  }

  .buy-mint {
    padding-bottom: 10px;
    margin-left: 0;
    margin-right: 0;
    width: 160px;
  }

  .galleries-buttons {
    flex-direction: column;
  }

  .gallery-t {
    text-align: center;
  }

  .date {
    position: inherit;
  }
  .counters-container {
    flex-direction: column;
  }
}

@media (max-width: 375px) {
  .sale-container {
    background-color: transparent;
    padding: 0;
    box-shadow: none;
  }
}
</style>
