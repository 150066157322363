<template>
  <b-container>
    <div
      class="row main-container"
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 3rem;
        padding-bottom: 7rem;
      "
    >
      <b-col order="2" order-md="1">
        <p id="countdown-text"></p>
        <p id="countdown2"><span></span></p>
        <h1 class="jointheclub">
          Join the<span style="color: rgb(0, 147, 163)"> Club</span>
        </h1>

        <p style="margin-top: 2rem">
          <span style="font-weight: 600"
            >The Blind Gallery Club is an art community that focuses on digital
            and generative art.</span
          >
          Besides accessing the community, members get early access and
          discounts to the Blind Gallery events and special ways to experience
          the blind concept.
        </p>
        <p style="font-weight: 600">Already a member?</p>
        <div class="club-buttons">
          <div>
            <button class="sync-club" @click="store.createWallet()">
              CONNECT YOUR WALLET
            </button>
          </div>
          <div
            style="display: flex; align-self: center; justify-content: center"
          >
            <p style="margin: 0">or</p>
          </div>

          <div style="display: flex; align-self: center">
            <a
              href="https://www.fxhash.xyz/generative/slug/blind-gallery-club-membership"
              target="_blank"
              class="secondary-buttonclub"
              >COLLECT ON FXHASH</a
            >
          </div>
        </div>
      </b-col>
      <b-col order="1" order-md="2">
        <div>
          <div>
            <img
              src="https://cdn.discordapp.com/attachments/987378128106168403/1065355680061599804/QmYQfw6nDbpbrDhd1WV9q4k4WPjZ3Q1ykrdCNTE7ByqPTv.png"
              style="width: 100%"
            />
          </div>
        </div>
      </b-col>
    </div>

    <div class="row benefits-row">
      <div class="col benefits-style">
        <div
          class="row"
          style="display: flex; align-items: center; justify-content: center"
        >
          <div class="col-3 icon-benefit">
            <iconify-icon
              icon="material-symbols:workspace-premium"
              style="color: white"
              width="3rem"
              height="3rem"
            ></iconify-icon>
          </div>
          <div class="col">
            <p style="font-weight: 600; margin: 0">Regular Membership</p>
            <p style="font-size: small; padding: 0; margin-bottom: 0.5rem">
              on each Blind Gallery edition
            </p>

            <p>2 Mint Passes reserves at a discounted price.</p>
          </div>
        </div>
        <div
          style="
            border-bottom: 1px solid rgba(0, 0, 0, 0.25);
            width: 100%;
            margin: 1rem 0rem 1rem 0rem;
          "
        ></div>
        <div
          class="row"
          style="display: flex; align-items: center; justify-content: center"
        >
          <div class="col-3 premium">
            <iconify-icon
              icon="fluent:premium-28-filled"
              style="color: white"
              width="3rem"
              height="3rem"
            ></iconify-icon>
          </div>
          <div class="col">
            <p style="font-weight: 600; margin: 0">
              Premium Membership (less than 5%)
            </p>

            <p style="font-size: small; padding: 0; margin-bottom: 0.5rem">
              on each Blind Gallery edition
            </p>

            <p>
              1 Mint Pass airdrop and 2 Mint Passes reserves at a discounted
              price.
            </p>
          </div>
        </div>
        <div
          style="
            border-bottom: 1px solid rgba(0, 0, 0, 0.25);
            width: 100%;
            margin: 1rem 0rem 1rem 0rem;
          "
        ></div>
        <div
          class="row"
          style="display: flex; align-items: center; justify-content: center"
        >
          <div class="col-3 gold">
            <iconify-icon
              icon="cryptocurrency:gold"
              style="color: white"
              width="3rem"
              height="3rem"
            ></iconify-icon>
          </div>
          <div class="col">
            <p style="font-weight: 600; margin: 0">
              Legendary Membership (less than 1%)
            </p>

            <p style="font-size: small; padding: 0; margin-bottom: 0.5rem">
              on each Blind Gallery edition
            </p>

            <p>Full set of mint passes airdropped.</p>
          </div>
        </div>
        <div class="hr-div"></div>
      </div>

      <div class="col benefits-style">
        <div
          class="row"
          style="display: flex; align-items: center; justify-content: center"
        >
          <div class="col-3 icon-benefit">
            <iconify-icon
              icon="fluent:people-community-24-filled"
              style="color: white"
              width="3rem"
              height="3rem"
            ></iconify-icon>
          </div>
          <div class="col">
            <p style="font-weight: 600; margin: 0">Access the community</p>
            <p>
              Blind Gallery Club holders will have a private community over
              Discord where you can chat and interact with other club members.
            </p>
          </div>
        </div>
        <div
          style="
            border-bottom: 1px solid rgba(0, 0, 0, 0.25);
            width: 100%;
            margin: 1rem 0rem 1rem 0rem;
          "
        ></div>
        <div
          class="row"
          style="display: flex; align-items: center; justify-content: center"
        >
          <div class="col-3 icon-benefit">
            <iconify-icon
              icon="ooui:image-gallery"
              style="color: white"
              width="3rem"
              height="3rem"
            ></iconify-icon>
          </div>
          <div class="col">
            <p style="font-weight: 600; margin: 0">Surprise drops</p>
            <p>
              Besides the Blind Gallery events, we will collaborate with Blind
              Gallery artists for surprise drops for Blind Gallery Club members.
            </p>
          </div>
        </div>
      </div>
      <div style="display: flex; align-items: center; justify-content: center">
        <a href="https://www.kaloh.xyz/p/blind-gallery-club" target="_blank">
          <button
            style="
              background: transparent;
              color: rgb(0, 147, 163);
              border: 1px solid rgb(0, 147, 163);
              min-width: 300px;
              max-width: 400px;
              border-radius: 0.2rem;
              font-weight: 600;
              padding: 0.5rem 0rem 0.5rem 0;
              margin-top: 1rem;
            "
          >
            READ MORE
          </button></a
        >
      </div>
    </div>
  </b-container>
</template>
<script>
import store from "@/store/index";
export default {
  name: "PxClubBenefits",
  props: ["createWallet"],
  data() {
    return {
      store,
    };
  },
  created() {
    store.autoLogin();
    store.getContracts();
  },
};
</script>
<style scoped>
h2 {
  font-weight: 600;
}

.benefits-row {
  display: flex;
  gap: 1.8rem;
  justify-content: center;
  padding-bottom: 7rem;
}

.hr-div {
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  width: 100%;
  margin: 1rem 0rem 1rem 0rem;
  display: none;
}
.background-black {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #252525;
  z-index: 2;
  visibility: hidden;
  opacity: 0.7;
}
.background-black-toggle {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  z-index: 2;
  visibility: visible;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
}
.popAlertParent {
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 3;
}
.popAlertParent-toggle {
  visibility: visible;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 3;
}
.popAlertText {
  display: none;
}
.popAlertText-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  padding: 4rem;
  border-radius: 0.5rem;
  text-align: center;
  width: min(410px);
}
.popAlertText1 {
  display: none;
}
.popAlertText1-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  padding: 4rem;
  border-radius: 0.5rem;
  text-align: center;
  width: min(410px);
}

.icon-benefit {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(0, 147, 163);
  background: linear-gradient(
    60deg,
    rgba(0, 147, 163, 1) 0%,
    rgb(2, 120, 131) 100%
  );
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 10rem;
  margin: 1rem;
}
.gold {
  background: rgb(255, 208, 65);
  background: linear-gradient(
    60deg,
    rgba(255, 208, 65, 1) 0%,
    rgba(212, 166, 24, 1) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 10rem;
  margin: 1rem;
}
.premium {
  background: rgb(175, 175, 175);
  background: linear-gradient(
    60deg,
    rgba(175, 175, 175, 1) 0%,
    rgba(97, 97, 97, 1) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 10rem;
  margin: 1rem;
}

.success-alert {
  background-color: #12b104;
  color: #ffffff;
  font-size: small;
  border: none;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  right: 1rem;
  z-index: 1;
  border-radius: 0.2rem;
  width: 20rem;
}
.error-alert {
  background-color: rgb(222, 36, 85);
  color: #ffffff;
  font-size: small;
  border: none;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  right: 1rem;
  z-index: 1;
  border-radius: 0.2rem;
  width: 20rem;
}
.benefits-style {
  background-color: #f9f9f9;
  padding: 1.5rem;
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

#burn-icon {
  filter: grayscale(100%);
}

.jointheclub {
  font-weight: 900;
  font-size: 4rem;
}

.club-buttons {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.sync-club {
  padding: 0.5rem 0.8rem 0.5rem 0.8rem;
  background-color: rgb(0, 147, 163);
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 0.2rem;
}

.secondary-buttonclub {
  text-decoration: none;
  color: #57606a;
  font-weight: 600;
}

@media (max-width: 768px) {
  .reserve-membership-header,
  .benefits-row {
    flex-direction: column;
  }
}
@media (max-width: 425px) {
  .benefits-style {
    margin: 0;
    box-shadow: none;
    border-radius: 0;
    background-color: transparent;
    padding: 0;
  }

  .sync-club {
    width: 100%;
  }

  .club-buttons {
    flex-direction: column;
    align-items: inherit;
  }
  .jointheclub {
    font-size: 2.5rem;
  }
  .benefits-row {
    gap: 0;
  }
  .hr-div {
    display: block;
  }
}
</style>
