<template>
  <div>
    <PxAlerts ref="alerts" />
    <b-container style="min-height: 75vh; display: flex">
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 1rem;
        "
      >
        <span
          class="material-symbols-sharp"
          style="font-size: 4.2rem; color: #0093a3"
        >
          how_to_vote</span
        >
        <h2>
          <span style="color: #0093a3">The first voting round has ended.</span>
          <br />
          The next one will happen in a few months, stay tuned!
        </h2>
      </div>
      <b-row v-show="false">
        <h2 style="font-weight: 600; font-size: 2.5rem; color: #0093a3">
          Art Incubator
        </h2>
        <p>Select the artist you would like to see in the Art Incubator.</p>
        <p style="font-size: small">
          Important: <br /><br />- The most voted artists will be invited to
          join the Art Incubator and collaborate with us to release their work
          blindly in future editions. <br />- These are previews and examples,
          not the final artworks.
        </p>
        <hr />

        <p style="font-weight: 600">Available votes: {{ amountOfVotes }}</p>
        <!-- <p>{{ msg2 }} (Remaining time for voting)</p> -->
        <b-col
          cols="12"
          lg="4"
          style="gap: 1rem; padding: 1rem"
          v-for="content in incubatorContent"
          :key="content.id"
        >
          <div
            @click="showModal(content.id, content.prev2, content.prev3)"
            v-b-modal.modalIncubator
            class="collection-container"
          >
            <div class="overlay">
              <div class="overlay-text">
                <h4>{{ content.title }}</h4>
                <hr />
                <p style="font-size: small">{{ content.Tools }}</p>
              </div>
            </div>
            <div class="ratio ratio-1x1">
              <img
                v-show="
                  content.prev1.includes('png') ||
                  content.prev1.includes('jpeg') ||
                  content.prev1.includes('jpg') ||
                  content.prev1.includes('PNG')
                "
                style="object-fit: cover; width: 100%; max-height: 100%"
                :src="content.prev1"
              />
              <video
                v-show="content.prev1.includes('mp4')"
                width="100%"
                :src="content.prev1"
                muted
                loop
                autoplay
              >
                <p>
                  Your browser doesn't support HTML5 video. Here is a
                  <a
                    href="https://assets.joyn.xyz/Backend/PromptSubmission/default/3515e8995ab23e0d7ab8c2279ef81fa2da333a5f3086aeb639f7ab226526db0e.mp4"
                    >link to the video</a
                  >
                  instead.
                </p>
              </video>
            </div>
          </div>

          <div class="container-buttons">
            <button
              style="width: 100%; font-size: 1rem; background-color: gray"
              @click="
                !confirmModal;
                $bvModal.show('modalConfirm' + content.id);
              "
              disabled
              class="primary-button"
            >
              CHOOSE ARTIST
            </button>
          </div>
          <b-modal
            size="xl"
            centered
            hide-footer
            hide-header
            hide-modal-cancel
            ok-only
            no-stacking
            :id="'modal-' + content.id"
            :visible="activeModal === content.id"
            @hidden="hideModal"
          >
            <template #modal-header>
              <p style="font-weight: 600"></p>

              <b-icon
                class="close-modal"
                @click="$bvModal.hide('modal-' + activeModal)"
                icon="x-lg"
              ></b-icon>
            </template>
            <div class="modal-container">
              <b-col cols="12" md="6">
                <b-carousel
                  v-show="
                    content.prev1.includes('png') ||
                    content.prev1.includes('jpeg') ||
                    content.prev1.includes('jpg') ||
                    content.prev1.includes('PNG')
                  "
                  id="carousel-1"
                  fade
                  :interval="8000"
                  :controls="slider2 !== null"
                  label-next=""
                  label-prev=""
                >
                  <div class="ratio ratio-1x1">
                    <b-carousel-slide
                      style="display: flex; align-items: center"
                      :img-src="content.prev1"
                    ></b-carousel-slide>
                    <b-carousel-slide
                      v-if="slider2 !== null"
                      style="display: flex; align-items: center"
                      :img-src="content.prev2"
                    ></b-carousel-slide>
                    <b-carousel-slide
                      v-if="slider3 !== null"
                      style="display: flex; align-items: center"
                      :img-src="content.prev3"
                    ></b-carousel-slide>
                  </div>
                </b-carousel>
                <div
                  class="ratio ratio-1x1"
                  v-show="content.prev1.includes('mp4')"
                >
                  <video width="100%" :src="content.prev1" muted loop autoplay>
                    <p>
                      Your browser doesn't support HTML5 video. Here is a
                      <a :href="content.prev1">link to the video</a>
                      instead.
                    </p>
                  </video>
                </div>
              </b-col>
              <b-col cols="12" md="6">
                <div class="modal-info">
                  <h2 style="margin-top: 1rem">
                    {{ content.title }}
                  </h2>
                  <p v-html="content.paragraph"></p>
                  <hr />
                  <p style="font-size: small">{{ content.Tools }}</p>

                  <b-button
                    @click="
                      !confirmModal;
                      $bvModal.show('modalConfirm' + activeModal);
                    "
                    disabled
                    style="background-color: gray"
                    class="primary-button"
                    >CHOOSE ARTIST</b-button
                  >
                </div></b-col
              >
            </div>
          </b-modal>
          <b-modal
            size="md"
            centered
            hide-footer
            hide-header
            :visible="activeModal === content.id && confirmModal === true"
            :id="'modalConfirm' + content.id"
            ><div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding: 1rem;
              "
            >
              <b-icon
                icon="exclamation-circle-fill
"
                scale="5"
                style="margin-bottom: 2rem; padding: 2rem 0rem 2rem 0rem"
              ></b-icon>

              <h3 style="text-align: center; margin-bottom: 1rem">
                Are you sure you want to choose
                <span style="font-weight: bold">{{ content.title }}</span
                >?
              </h3>
              <div style="display: flex; gap: 2rem">
                <button
                  style="font-size: 1rem; padding: 0.5rem"
                  class="secondary-button"
                  @click="$bvModal.hide('modalConfirm' + content.id)"
                >
                  CANCEL</button
                ><button
                  @click="
                    doVote(content.sku);
                    $bvModal.hide('modalConfirm' + content.id);
                  "
                  style="font-size: 1rem; font-weight: 600; color: #0093a3"
                  class="secondary-button"
                >
                  CONFIRM
                </button>
              </div>
            </div></b-modal
          ></b-col
        >
      </b-row>
    </b-container>
  </div>
</template>
<script>
import PxAlerts from "@/components/PxAlerts.vue";

import store from "@/store";
import { vote } from "@/tezos/voting_calls";

export default {
  name: "PxClubIncubator",

  components: {
    PxAlerts,
  },

  data() {
    return {
      store,
      amountOfVotes: 0,
      activeModal: null,
      slider2: null,
      slider3: null,
      confirmModal: false,

      msg2: "",
      htmlText: "The exchange period ends in:",
      htmlText2: "",
      enableDisable: true,
      resetDistance: false,

      enableWatcher: true,

      incubatorContent: [
        {
          prev1:
            "https://assets.joyn.xyz/Backend/PromptSubmission/default/1112a3e2e4aefb45c46dff06a390fa579515900a952582ce34de87f5489e798b.png?d=xl",
          prev2:
            "https://assets.joyn.xyz/Backend/PromptSubmission/default/dbb9aa950f667ec987737721c4f20bf712fda01eb9e86caf67197e9fd1b7edd2.png?d=xl",
          prev3:
            "https://assets.joyn.xyz/Backend/PromptSubmission/default/caa52ef653eb306e81b37ce8521bf589039ba3cfcb1c063b8cd8bbf71e2186d8.png?d=xl",
          title: "Artist A",
          sku: "artist-a",
          paragraph:
            "With this collection I'm exploring the raw beauty of the ocean in the more remote and less welcoming parts of the world.<br><br> I like to start with a simple idea, then work to add layers and variations, while also trying not to add too much. Less is more.",
          Tools: "Tools: Generative coded art, p5.js",
          id: 1,
        },
        {
          id: 2,
          prev1:
            "https://cdn.discordapp.com/attachments/978720964533690449/1082739342197395598/4shnH4Va.png",
          prev2: null,
          prev3: null,
          title: "Artist B",
          sku: "artist-b",
          paragraph:
            "Filmmaker and a photographer, enjoyed a passion for the arts and a love for the natural world's mysteries.<br><br>Her photographs are portraits, imposing respect. The flower is no longer fragile; she is strong. She symbolizes this nature that bends to the elements. A real break in the world in which we live.The flower is a lady first and foremost. A feminist before its time. A cornucopia of culture, stories, and emotions.",
          Tools: "Tools: AI, Midjourney",
        },

        {
          prev1:
            "https://assets.joyn.xyz/Backend/PromptSubmission/default/ed97df675678091ef7ff6883738e16334b613b39025da6c7449ca29365ca200c.png?d=xl",
          prev2:
            "https://assets.joyn.xyz/Backend/PromptSubmission/default/1bc2b6fb3bff5dfc3feaa9811fc24668e6137a886375d87229a25148ad0c5192.png?d=xl",
          prev3:
            "https://assets.joyn.xyz/Backend/PromptSubmission/default/8d433597f58a0625c3012f4d4b33e1c917ad1a40ba7c3ff7fdb7739819505844.png?d=xl",
          title: "Artist C",
          sku: "artist-c",
          paragraph:
            "I'm working on a current mission: developing the style of Neo-Pixelism (AI-enacted Pixel Art) to blend it with philosophical kind of themes and questioning.<br><br> With 'DUSK/DAWN' specifically, I'm trying to explore the concept of Thresholds made by light, using Pixel Art Aesthetics, inspired by many places described or imagined in books or TV.<br><br> I use different AI tools and resources such as Inpainting, #AIMusic creation, video post edition, text, etc. To achieve this own creative quest.",
          Tools: "Tools: AI",
          id: 3,
        },
        {
          id: 4,
          prev1:
            "https://assets.joyn.xyz/Backend/PromptSubmission/default/f5f872afc6f68d1473533849c3fa9ca9731deb4d1f22ecb1294bde2fcfca4190.png?d=xl",
          prev2:
            "https://assets.joyn.xyz/Backend/PromptSubmission/default/ec427e271034432355dd0b9b964358d38146d8255f946da4f704185484ecbccc.png?d=xl",
          prev3:
            "https://assets.joyn.xyz/Backend/PromptSubmission/default/46cc7d778bc0b4953b4b09d78112a2f1464ffa4d2cf83ab41b16eca9ab83c833.png?d=xl",
          title: "Artist D",
          sku: "artist-d",
          paragraph:
            "Inspiration for this piece was Escher, the play on perspective and finding many points to explore across a singular piece. ",
          Tools: "Tools: p5.js, webgl",
        },
        {
          id: 5,
          prev1:
            "https://assets.joyn.xyz/Backend/PromptSubmission/default/9f971f61705c32d0723bcda45b9ccbd65efce6f696871b9dbd02b423e0f20476.png?d=xl",
          prev2:
            "https://assets.joyn.xyz/Backend/PromptSubmission/default/28914b8dc1b36d5df25b961f3183526e66d4b590c511e4f017276ecfc7de6abc.png?d=xl",
          prev3: null,
          title: "Artist E",
          sku: "artist-e",
          Tools: "Tools: Generative coded art, p5.js, JavaScript",
          paragraph:
            "I am exploring challenging the traditional notions of art and aesthetics, the role of the generative artist in society, and simultaneously the formal elements of generative art, such as abstract shape, line, fluidity, color, and value (lightness or darkness). With each new creation, we are given the opportunity to discover something new and to be curious about the world around us.<br><br> My process includes coding in JavaScript and p5js, the use of algorithms that incorporate random elements, or the use of chance operations to determine a certain aspect of the final image. The field of generative art is an inspiration in itself, brimming with potential, and is sure to bring joy and excitement to all who engage with it.",
        },

        {
          id: 6,
          prev1:
            "https://assets.joyn.xyz/Backend/PromptSubmission/default/a83c9ab2a4ae7107f30741e2bee0a3fa43ad4f746018348334e9274a3cb718d3.png?d=xl",
          prev2:
            "https://assets.joyn.xyz/Backend/PromptSubmission/default/093e4e65e019532a3abc08bec45f600a276a2b20ce13e15c53d1ebe3132cefbd.png?d=xl",
          prev3:
            "https://assets.joyn.xyz/Backend/PromptSubmission/default/121420f718af3a92770cb031bbf6c6d52aef7f48ef5333639df3649cba601546.png?d=xl",
          Tools: "Tools: AI, Midjourney, Stable Diffusion",
          title: "Artist F",
          sku: "artist-f",
          paragraph: `I had this idea of AI visually representing gold that consumes, terrorizes, enhances, corrupts, and beautifies art.<br><br> I am exploring this concept with a process where I feed classical masterpieces to AIs (midjourney, local stable-diffusion trained models) with gold-gilded-golden prompts and editing it with photoshop - repeating until I see a combination of fine art + post-ai art imagery + my twisted golden vision.`,
        },

        {
          id: 7,

          prev1:
            "https://assets.joyn.xyz/Backend/PromptSubmission/default/0a6b88aa7e4fce1d6c9f5fe5ee434c3c9f25ebe03f003d672f69105da4721034.png?d=xl",
          prev2:
            "https://assets.joyn.xyz/Backend/PromptSubmission/default/4fc07e539ccf847b717f65638b990b2d93a8f6fd9d731955f489b521395cca3c.png?d=xl",
          prev3:
            "https://assets.joyn.xyz/Backend/PromptSubmission/default/afc8c038e0501a246eab4d63ebdb3ff3d28f6f92beb60af8d20a726465595173.png?d=xl",
          title: "Artist G",
          sku: "artist-g",
          paragraph:
            "This work is inspired by the phenomenon of electrical noise in amplifier circuitry. This noise is usually unwanted and is filtered out. The idea is to create something aesthetically pleasing from an undesirable effect - to pull a signal from the noise.<br><br> These are 100% code, made from Javascript and p5.js, without the use of pre-rendered components or images.<br><br> My inspirations are numerous. Here are some of them: <br><br>-The usual generative suspects (Tyler Hobbs, William Mapan, Iskra Velitchkova etc.)<br><br>-The greats of the abstract (Kusama, Kandinsky, Twombly, Rothko etc.)<br><br>-Photographers (Joel Tjintjelaar, Fan Ho, Andy Gray etc.)",
          Tools: "Tools: Generative coded art, p5.js.",
        },

        {
          id: 8,

          prev1:
            "https://assets.joyn.xyz/Backend/PromptSubmission/default/141f779ee125f6390c179373de46bedd98115326bd229f19a3ec4a7f784b111a.png?d=xl",
          prev2:
            "https://assets.joyn.xyz/Backend/PromptSubmission/default/5af1e40ecc3f422a5e0e2456b502b8c259948c9ec88195c8d5d460c3a9036340.png?d=xl",
          prev3:
            "https://assets.joyn.xyz/Backend/PromptSubmission/default/335017a33c6cf9e67e6d1ca4ee8c3695135930c930045338f92d5e2936173225.png?d=xl",
          title: "Artist H",
          sku: "artist-h",
          paragraph:
            "In terms of process, this has been a labor of love for a couple years now, so strap yourself in...<br><br>The custom StableDiffusion model was trained on images made with Midjourney. Those midjourney images were made by image-prompting Dall-E images. Those Dall-E images? Made by feeding it previous midjourney pics. And those early midjourney pfp's were a blend of my own art and images generated using VQGAN.",
          Tools: "Tools: AI, Stable Diffusion, Midjourney, Dall-E, VQGAN",
        },

        {
          id: 9,

          prev1:
            "https://assets.joyn.xyz/Backend/PromptSubmission/default/ad4623039cab690ffc6212cef4d2c0c0e2cffffd20bad4f0660ac2f26778a5f1.mp4",
          prev2: null,
          prev3: null,
          title: "Artist I",
          sku: "artist-i",
          paragraph:
            "I create the input with ai or 3d motion and process everything on nodes.<br><br> Trying to encapsulate human nature and its capabilities through spectrum of moving lights.<br><br> Color blindness (each piece has its contrary color blind version) and it explores how seeing colors can change our perception",
          Tools: "Tools:  AI, 3D creator tools",
        },
        {
          id: 10,

          prev1:
            "https://assets.joyn.xyz/Backend/PromptSubmission/default/a5c9da002784f01897b3738839d7db9fcf6e4a4e72daaba5784b35c3b4015e99.png?d=xl",
          prev2: null,
          prev3: null,
          title: "Artist J",
          sku: "artist-j",
          paragraph:
            "I'm exploring the synthesis between human and machine and I envision it in a future world where reality and virtual has met. I believe will merge with their creation (AI, machine..) into a new life form that won't necessary live in the real world. With my latest art I'm exploring how this new humans might look.<br><br>I start with drawing on Procreate and iPad. I then use custom build software that is essentially a displacement map software build for iPad that helps me create my unique abstract style. Then I export a set of images which I use to feed into an AI. The AI trains on those images and creates visual recreations based on the visuals. I do NOT use any word prompts which is quite unique.",
          Tools: "Tools:  Procreate, AI, custom models, no prompts.",
        },
        {
          id: 11,
          prev1:
            "https://assets.joyn.xyz/Backend/PromptSubmission/default/daba0a38c5ee4608146d5706706fd8b986febb123996168d9f725f9d157a44ba.jpg?d=xl",
          prev2:
            "https://assets.joyn.xyz/Backend/PromptSubmission/default/50d67e67a27f2eeb46d776f026db0d54d0ac0ea04fed2a8e133830055293f720.jpg?d=xl",
          prev3:
            "https://assets.joyn.xyz/Backend/PromptSubmission/default/faec71e504b4f6c456c0dadfedd3026ebdad7aa389d499f96d675ad1e7d8856d.jpg?d=xl",
          title: "Artist K",
          sku: "artist-k",
          paragraph:
            "This collection is the first personalized long-form generative art.<br><br> *After minting, the name of the owner will appear inside the artwork! <br><br> Each output consists of two parts:<br><br> - an abstract canvas with a name (but it's in a non-obvious form);<br><br> - by double click - with a hint frame.<br><br> Everyone has an identity — a sense of self. But how to make sensation more comprehensible, how to see the invisible, how to make the intangible real? 'IDentica' is an artist's experiment in understanding self-identification through art, creating a visual image of a personality with the name of the artwork's owner included inside.",
          Tools: "Tools: Generative coded art, p5.js",
        },
        {
          prev1:
            "https://assets.joyn.xyz/Backend/PromptSubmission/default/cd7c9556512a1def6bad271b9dcf8a9d5169c314c430f3c0addd0a1f91878d1a.png?d=xl",
          prev2:
            "https://assets.joyn.xyz/Backend/PromptSubmission/default/039d904949ac6400d0a5cc2b8de29396533b8b142d0870c2bd6d60d01075498d.jpg?d=xl",
          prev3:
            "https://assets.joyn.xyz/Backend/PromptSubmission/default/797cdd0904dcc40bde8a5b141fdd7f961c4104f840bd98e2b82ed1df0dfd2feb.jpg?d=xl",
          title: "Artist L",
          sku: "artist-l",
          paragraph:
            "It is also mean overthinking, which I am involved in these days.",
          Tools: "Tools: Generative coded art, p5js",
          id: 12,
        },
        {
          prev1:
            "https://assets.joyn.xyz/Backend/PromptSubmission/default/c3d765b6a6070b80b971fdd42a1d2280d1f47daa337c757029f49628816b1c5e.png?d=xl",
          prev2: null,
          prev3: null,
          title: "Artist M",
          sku: "artist-m",
          paragraph: "Series on Surrealism created on Midjourney",
          Tools: "Tools: AI, Midjourney",
          id: 13,
        },
      ],
    };
  },

  methods: {
    showModal(id, prev2, prev3) {
      this.activeModal = id;
      this.slider2 = prev2;
      this.slider3 = prev3;
    },

    hideModal() {
      this.activeModal = null;
    },

    showRemaining2() {
      let end = new Date("03/15/2023 20:00 UTC-6");
      let second = 1000;
      let minute = second * 60;
      let hour = minute * 60;
      let day = hour * 24;
      let now = new Date();
      let distance = end - now;

      // if (distance < 0) {
      //   this.htmlText = "Public Sale starts in:";
      //   this.htmlText2 = "(Remaining time for public sale)";
      //   distance = distance + hour * 31;
      //   this.resetDistance = true;
      // } else if (this.resetDistance === true) {
      //   this.htmlText = "";
      //   clearTimeout(this.showRemaining2);
      // }

      let days = Math.floor(distance / day);
      let hours = Math.floor((distance % day) / hour);
      let minutes = Math.floor((distance % hour) / minute);
      let seconds = Math.floor((distance % minute) / second);

      this.msg2 = `${days} days | ${hours} hours | ${minutes} minutes | ${seconds} seconds`;

      setTimeout(this.showRemaining2, 1000);

      if (days < 0 && hours < 0 && minutes < 0 && this.resetDistance === true) {
        clearInterval(this.showRemaining2);
        this.htmlText = "";
        this.htmlText2 = "";
        this.msg2 = "";

        return;
      }
    },
    async doVote(sku) {
      if (!store.user_address) {
        this.$refs.alerts.showInfoAlert("Please connect your wallet first!");
        return;
      }
      if (store.voting_storage.members[store.user_address] < 1) {
        this.$refs.alerts.showInfoAlert("You have no votes left!");
        return;
      }

      this.$refs.alerts.toggleTransaction();

      try {
        const op = await vote(sku);
        if (op.completed) {
          let selected = this.incubatorContent.find((o) => o.sku === sku);
          store.voting_storage.members[store.user_address] -= 1;
          this.amountOfVotes -= 1;
          this.$refs.alerts.showSuccessAlert(
            "Voted for " + selected.title + "!"
          );
        } else {
          this.$refs.alerts.showErrorAlert("Something went wrong!");
        }
      } catch (e) {
        this.$refs.alerts.showErrorAlert(e.description);
      }

      this.$refs.alerts.toggleTransaction();
    },

    async getUserVotes() {
      if (!store.user_address) {
        this.amountOfVotes = 0;
        return;
      }

      await store.updateStorage();
      this.amountOfVotes = Number(
        store.voting_storage.members[store.user_address]
          ? store.voting_storage.members[store.user_address]
          : 0
      );
    },
    async resetUserData() {
      this.amountOfVotes = 0;
      await store.updateStorage();
    },
  },

  created() {
    this.showRemaining2();
    this.getUserVotes();
  },
  mounted() {
    this.getUserVotes();
  },
  watch: {
    // Note: only simple paths. Expressions are not supported.
    "store.user_address"(newAddress) {
      if (newAddress && this.enableWatcher) {
        this.resetUserData();
        this.getUserVotes();
      } else {
        this.resetUserData();
      }
    },
  },
};
</script>
<style>
.w-100 {
  object-fit: contain;
  max-height: 100%;
}

.carousel-control-next-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="24" height="24" viewBox="0 0 24 24"%3E%3Cpath fill="currentColor" d="M9.4 18L8 16.6l4.6-4.6L8 7.4L9.4 6l6 6l-6 6Z"%2F%3E%3C%2Fsvg%3E');
}

.carousel-control-prev-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="24" height="24" viewBox="0 0 24 24"%3E%3Cg transform="rotate(180 12 12)"%3E%3Cpath fill="currentColor" d="M9.4 18L8 16.6l4.6-4.6L8 7.4L9.4 6l6 6l-6 6Z"%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
}
</style>
