//! constants
export const first_edition_token_metadata = [
  {
    description:
      "Appreciation comes from mysterious and usually forbidden sources, but it is undoubtedly one of the most pleasurable things in life.",
    token_id: "1",
    name: "Appreciation Sample",
    author: "Datzel",
    twitter: "https://twitter.com/Datzel3",
    metadata: "image",
    img: "/assets/art/Appreciation sample.png",
    link: "https://objkt.com/asset/KT1D8Q4HEMzoiBaGSVDdTTYMq9phz98WizVQ/1",
  },
  {
    description:
      "Inspired by the anonymous nature of collectors in the NFT world. It pictures the elusive investor, ever watchful but restless, driven by the fear of an opportunity missed. \n\nDigital painting, 2123x1664 px\n\nThis NFT was part of the Blind Gallery (www.blindgallery.xyz)",
    token_id: "8",
    name: "The Collector",
    author: "goldcat",
    twitter: "https://twitter.com/OriginalGoldCat",
    metadata: "image",
    img: "/assets/art/The Collector.jpg",
    link: "https://objkt.com/asset/KT1D8Q4HEMzoiBaGSVDdTTYMq9phz98WizVQ/8",
  },
  {
    description:
      "This paper was discovered in 1947 on the northwestern shore of the Dead Sea. It belongs to a collection of illustrations, primarily fragmented, except for a few relatively intact ones. The papers are of extraterrestrial origin, and their intent is currently unknown.\n\nThis NFT was part of the Blind Gallery (www.blindgallery.xyz)",
    token_id: "15",
    name: "Blueprint",
    author: "ismahelio",
    twitter: "https://twitter.com/ismahelios",
    metadata: "image",
    img: "assets/art/Blueprint.png",
    link: "https://objkt.com/asset/KT1D8Q4HEMzoiBaGSVDdTTYMq9phz98WizVQ/15",
  },
  {
    description:
      "'Pilgrims' is an expressionist representation of a group of people on spiritual pilgrimage. Selfless action is faceless. There is equality in anonymity.\n\n\nThis NFT was part of the Blind Gallery (www.blindgallery.xyz)",
    token_id: "17",
    name: "Pilgrims",
    author: "AD_AD",
    twitter: "https://twitter.com/__AD_AD__",
    metadata: "video",
    img: "assets/art/Pilgrims.m4v",
    link: "https://objkt.com/asset/KT1D8Q4HEMzoiBaGSVDdTTYMq9phz98WizVQ/17",
  },
  {
    description:
      "In generative art, artists play with randomization. We have to find the right balance between order and chaos. Too much order, and it’s not very interesting; too much chaos, and it looks messy. If noise is not fully random, it could contain information.\n\nThis artwork contains a message… but it’s probably not where you might think.\n\nThis NFT was part of the Blind Gallery (www.blindgallery.xyz)",
    token_id: "24",
    name: "The Message Is Not Where You Might Think",
    author: "Camille Roux",
    twitter: "https://twitter.com/camillerouxart",
    metadata: "image",
    img: "assets/art/The message is not where you might think.png",
    link: "https://objkt.com/asset/KT1D8Q4HEMzoiBaGSVDdTTYMq9phz98WizVQ/24",
  },
  {
    description:
      "No being ever truly consistent or the same. Pay attention to the movements and the rest will follow.\n\nThis NFT was part of the Blind Gallery (www.blindgallery.xyz)",
    token_id: "14",
    name: "A Wanderer",
    author: "I am Wessel",
    twitter: "https://twitter.com/iamwesselart",
    metadata: "video",
    img: "assets/art/A Wanderer.m4v",
    link: "https://objkt.com/asset/KT1D8Q4HEMzoiBaGSVDdTTYMq9phz98WizVQ/14",
  },
  {
    description:
      "This piece was created with Javascript, which poses a dimensional space in motion and in constant transmutation. The idea of the mysterious and the secret is also part of the evocative experience of the piece.\n\nThis NFT was part of the Blind Gallery (www.blindgallery.xyz)",
    token_id: "2",
    name: "Dimensional Access",
    author: "Daniel Oropeza",
    twitter: "https://twitter.com/Ferdoropeza",
    metadata: "video",
    img: "assets/art/Dimensional Access.m4v",
    link: "https://objkt.com/asset/KT1D8Q4HEMzoiBaGSVDdTTYMq9phz98WizVQ/2",
  },
  {
    description:
      "This piece was inspired by the countless ancient alien artifacts that populate the imagery of science-fiction. The only remnants of long forgotten civilizations, these devices are usually crucial to the plot of the story. I've always loved this trope of the genre.\n\nOrigin: unknown\nState: working, function unknown\nComments: /\n\nThis NFT was part of the Blind Gallery (www.blindgallery.xyz)",
    token_id: "5",
    name: "Unknown Device #12311",
    author: "Loackme",
    twitter: "https://twitter.com/loackme_",
    metadata: "video",
    img: "assets/art/UnknownDevice.m4v",
    link: "https://objkt.com/asset/KT1D8Q4HEMzoiBaGSVDdTTYMq9phz98WizVQ/5",
  },
  {
    description:
      "Create an evolving lock and unlock it.\n\nPress S to save the image.\nPress P to pause/restart.\nPress A to turn the audio on/off.\nPress T to change speed.\n\nThis work uses p5js,webpd and glfx.\n\nThis NFT was part of the Blind Gallery (www.blindgallery.xyz)",
    token_id: "6",
    name: "Unlock",
    author: "Jinyao Lin",
    twitter: "https://twitter.com/jinyaolin",
    metadata: "image",
    img: "assets/art/Unlock.jpg",
    link: "https://objkt.com/asset/KT1D8Q4HEMzoiBaGSVDdTTYMq9phz98WizVQ/6",
  },
  {
    description:
      "“But she was inside the wonderful garden, and she could come through the door under the ivy any time, and she felt as if she had found a world all her own.” \n\n― Frances Hodgson Burnett, The Secret Garden\n\nThis NFT was part of the Blind Gallery (www.blindgallery.xyz)",
    token_id: "7",
    name: "The Secret Garden",
    author: "Anna Lucia",
    twitter: "https://twitter.com/annaluciacodes",
    metadata: "image",
    img: "assets/art/The Secret Garden.png",
    link: "https://objkt.com/asset/KT1D8Q4HEMzoiBaGSVDdTTYMq9phz98WizVQ/7",
  },
  {
    description:
      "A generative algorithm executed by hand. The instruction to execute this is fairly simple — keep drawing new lines as close to the previous one as possible without actually touching it — yet deceptively challenging to 𝚍𝚘. It took several sessions to complete. As I was doing this, I noticed how aware I had become of my thoughts and feelings. I'd 𝚌𝚊𝚝𝚌𝚑 myself trying to keep it perfect. Or feel my patience tested. Like when my 𝚌𝚑𝚒𝚕𝚍 came running to me unannounced, I worried they'd bump into me and mess up my drawing. 𝙸𝚏 I mess up it up, I'd have to go back to 𝚕𝚒𝚗𝚎 𝟶. Once, I was doing it late at night and started getting sleepy, so I had to 𝚋𝚛𝚎𝚊𝚔 and continue in the morning. It made me think, if a computer were to do this, it wouldn't ever need to 𝚑𝚊𝚕𝚝. It wouldn't feel tired, upset, anxious, worried. It wouldn't feel anything. But I also thought, \"But then it'd be perfect.\"\n\nThis NFT was part of the Blind Gallery (www.blindgallery.xyz)",
    token_id: "10",
    name: "Line by Line",
    author: "Yazid",
    twitter: "https://twitter.com/Yazid",
    metadata: "image",
    img: "assets/art/Line by Line.jpg",
    link: "https://objkt.com/asset/KT1D8Q4HEMzoiBaGSVDdTTYMq9phz98WizVQ/10",
  },
  {
    description:
      "Real-time animation, variable size. Can you decode the message?\n\nMade in p5js.\n\nThis NFT was part of the Blind Gallery (www.blindgallery.xyz)",
    token_id: "23",
    name: "Decoder",
    author: "Lisa Orth",
    twitter: "https://twitter.com/lisaorthstudio",
    metadata: "video",
    img: "assets/art/Decoder.m4v",
    link: "https://objkt.com/asset/KT1D8Q4HEMzoiBaGSVDdTTYMq9phz98WizVQ/23",
  },
  {
    description:
      "Unfold the mystery. Some elements might appear to stay the same, yet perspective always changes when things move. Focus and you'll find a place where even small questions are unraveled. \n\nThis NFT was part of the Blind Gallery (www.blindgallery.xyz)",
    token_id: "4",
    name: "unfold.mystery",
    author: "Mark Knol",
    twitter: "https://twitter.com/mknol",
    metadata: "video",
    img: "assets/art/UnfoldMystery.m4v",
    link: "https://objkt.com/asset/KT1D8Q4HEMzoiBaGSVDdTTYMq9phz98WizVQ/4",
  },
  {
    description:
      "Both the lack of light and the abundance of it can make us blind.\n\n3D design. Video Edition. Gif format.\n1600 × 954, 178 fr2mes, 37,6 MB.\n\nThis NFT was part of the Blind Gallery (www.blindgallery.xyz)",
    token_id: "25",
    name: "Blinded",
    author: "A.L. Crego",
    twitter: "https://twitter.com/ALCrego_",
    metadata: "video",
    img: "assets/art/Blinded.mp4",
    link: "https://objkt.com/asset/KT1D8Q4HEMzoiBaGSVDdTTYMq9phz98WizVQ/25",
  },
  {
    description:
      "Behind the anon names and cartoon avatars many face their inner struggles in total secrecy, tormented by negative feelings, from FOMO to fear; a fire that is internalised to maintain composure to face the volatile seas and pirate waters.\n\nThis NFT was part of the Blind Gallery (www.blindgallery.xyz)",
    token_id: "3",
    name: "Anon Torment",
    author: "Matthew Plummer-Fernández",
    twitter: "https://twitter.com/M_PF",
    metadata: "image",
    img: "assets/art/Anon Torment.png",
    link: "https://objkt.com/asset/KT1D8Q4HEMzoiBaGSVDdTTYMq9phz98WizVQ/3",
  },
  {
    description:
      "The universe is securely locked. \nThere is not enough time to unlock its mysteries. \nWe must, instead, make our own. \n\nprocreate was used to create the base image sequence and create the post effects - procreate.art\n\nvideo production was done within blender to combine the video and audio - blender.org\n\n\n\nThis NFT was part of the Blind Gallery (www.blindgallery.xyz)",
    token_id: "13",
    name: "The Problem With The Great Machine",
    author: "Culla",
    twitter: "https://twitter.com/camillerouxart",
    metadata: "video",
    img: "assets/art/The Problem With The Great Machine.m4v",
    link: "https://objkt.com/asset/KT1D8Q4HEMzoiBaGSVDdTTYMq9phz98WizVQ/13",
  },
  {
    description:
      "\"I've always been fascinated with early stereoscopic images and their uncanny realism for the time. Standing on the threshold of the metaverse, let’s look back one last time and see how far we've come.\"\n\nBest viewed with 3D red-cyan glasses.\n\nSeizure warning: flashing lights. \nMedium: p5.js, html, javascript, css, WebGL\nFormat: stereo anaglyph, 5 seconds loop, 2558×1600 pixels\n\nPhoto credit: Fortepan.hu released under Creative Commons CC-BY-SA-3.0 licence\n\nThis NFT was part of the Blind Gallery (www.blindgallery.xyz)",
    token_id: "18",
    name: "Ontoscope",
    author: "Raphaël de Courville",
    twitter: "https://twitter.com/sableRaph",
    metadata: "video",
    img: "assets/art/Ontoscope.m4v",
    link: "https://objkt.com/asset/KT1D8Q4HEMzoiBaGSVDdTTYMq9phz98WizVQ/18",
  },
]

export const bear_edition_token_metadata = [
  {
    description:
      "Collective madness is followed by blurred paths and unfortunate decisions before the acceptance. \n\nCreated using GLSL and Touchdesigner. \n2888 x 3840px\n.\nThis piece was part of the Blind Gallery (www.blindgallery.xyz).",
    name: "Lapse",
    img: "assets/second-collection/3.png",
    metadata: "image",
    token_id: "3",
    link: "https://objkt.com/asset/KT1SiWsWp3JDDCkTaDZBkVahAxRQj1djX5Eg/3",
    author: "Pablo Alpe",
    twitter: "https://twitter.com/pabloAlpe_",
  },
  {
    description:
      "Initially released on June 30, 2018, tezos has served as the blockchain of choice for many digital authors. \n\nfour utilizes tezos price data to transform four years of emotions–fear, greed, excitement, and uncertainty–into generative art. Carefully engineered to balance art and its origins (as price data), the undulating waves and cavernous corners within four represent the rollercoaster of emotions and dark spaces we experience during market turbulence.  \n\nAs it's not clearly evident, tezos price data was used as follows:\n  • Individual columns represent one day within a year, with 365 columns across the entire canvas.\n  • Respective years are represented by color, with hue being the primary driver.\n-      July 2018 - June 2019: tezos nascency, represented in white.\n-      July 2019 - June 2020: price stabilization, represented in blue.\n-      July 2020 - June 2021: momentum increases, represented in green.\n-      July 2021 - June 2022: enthusiasm followed by a deep decline, represented in red.\n  • Daily price is represented by the length of the colored section, compared against that same day in different years. \n  • Represented by the long triangle within each day of price data, daily volume is compared against that year’s annual low and high volumes.\n  • One additional use of volume data is the borders on each individual triangle. Thicker borders indicate higher volumes.\n  • Perhaps overlooked, there are only two uses of random() incorporated into the data, providing variation to the saturation and brightness.  Substituting tezos price data for randomized data provides for interesting outputs.\n\nMade with p5js, triangles, and tezos price data.\n\nThis NFT was part of the Blind Gallery (www.blindgallery.xyz).",
    name: "four",
    img: "assets/second-collection/4.png",
    metadata: "image",
    token_id: "4",
    link: "https://objkt.com/asset/KT1SiWsWp3JDDCkTaDZBkVahAxRQj1djX5Eg/4",
    author: "Abstractment",
    twitter: "https://twitter.com/abstractment",
  },
  {
    description:
      "Created with processing.py\n\nThis NFT was part of the Blind Gallery (www.blindgallery.xyz)",
    name: "how i sometimes feel",
    img: "assets/second-collection/13.png",
    metadata: "image",
    token_id: "13",
    link: "https://objkt.com/asset/KT1SiWsWp3JDDCkTaDZBkVahAxRQj1djX5Eg/13",
    author: "Lunarean",
    twitter: "https://twitter.com/lunarean",
  },
  {
    description:
      "Footage recovered from the body of a crypto bro who went down the bear market slide.\nThis NFT was part of the Blind Gallery (www.blindgallery.xyz)",
    name: "BEAR MARKET SLIDE",
    img: "assets/second-collection/2.m4v",
    metadata: "video",
    token_id: "2",
    link: "https://objkt.com/asset/KT1SiWsWp3JDDCkTaDZBkVahAxRQj1djX5Eg/2",
    author: "Waxhead",
    twitter: "https://twitter.com/Waxheadart",
  },
  {
    description:
      "The semicolon (;) is used to indicate a major division in a market where a more distinct separation is felt between valuations or mental wellbeing on a cryptocoin. The semicolon is a mark of pause where we recognize and await what comes next. The semicolon is a reminder that we continue on.\n\n This NFT was part of the Blind Gallery (www.blindgallery.xyz)",
    name: "Sem-i-koh-li-zhen",
    img: "assets/second-collection/5.png",
    metadata: "image",
    token_id: "5",
    link: "https://objkt.com/asset/KT1SiWsWp3JDDCkTaDZBkVahAxRQj1djX5Eg/5",
    author: "Matt Perkins",
    twitter: "https://twitter.com/nudoru",
  },
  {
    description: "the bear market\ncrypto … (www.blindgallery.xyz)",
    name: "among the litter of coals and ashes we burst from our cages",
    img: "assets/second-collection/8.png",
    metadata: "image",
    token_id: "8",
    link: "https://objkt.com/asset/KT1SiWsWp3JDDCkTaDZBkVahAxRQj1djX5Eg/8",
    author: "David Cheifetz",
    twitter: "https://twitter.com/davidcheifetz",
  },
  {
    description: "Ink On Paper\n#blindgallery",
    name: "In The Red",
    img: "assets/second-collection/10.jpg",
    metadata: "image",
    token_id: "10",
    link: "https://objkt.com/asset/KT1SiWsWp3JDDCkTaDZBkVahAxRQj1djX5Eg/10",
    author: "Tim Maxwell",
    twitter: "https://twitter.com/maxwellinked",
  },
  {
    description:
      "A ballad of failed moon missions and days of endless red. \nTides rise, liquidations happen and promises are broken.\nKingly riches turn to life underwater as the waters rise around you.\nThis is love in the time of ramen.\n\n\n\ndigital collage, digital painting and Midjourney AI\n\nThis NFT was part of the Blind Gallery (www.blindgallery.xyz)",
    name: "Love In The Time of Ramen",
    img: "assets/second-collection/21.png",
    metadata: "image",
    token_id: "21",
    link: "https://objkt.com/asset/KT1SiWsWp3JDDCkTaDZBkVahAxRQj1djX5Eg/21",
    author: "Studio York Town",
    twitter: "https://twitter.com/StudioYorktown",
  },
  {
    description:
      "This NFT was part of the Blind Gallery (www.blindgallery.xyz)",
    name: "NGMI",
    img: "assets/second-collection/22.png",
    metadata: "image",
    token_id: "22",
    link: "https://objkt.com/asset/KT1SiWsWp3JDDCkTaDZBkVahAxRQj1djX5Eg/22",
    author: "Leander Herzog",
    twitter: "https://twitter.com/lennyjpg",
  },
  {
    description: "Generative animated gif.…(www.blindgallery.xyz).",
    name: "RD(33)033",
    img: "assets/second-collection/0.gif",
    metadata: "image",
    token_id: "0",
    link: "https://objkt.com/asset/KT1SiWsWp3JDDCkTaDZBkVahAxRQj1djX5Eg/0",
    author: "ElTono",
    twitter: "https://twitter.com/Eltono",
  },
  {
    description:
      "Through the veil of perception we do not see things as they are, for they are filtered by who we are.\n\nThis NFT was part of the Blind Gallery (www.blindgallery.xyz)",
    name: "The Veil",
    img: "assets/second-collection/7.jpg",
    metadata: "image",
    token_id: "7",
    link: "https://objkt.com/asset/KT1SiWsWp3JDDCkTaDZBkVahAxRQj1djX5Eg/7",
    author: "Ryan Bell",
    twitter: "https://twitter.com/iRyanBell",
  },
  {
    description:
      'Two agents walking randomly in the pulsating market. \n \nMade with p5js\n\nPress "P" to pause/unpause.\nPress "R" to reset.',
    name: "B0822 simulator",
    img: "assets/second-collection/6.png",
    metadata: "image",
    token_id: "6",
    link: "https://objkt.com/asset/KT1SiWsWp3JDDCkTaDZBkVahAxRQj1djX5Eg/6",
    author: "Aleksandra Jovanić",
    twitter: "https://twitter.com/alexis_o_O",
  },
  {
    description:
      "The darker today is, the more likely it is that tomorrow will be brighter.\n\nThis piece was part of the Blind Gallery (www.blindgallery.xyz).",
    name: "Brighter, Tomorrow.",
    img: "assets/second-collection/9.png",
    metadata: "image",
    token_id: "9",
    link: "https://objkt.com/asset/KT1SiWsWp3JDDCkTaDZBkVahAxRQj1djX5Eg/9",
    author: "Jeres",
    twitter: "https://twitter.com/heyjeres",
  },
  {
    description:
      "Luna\n“As if you were on fire from within.\n\nThe moon lives in the lining of your skin.”\n― Pablo Neruda\n\nAll rights reserved to the author. The purchase of this NFT is a purchase of the asset, not of the copyright.\n\nThis NFT was part of the Blind Gallery (http://blindgallery.xyz)",
    name: "Luna",
    img: "assets/second-collection/18.jpg",
    metadata: "image",
    token_id: "18",
    link: "https://objkt.com/asset/KT1SiWsWp3JDDCkTaDZBkVahAxRQj1djX5Eg/18",
    author: "Jimena Buena Vida",
    twitter: "https://twitter.com/JimenaBuenaVida",
  },
  {
    description:
      "When in danger,\nWhen in doubt,\nRun in circles, \nScream and shout.\n\nA digitized version of an oil painting on canvas from 2016 that would relate to the situations brought upon by the current bear market to the NFT space.\n\nThis NFT is part of the Blind Gallery (www.blindgallery.xyz)",
    name: "9 letters of FUD",
    img: "assets/second-collection/14.jpg",
    metadata: "image",
    token_id: "14",
    link: "https://objkt.com/asset/KT1SiWsWp3JDDCkTaDZBkVahAxRQj1djX5Eg/14",
    author: "Bjorn Calleja",
    twitter: "https://twitter.com/bjorncalleja",
  },
  {
    description:
      "The ongoing dance of markets rising and plunging \nPNG . 4724x2363 . Date: July, 2022 \n\nThis NFT was part of the Blind Gallery (www.blindgallery.xyz)",
    name: "Tidal",
    img: "assets/second-collection/15.png",
    metadata: "image",
    token_id: "15",
    link: "https://objkt.com/asset/KT1SiWsWp3JDDCkTaDZBkVahAxRQj1djX5Eg/15",
    author: "JVDE",
    twitter: "https://twitter.com/JvdeNft",
  },
  {
    description:
      "delay | defray | decay | dismay\n\nThis NFT was part of the Blind Gallery (www.blindgallery.xyz)",
    name: "Ursine Decline",
    img: "assets/second-collection/11.png",
    metadata: "image",
    token_id: "11",
    link: "https://objkt.com/asset/KT1SiWsWp3JDDCkTaDZBkVahAxRQj1djX5Eg/11",
    author: "Jess Hewitt",
    twitter: "https://twitter.com/rustysniper1",
  },
  {
    description:
      "From the outside looking in, to some, NFTs are a loser's game. They see the clickbait headlines, the negative comments from others, the copycat PFP projects - some with questionable traits! Prices that were once astronomical are now plummeting spectacularly during this bear market. They fear that all NFTs are burning the world.\n\nThey don't see past any of these falsehoods. They haven't yet discovered that there are many great authors grafting and creating brilliant art. They haven't yet discovered the 'green' Tezos blockchain and art community!\nWe are early. The rest will catch up. NFTs are NOT dead!\n\n*\n\nMixed media composition including AI, glitched/distressed typography, photography, digital painting, scanned illustrations and handwritten negative NFT quotes found online.\n\nThis NFT is part of the Blind Gallery (www.blindgallery.xyz)",
    name: "NFTs are DEAD",
    img: "assets/second-collection/12.jpg",
    metadata: "image",
    token_id: "12",
    link: "https://objkt.com/asset/KT1SiWsWp3JDDCkTaDZBkVahAxRQj1djX5Eg/12",
    author: "Nor44",
    twitter: "https://twitter.com/Nor44_lab",
  },
]


