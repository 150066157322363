import { render, staticRenderFns } from "./ViewClub.vue?vue&type=template&id=763acb4a&"
import script from "./ViewClub.vue?vue&type=script&lang=js&"
export * from "./ViewClub.vue?vue&type=script&lang=js&"
import style0 from "./ViewClub.vue?vue&type=style&index=0&id=763acb4a&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports