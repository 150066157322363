<template>
  <div>
    <b-nav class="bottom-menu" horizontal style="justify-content: space-around">
      <b-nav-item @click="showRow = true"
        ><span
          style="font-size: 26px"
          :class="
            showRow === true
              ? 'material-symbols-sharp'
              : 'material-symbols-outlined'
          "
        >
          image </span
        ><span style="font-size: small">Editions</span></b-nav-item
      >
      <b-nav-item @click="showRow = false">
        <span
          style="font-size: 26px"
          :class="
            showRow === false
              ? 'material-symbols-sharp'
              : 'material-symbols-outlined'
          "
        >
          filter_none </span
        ><span style="font-size: small">Collections</span></b-nav-item
      >
    </b-nav>
    <b-row>
      <b-col class="side-nav-container" lg="1">
        <b-nav vertical class="side-nav">
          <b-nav-item @click="showRow = true"
            ><span
              style="font-size: 26px"
              :class="
                showRow === true
                  ? 'material-symbols-sharp'
                  : 'material-symbols-outlined'
              "
            >
              image </span
            ><span style="font-size: small">Editions</span></b-nav-item
          >
          <b-nav-item @click="showRow = false">
            <span
              style="font-size: 26px"
              :class="
                showRow === false
                  ? 'material-symbols-sharp'
                  : 'material-symbols-outlined'
              "
            >
              filter_none </span
            ><span style="font-size: small">Collections</span></b-nav-item
          >
        </b-nav>
      </b-col>
      <b-col class="art-container" cols="12" lg="11">
        <b-row v-if="showRow === true">
          <b-col v-for="edition in editions" :key="edition.id" md="6" lg="4">
            <b-card
              img-top
              style="max-width: 37rem; border: none"
              class="mb-4 shadow-sm"
            >
              <div>
                <b-img
                  :src="edition.cover"
                  style="height: 350px; width: 100%; object-fit: cover"
                  alt="Preview"
                ></b-img>
                <h5 class="pt-4">{{ edition.title }}</h5>
                <b-card-text class="mb-4">
                  {{ edition.description }}
                </b-card-text>
              </div>
              <div class="editions-buttons">
                <router-link :to="edition.route">
                  <button class="primary-button">
                    View gallery
                  </button></router-link
                >
                <a :href="edition.link" target="_blank" class="secondary-button"
                  >View on {{ edition.marketplace }}
                  <b-icon icon="chevron-right" font-scale="0."></b-icon
                ></a>
              </div>
            </b-card>
          </b-col>
        </b-row>

        <b-row v-else>
          <b-col
            v-for="collection in collections"
            :key="collection.id"
            md="6"
            lg="4"
          >
            <b-card
              img-top
              style="max-width: 37rem; border: none"
              class="mb-4 shadow-sm"
            >
              <div>
                <b-img
                  :src="collection.cover"
                  style="height: 350px; width: 100%; object-fit: cover"
                  alt="Preview"
                ></b-img>
                <h5 class="pt-4">{{ collection.title }}</h5>
                <b-card-text class="mb-4">
                  {{ collection.description }}
                </b-card-text>
              </div>
              <a
                :href="collection.link"
                target="_blank"
                class="secondary-button"
                >Learn more
                <b-icon icon="chevron-right" font-scale="0."></b-icon
              ></a>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      editions: [
        {
          cover:
            "https://ipfs.io/ipfs/QmNwsCFPq2V7R3D12WS8Vste1ZxUWPsDu1znBsxjVaUypm",
          title: "Blind Gallery Edition 1",
          description:
            "During the 1st edition, 17 talented artists created artwork inspired by the mysterious and anon theme.",
          route: "/first-edition",
          link: "https://objkt.com/collection/KT1D8Q4HEMzoiBaGSVDdTTYMq9phz98WizVQ",
          marketplace: "objkt",
        },
        {
          cover:
            "https://ipfs.io/ipfs/QmZ8wihiztCgHAVH8QBtDL1sDPiaS542wUk8ZjeJqYeqtd",
          title: "Blind Gallery: Bear Market Edition",
          description:
            "During the Bear Market edition, 18 artists found inpiration in the adverse market conditions.",
          route: "/second-edition",
          link: "https://objkt.com/collection/KT1SiWsWp3JDDCkTaDZBkVahAxRQj1djX5Eg",
          marketplace: "objkt",
        },
        {
          cover:
            "https://gateway.fxhash.xyz/ipfs/QmTi1rWh8aFZDACFYpiEqmdGwXtPbTTw5rCpjbqxN4N6pJ",
          title: "Blind Gallery: Seed Edition",
          description:
            "Four generative collections created by Thomas Noya, DistCollective, Olivier Bodini and NIINOMI.",
          route: "/third-edition",
          link: "https://www.fxhash.xyz/u/Blind_Gallery",
          marketplace: "fxhash",
        },
        {
          cover:
            "https://gateway.fxhash.xyz/ipfs/QmQXmrvnjRAtV4qdbZ83yZybziiHpnwKEwYc3bgDE1PCyn",
          title: "Blind Gallery: Vistas Edition",
          description:
            "In collaboration with Feral File and a group of talented artists, the Vistas Edition has been minted on fxhash.",
          route: "/fourth-edition",
          link: "https://www.fxhash.xyz/u/Blind_Gallery",
          marketplace: "fxhash",
        },
      ],
      collections: [
        {
          cover:
            "https://assets.objkt.media/file/assets-003/QmbGGEaXh4XPeKqcqhhWWK7RZ9whDHE1AhjjfKcaRPTxNF/thumb400",
          title: "Surreality",
          description:
            "'Surreality' takes us on a journey through a realm of abstract and surreal beauty, like looking into a mirror and seeing the world reflected back at us in a whole new way.",
          link: "https://objkt.com/collection/KT1WUEkuv8gAT4CWe63MkDrmWArqLVbWa3Qx",
          marketplace: "objkt",
        },
        {
          cover:
            "https://gateway.fxhash.xyz/ipfs/QmUXM8bZRBzZJXQLyHx4gkS4wRafaJAoEgudXA2XUKG5RP",
          title: "Frequency of Affection",
          description:
            "A generative abstract art collection inspired by the interplay between signals and noise in physical and digital systems. Each piece explores the delicate balance between clarity and distortion.",
          link: "https://www.fxhash.xyz/generative/27617",
          marketplace: "fxhash",
        },
        {
          cover:
            "https://ipfs.io/ipfs/QmXmpTJK8HRu5MyXQGy7M6y8CoHbiAaYpmtmK1teiuSehT",
          title: "Humans Being",
          description:
            "Humans Being is a collection that delves into the complex relationship between humanity, technology, and perception.",
          link: "https://objkt.com/collection/KT1PoKqL2efwiSqT5JPK98ZF6fxJdinZwdrC",
          marketplace: "objkt",
        },
      ],
      showRow: true,
    };
  },

  methods: {
    toggleSideBar() {
      this.$refs.sidebar2.$refs.content.classList.toggle("b-sidebar_toggle");
    },
  },
};
</script>
<style>
.nav-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;

  margin: 1rem;
  transition: ease all 0.3s;
}

.nav-link:hover {
  background-color: #e0e0e0;
}

.side-nav {
  position: fixed;
  border-right: 1px solid lightgrey;
  height: 100vh;
}

.art-container {
  margin-left: 10rem;
}

.art-container .card {
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .side-nav-container {
    display: none;
  }

  .art-container {
    margin: 0rem 0rem 7rem 0rem;
  }
}
</style>
