import { Tezos } from "@/tezos/wallet";
import { OpKind } from "@taquito/taquito";
import store from "@/store/index";
import { DEFAULT_CONFIRMATION_BLOCKS, CONTRACTS } from "@/constants.js";

export const set_administrator = async (address, c = DEFAULT_CONFIRMATION_BLOCKS) => {
  if (!store.contracts.bg) {
    await store.getContracts();
  }

  const batch_operation = await Tezos.wallet
    .batch([
      {
        kind: OpKind.TRANSACTION,
        ...store.contracts.bg.methods
          .set_administrator(address)
          .toTransferParams(),
      },
    ])
    .send();

  const confirmation = await batch_operation.confirmation(c);
  return confirmation
}

export const add_moderator = async (address, name, c = DEFAULT_CONFIRMATION_BLOCKS) => {
  if (!store.contracts.bg) {
    await store.getContracts();
  }

  const batch_operation = await Tezos.wallet
    .batch([
      {
        kind: OpKind.TRANSACTION,
        ...store.contracts.bg.methods
          .add_moderator(address, name)
          .toTransferParams(),
      }
    ])
    .send()

  const confirmation = await batch_operation.confirmation(c);
  return confirmation
}

export const remove_moderator = async (address, c = DEFAULT_CONFIRMATION_BLOCKS) => {
  if (!store.contracts.bg) {
    await store.getContracts();
  }

  const batch_operation = await Tezos.wallet
    .batch([
      {
        kind: OpKind.TRANSACTION,
        ...store.contracts.bg.methods
          .remove_moderator(address)
          .toTransferParams(),
      }
    ])
    .send()

  const confirmation = await batch_operation.confirmation(c);
  return confirmation
}

export const set_pause = async (pause, c = DEFAULT_CONFIRMATION_BLOCKS) => {
  if (!store.contracts.bg) {
    await store.getContracts();
  }

  const batch_operation = await Tezos.wallet
    .batch([
      {
        kind: OpKind.TRANSACTION,
        ...store.contracts.bg.methods
          .set_pause(pause)
          .toTransferParams(),
      }
    ])
    .send()

  const confirmation = await batch_operation.confirmation(c);
  return confirmation
}
/*
[
  {
    "destination": "KT1CWgsZNmW5y7dkkoERULaT6Tz6CgHD7ftS",
    "kind": "transaction",
    "amount": 0,
    "parameters": { "entrypoint": "exchangeObjkt",
    "value":
      { "prim": "Pair", "args": [ { "int": "1" }, { "string": "test" } ] }
    }
  }
]
*/
export const buyArtPiece = async (edition, amount, price, c = DEFAULT_CONFIRMATION_BLOCKS) => {
  if (!store.contracts.bg) {
    await store.getContracts();
  }

  const batch_operation = await Tezos.wallet
    .batch([
      {
        kind: OpKind.TRANSACTION,
        ...store.contracts.bg.methods
        .exchangeObjkt(amount, edition)
        .toTransferParams(),
        amount: price / 1000000, //! the amount is in mutez
      }
    ])
    .send()

  const confirmation = await batch_operation.confirmation(c);
  return confirmation
}
/*
[
  {
    "destination": "KT1E54gPqGtUvgyxJtsvWKeUmmLJge32o2oT",
    "kind": "transaction",
    "amount": 0,
    "parameters": {
      "entrypoint": "register_pass",
      "value": {
        "prim": "Pair",
        "args": [
          {
            "prim": "Pair",
            "args": [
              {
                "int": "0" mint pass
              },
              {
                "int": "12" certificate
              }
            ]
          },
          {
            "string": "KT1SiWsWp3JDDCkTaDZBkVahAxRQj1djX5Eg"
          }
        ]
      }
    }
  }
]
*/
export const register_pass = async (address, certificate_id, mint_pass_id, c = DEFAULT_CONFIRMATION_BLOCKS) => {
  if (!store.contracts.bg) {
    await store.getContracts();
  }

  const batch_operation = await Tezos.wallet
    .batch([
      {
        kind: OpKind.TRANSACTION,
        ...store.contracts.bg.methods
          .register_pass(
            certificate_id,
            mint_pass_id,
            address)
          .toTransferParams(),
      }
    ])
    .send()

  const confirmation = await batch_operation.confirmation(c);
  return confirmation
}

export const unregister_pass = async (address, c = DEFAULT_CONFIRMATION_BLOCKS) => {
  if (!store.contracts.bg) {
    await store.getContracts();
  }

  const batch_operation = await Tezos.wallet
    .batch([
      {
        kind: OpKind.TRANSACTION,
        ...store.contracts.bg.methods
          .unregister_pass(address)
          .toTransferParams(),
      }
    ])
    .send()

  const confirmation = await batch_operation.confirmation(c);
  return confirmation
}

export const get_discount = async (item, address, amount, c = DEFAULT_CONFIRMATION_BLOCKS) => {
  if (!store.contracts.bg) {
    await store.getContracts();
  }

  if (item == "mint_pass") {

    const token_id = address == CONTRACTS.first_collection.fa2 ? CONTRACTS.first_collection.mint_pass_id : CONTRACTS.second_collection.mint_pass_id

    const batch_operation = await Tezos.wallet
      .batch([
        {
          kind: OpKind.TRANSACTION,
          ...store.contracts[address].methods
            .update_operators([
              {
                add_operator: {
                  owner: store.user_address,
                  operator: store.contracts.bg.address,
                  token_id: token_id,
                },
              },
            ])
            .toTransferParams(),
        },
        {
          kind: OpKind.TRANSACTION,
          ...store.contracts.bg.methods
            .mint_pass(address, 1)
            .toTransferParams(),
        }
      ])
      .send()

    const confirmation = await batch_operation.confirmation(c);
    return confirmation
  }
  else if (item == "certificate") {

    const token_id = address == CONTRACTS.first_collection.fa2 ? CONTRACTS.first_collection.certificate_id : CONTRACTS.second_collection.certificate_id

    const batch_operation = await Tezos.wallet
      .batch([
        {
          kind: OpKind.TRANSACTION,
          ...store.contracts[address].methods
            .update_operators([
              {
                add_operator: {
                  owner: store.user_address,
                  operator: store.contracts.bg.address,
                  token_id: token_id,
                },
              },
            ])
            .toTransferParams(),
        },
        {
          kind: OpKind.TRANSACTION,
          ...store.contracts.bg.methods
            .certificate(address, 1)
            .toTransferParams(),
        }
      ])
      .send()

    const confirmation = await batch_operation.confirmation(c);
    return confirmation
  }

  return Error("Invalid item")
}

export const add_discount = async (address, amount, c = DEFAULT_CONFIRMATION_BLOCKS) => {
  if (!store.contracts.bg) {
    await store.getContracts();
  }

  const batch_operation = await Tezos.wallet
    .batch([
      {
        kind: OpKind.TRANSACTION,
        ...store.contracts.bg.methods
          .add_allow_list(amount, address)
          .toTransferParams(),
      }
    ])
    .send()

  const confirmation = await batch_operation.confirmation(c);
  return confirmation
}

export const exchange_token = async (token_id, c = DEFAULT_CONFIRMATION_BLOCKS) => {
  const batch_operation = await Tezos.wallet
    .batch([
      {
        kind: OpKind.TRANSACTION,
        ...store.contracts.bg_collection_1.methods
          .update_operators([
            {
              add_operator: {
                owner: store.user_address,
                operator: store.contracts.bg.address,
                token_id: CONTRACTS.second_collection.mint_pass_id,
              },
            },
          ])
          .toTransferParams(),
      },
      {
        kind: OpKind.TRANSACTION,
        ...store.contracts.bg.methods
          .buy_art_piece(token_id)
          .toTransferParams(),
      },
    ])
    .send();
  const confirmation = await batch_operation.confirmation(c);
  return confirmation;
}

export const exchangeMintPass = async (tokenId, sku, c = DEFAULT_CONFIRMATION_BLOCKS) => {
  const batch_operation = await Tezos.wallet
    .batch([
      {
        kind: OpKind.TRANSACTION,
        ...store.contracts.fxhashFA2.methods
          .update_operators([
            {
              add_operator: {
                owner: store.user_address,
                operator: store.contracts.bg.address,
                token_id: tokenId,
              },
            },
          ])
          .toTransferParams(),
      },
      {
        kind: OpKind.TRANSACTION,
        ...store.contracts.bg.methods
          .exchangeFXHash(
              store.user_address,
              sku,
              tokenId
          )
          .toTransferParams(),
      },
    ])
    .send();
  const confirmation = await batch_operation.confirmation(c);
  return confirmation;
}

// [
//   {
//     "destination": "KT1LKsPyY8wtQPakj1Ro1FHknDczvaqGuE16",
//     "kind": "transaction",
//     "amount": 0,
//     "parameters": {
//       "entrypoint": "exchangeFXHash",
//       "value": {
//         "prim": "Pair",
//         "args": [
//           {
//             "prim": "Pair",
//             "args": [
//               {
//                 "string": "tz1ZLedXnXnPbk43LD1sHHG3NMXG7ZveZ1jr"
//               },
//               {
//                 "string": "kittos"
//               }
//             ]
//           },
//           {
//             "int": "1"
//           }
//         ]
//       }
//     }
//   }
// ]
