<template>

  <body>
    <PxAlerts v-bind:inTransaction="inTransaction" v-bind:showErrorMessage="lastErrorMessage"
      v-bind:showSuccessMessage="lastSuccessMessage"/>
    <!-- Gallery -->
    <PxCardOld v-bind:tokens="tokens" @toggleTransaction="toggleTransaction" @updateErrorMessage="updateErrorMessage"
    @updateSucessMessage="updateSuccessMessage"/>
  </body>
</template>

<script>
import PxCardOld from "@/components/PxCardOld.vue";
import PxAlerts from "@/components/PxAlerts.vue";

import update_token_metadata from "@/store/metadata/getters";

export default {
  name: "BearBlindGallery",
  components: {
    PxCardOld,
    PxAlerts
  },

  data() {
    return {
      tokens: [],
      inTransaction: false,
      lastErrorMessage: "",
      lastSuccessMessage: "",
    };
  },

  created() {
    update_token_metadata("second_collection").then((tokens) => {
      this.tokens = tokens;
    });
  },
  methods: {
    toggleTransaction() {
      this.inTransaction = !this.inTransaction;
    },
    updateErrorMessage(message) {
      this.lastErrorMessage = message;
    },
    updateSuccessMessage(message) {
      this.lastSuccessMessage = message;
    }
  }
};
</script>

