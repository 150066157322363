import hookcord from 'hookcord';
const Hook = new hookcord.Hook();

import { discord_id, discord_info } from '@/constants';

const send_message = (company, email, info) => {
  Hook.login(discord_id, discord_info);
  Hook.setPayload({
    "content": "Hey there! we have a new possible partner!",
    "embeds": [{
      "title": "Partner request",
      "color": 15257231,
      "fields": [
        {
          "name": "Company",
          "value": company
        },
        {
          "name": "Email",
          "value": email
        },
        {
          "name": "Info",
          "value": info
        }
      ]
    }]
  })

  Hook.fire()
    .then(_response_object => { })
    .catch(error => {
      throw error;
    })
}

export default send_message;
