<template>
  <div class="container">
    <PxAlerts ref="alerts" />
    <div class="row" style="padding-top: 12rem; padding-bottom: 8rem">
      <div
        class="col"
        ref="col"
        style="display: flex; align-items: center; justify-content: center"
      >
        <div class="form-style">
          <h1 style="font-weight: bold; padding-bottom: 1rem">
            Sponsor The Blind Gallery
          </h1>
          <form id="form" @submit="validateForm" method="post" ref="form">
            <label class="label-style" for="name">Company name</label><br />
            <p style="font-size: small; color: #de2455" v-show="nameWarn">
              Company name is required
            </p>

            <input
              @input="validateInputs"
              type="text"
              name="name"
              placeholder="Your name company"
              v-model="form['name']"
              ref="name"
              class="input-style"
            /><br /><br />
            <label class="label-style" for="email">Email</label><br />
            <p style="font-size: small; color: #de2455" v-show="emailWarn">
              Email is required
            </p>

            <input
              @input="validateInputs2"
              type="email"
              placeholder="Your email"
              name="email"
              ref="email"
              v-model="form['email']"
              class="input-style"
            /><br /><br />
            <label class="label-style" for="description"
              >Tell us a bit about your product</label
            ><br />
            <p
              style="font-size: small; color: #de2455"
              v-show="descriptionWarn"
            >
              Description is required
            </p>

            <textarea
              @input="validateInputs3"
              placeholder="General description"
              class="input-style"
              v-model="form['description']"
              name="description"
              type="text"
              ref="description"
            /><br /><br />
            <input
              @click="sendMessage"
              class="generic-button"
              style="width: 100%"
              type="submit"
              value="GET IN TOUCH"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import send_message from "@/discord/partners_webhook";
import PxAlerts from "@/components/PxAlerts.vue";
export default {
  name: "ViewSponsor",
  components: {
    PxAlerts,
  },

  data() {
    return {
      form: {},
      nameWarn: false,
      emailWarn: false,
      descriptionWarn: false,
    };
  },

  methods: {
    validateForm: function (e) {
      if (!this.form.name) {
        this.$refs["name"].classList.remove("input-style");
        this.$refs["name"].classList.add("input-style-toggle");
        this.nameWarn = true;
      } else if (this.form.name !== "") {
        this.$refs["name"].classList.remove("input-style-toggle");
        this.$refs["name"].classList.add("input-style");
        this.nameWarn = false;
      }
      if (!this.form.email) {
        this.$refs["email"].classList.remove("input-style");
        this.$refs["email"].classList.add("input-style-toggle");
        this.emailWarn = true;
      } else if (this.form.email !== "") {
        this.$refs["email"].classList.remove("input-style-toggle");
        this.$refs["email"].classList.add("input-style");
        this.emailWarn = false;
      }

      if (!this.form.description) {
        this.$refs["description"].classList.remove("input-style");
        this.$refs["description"].classList.add("input-style-toggle");
        this.descriptionWarn = true;
      } else if (this.form.description !== "") {
        this.$refs["description"].classList.remove("input-style-toggle");
        this.$refs["description"].classList.add("input-style");
        this.descriptionWarn = false;
      }

      e.preventDefault();
    },

    validateInputs() {
      if (!this.form.name) {
        this.$refs["name"].classList.remove("input-style");
        this.$refs["name"].classList.add("input-style-toggle");
        this.nameWarn = true;
      } else {
        this.$refs["name"].classList.remove("input-style-toggle");
        this.$refs["name"].classList.add("input-style");
        this.nameWarn = false;
      }
    },
    validateInputs2() {
      if (!this.form.email) {
        this.$refs["email"].classList.remove("input-style");
        this.$refs["email"].classList.add("input-style-toggle");
        this.emailWarn = true;
      } else {
        this.$refs["email"].classList.remove("input-style-toggle");
        this.$refs["email"].classList.add("input-style");
        this.emailWarn = false;
      }
    },

    validateInputs3() {
      if (!this.form.description) {
        this.$refs["description"].classList.remove("input-style");
        this.$refs["description"].classList.add("input-style-toggle");
        this.descriptionWarn = true;
      } else {
        this.$refs["description"].classList.remove("input-style-toggle");
        this.$refs["description"].classList.add("input-style");
        this.descriptionWarn = false;
      }
    },

    sendMessage() {
      send_message(this.form.name, this.form.email, this.form.description);
      if (
        this.form.name !== undefined &&
        this.form.email !== undefined &&
        this.form.description !== undefined
      ) {
        this.$refs.alerts.showSuccessAlert("Your information has been sent");
        this.$refs["form"].reset();
        this.form = {};
      }
    },
  },
};
</script>

<style>
.label-style {
  font-size: 1.5rem;
  padding-bottom: 1rem;
}

.form-style {
  padding: 3rem;
  box-shadow: -1px 11px 29px -10px rgba(0, 0, 0, 0.54);
  -webkit-box-shadow: -1px 11px 29px -10px rgba(0, 0, 0, 0.54);
  border-radius: 5px;
}

.input-style {
  padding: 0.5rem;
  font-size: small;
  width: 100%;
  border: 1px solid #252525;
  border-radius: 5px;
}

.input-style-toggle {
  padding: 0.5rem;
  font-size: small;
  width: 100%;
  border: 1px solid #de2455;
  border-radius: 5px;
}

@media (max-width: 425px) {
  .form-style {
    box-shadow: none;
    padding: 0;
  }
}
</style>
