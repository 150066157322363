<template>
  <div class="container">
    <div
      class="row"
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-top: 8rem;
      "
    >
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 2rem;
        "
      >
        <img
          src="https://cdn.discordapp.com/attachments/987378128106168403/1055561011412865145/Recurso_19.png"
          alt="Incubator"
          style="min-width: 270px; max-width: 500px; padding: 1rem"
        />
      </div>
      <div
        class="col col-lg-6"
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 1rem;
        "
      >
        <p style="text-align: center; font-size: 1.2rem">
          The Art Incubator is a
          <span style="font-weight: 600">place for artists</span> pushing the
          boundaries between
          <span style="font-weight: 600">technology and art</span> to experiment
          and release artworks using the blind concept.
        </p>
      </div>
    </div>
    <hr />
    <div
      class="row requerimientos-info"
      style="display: flex; justify-content: center"
    >
      <div class="col" style="border">
        <div class="requerimientos-contenedor">
          <h3 style="font-weight: 600; padding-top: 1rem">
            Who is the Incubator for?
          </h3>
          <p style="font-weight: 600">
            Artists working with digital tools in the generative art space.
          </p>

          <ul style="color: #57606a">
            <li>Creating art with code (p5.js, javascript, processing)</li>
            <li>
              Creating art with AI technologies (neural networks, custom
              training data).
            </li>
            <li>
              Pushing the boundaries of emerging AI technologies like gptChat.
            </li>
            <li>
              Using blockchain and its elements to create engaging dynamics.
            </li>
          </ul>
        </div>
      </div>

      <div class="col" style="border">
        <div class="requerimientos-contenedor">
          <h3 style="font-weight: 600; padding-top: 1rem">
            What is the process?
          </h3>
          <p style="font-weight: 600">Simple and transparent process. <br /></p>

          <ul style="color: #57606a">
            <li>Apply.</li>
            <li>
              The Blind Gallery Club members will vote on a recurrent basis.
            </li>
            <li>Selected artists join the incubator.</li>
            <li>
              Work on your art with feedback from the Blind Gallery team and
              curators.
            </li>
            <li>
              Release your work through the Blind Gallery surprise drops or
              editions.
            </li>
          </ul>
        </div>
      </div>

      <div class="col" style="border">
        <div class="requerimientos-contenedor">
          <h3 style="font-weight: 600; padding-top: 1rem">How to apply?</h3>
          <p style="font-weight: 600">Tell us a bit about... <br /></p>

          <ul style="color: #57606a">
            <li>
              What are you exploring with your art in one or two sentences (your
              vision).
            </li>
            <li>Provide non-released artwork examples.</li>
            <li>
              Tell us a bit about your process, inspiration and technologies.
            </li>
          </ul>
          <br />
          <br />

          <a
            href="https://app.joyn.xyz/contest/art-incubator-by-blind-gallery-2815f6b98b7a"
            target="_blank"
          >
            <button class="primary-button" style="width: 100%">
              APPLY ON JOYN
            </button></a
          >
        </div>
      </div>
      <div>
        <div
          style="display: flex; align-items: center; justify-content: center"
        >
          <a
            href="https://blindgallery.substack.com/p/art-incubator"
            target="_blank"
            ><button
              style="
                background: transparent;
                color: rgb(0, 147, 163);
                border: 1px solid rgb(0, 147, 163);
                min-width: 300px;
                max-width: 400px;

                border-radius: 0.2rem;
                font-weight: 600;
                padding: 0.5rem 0rem 0.5rem 0;
                margin-top: 1rem;
              "
            >
              READ MORE
            </button></a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ViewIncubator",
  components: {},
};
</script>

<style>
.requerimientos-contenedor {
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(212, 212, 212);
  min-height: 396px;
  max-height: 570px;
  padding: 1.2rem;
}

@media (max-width: 1400px) {
  .requerimientos-contenedor {
    min-height: 475px;
  }
}

@media (max-width: 1200px) {
  .requerimientos-contenedor {
    max-height: 900px;
    min-height: 572px;
  }
}
@media (max-width: 1024px) {
  .requerimientos-contenedor {
    margin-bottom: 1rem;
  }
  .requerimientos-info {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .requerimientos-contenedor {
    min-height: 250px;
  }
}

@media (max-width: 425px) {
  .requerimientos-contenedor {
    min-height: 370px;
  }
}
</style>
