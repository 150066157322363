<template>
  <div>
    <b-container>
      <b-row>
        <b-col
          v-for="(collection, index) in collections"
          :key="index"
          lg="4"
          md="6"
          cols="12"
        >
          <div class="rounded shadow-sm d-flex flex-column p-4 my-4">
            <div class="ratio ratio-1x1">
              <img
                style="object-fit: contain; cursor: pointer"
                :src="collection.cover"
                :alt="collection.alt"
                class="w-100"
                @click="$bvModal.show(`modal-${index}`)"
              />
            </div>
            <div style="margin-top: 1rem">
              <a
                class="secondary-button m-0"
                style="cursor: pointer; color: #0093a3"
                :href="collection.twitter"
                target="_blank"
                >{{ collection.artist }}</a
              >
              <h4>{{ collection.title }}</h4>

              <div
                class="d-flex justify-content-between w-100 align-items-center"
              >
                <a
                  :href="collection.collection"
                  target="_blank"
                  style="cursor: pointer"
                  class="primary-button"
                  >Mint on fxhash</a
                >
                <p
                  class="secondary-button m-0"
                  @click="$bvModal.show(`modal-${index}`)"
                  style="cursor: pointer; color: #0093a3"
                >
                  Learn more
                </p>
              </div>
            </div>
          </div>

          <b-modal
            centered
            size="lg"
            hide-header
            hide-footer
            :id="`modal-${index}`"
          >
            <span
              style="
                cursor: pointer;
                position: absolute;
                right: 1rem;
                z-index: 3;
              "
              @click="$bvModal.hide(`modal-${index}`)"
            >
              <Icon icon="material-symbols:close" width="32" height="32" />
            </span>
            <div class="p-4">
              <swiper
                :navigation="true"
                :modules="modules"
                :effect="'fade'"
                :spaceBetween="30"
                :pagination="{
                  clickable: true,
                }"
                @swiper="updateSwiper"
                class="swiper"
              >
                <swiper-slide
                  v-for="preview in collection.previews"
                  :key="preview.id"
                  class="ratio ratio-1x1"
                  style="height: 85%"
                  ><img
                    width="100%"
                    style="object-fit: contain"
                    :src="preview.prev"
                    alt="preview"
                /></swiper-slide>
              </swiper>

              <div class="d-lg-flex align-items-center justify-content-between">
                <div>
                  <a
                    class="secondary-button m-0"
                    style="cursor: pointer; color: #0093a3"
                    target="_blank"
                    :href="collection.twitter"
                    >{{ collection.artist }}</a
                  >
                  <h4>{{ collection.title }}</h4>
                </div>
              </div>
              <p style="white-space: pre-line" class="text-secondary m-0 small">
                {{ collection.description }}
              </p>
            </div>
          </b-modal>

          <b-modal
            centered
            size="md"
            hide-header
            hide-footer
            :id="`modal-alert-${index}`"
          >
            <div class="d-flex flex-column align-items-center p-4 text-center">
              <Icon
                width="74"
                class="my-4"
                icon="material-symbols:release-alert-rounded"
              />

              <h4>
                You are going to exchange your mint pass for
                <b>{{ collection.title }}</b>
              </h4>
              <p class="small text-secondary">
                Once you get your reserve, you will be able to mint your piece
                in fxhash on September 8 for free.
              </p>
              <div class="d-flex align-items-center" style="gap: 1rem">
                <button
                  class="tertiary-button"
                  @click="$bvModal.hide(`modal-alert-${index}`)"
                >
                  Cancel
                </button>
                <button
                  class="primary-button"
                  @click="
                    exchange(collection.title);
                    $bvModal.hide(`modal-alert-${index}`);
                  "
                >
                  Confirm
                </button>
              </div>
            </div></b-modal
          >
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { Pagination, EffectFade, Navigation } from "swiper";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import "swiper/swiper-bundle.css";
SwiperCore.use([Pagination, Navigation]);

export default {
  name: "ViewVistasEd",
  components: { Swiper, SwiperSlide },

  data() {
    return {
      modules: [Pagination, EffectFade, Navigation],
      collections: [
        {
          artist: "Aleksandra Jovanić",
          twitter: "https://twitter.com/alexis_o_O",
          cover: "/assets/FFE/1.png",
          alt: "Enchanted",
          title: "Enchanted",
          editions: "",
          description: `The artist behind Enchanted delves into the analyses of fairy tales and contemplates their true and 'untrue' nature. Within these tales, a hero embarks on a journey where various events unfold in random or loosely connected ways.
          \nThese incidents occasionally result in unlikely plot twists, yet these enigmatic transformations culminate in a narrative loop, evoking a fusion of familiarity and an aura of mystery.`,
          previews: [
            {
              prev: "/assets/FFE/1.png",
            },
            {
              prev: "https://cdn.discordapp.com/attachments/987378128106168403/1141363215406735380/2.png",
            },
            {
              prev: "https://cdn.discordapp.com/attachments/987378128106168403/1141363215800991795/3.png",
            },
            {
              prev: "https://cdn.discordapp.com/attachments/987378128106168403/1141363216375627916/4.png",
            },
            {
              prev: "https://cdn.discordapp.com/attachments/987378128106168403/1141363216715358248/5.png",
            },
          ],
          collection: "https://www.fxhash.xyz/generative/28810",
        },

        {
          artist: "Kitel",
          twitter: "https://twitter.com/Kitel87",
          cover: "/assets/FFE/3.jpg",
          alt: "Idem Locus",
          title: "Idem Locus",
          editions: "",
          description: `The artist of this art piece found its inspiration not in an individual artist, but rather in the aesthetic of an entire era - the engravings from the Late Middle Ages and the early Renaissance.
          \n"Idem Locus" (Latin for "The Same Place") was conceived as a result of delving into the engravings of the 15th and 16th centuries, contrasting the portrayed locations with their contemporary counterparts.`,
          previews: [
            {
              prev: "/assets/FFE/3.jpg",
            },
            {
              prev: "https://cdn.discordapp.com/attachments/987378128106168403/1141365552040915036/Idem_Locus_2.jpg",
            },
            {
              prev: "https://cdn.discordapp.com/attachments/987378128106168403/1141365552611348510/Idem_Locus_3.jpg",
            },
            {
              prev: "https://cdn.discordapp.com/attachments/987378128106168403/1141365552921714710/Idem_Locus_4.jpg",
            },
            {
              prev: "https://cdn.discordapp.com/attachments/987378128106168403/1141365551369826314/Idem_Locus_5.jpg",
            },
          ],
          collection: "https://www.fxhash.xyz/generative/28809",
        },

        {
          artist: "phenomena",
          twitter: "https://twitter.com/Phenomenalabs",
          cover: "/assets/FFE/2.png",
          alt: "Dio",
          title: "Dio",
          editions: "",
          description: `"Transcendental Landscapes: A Dio Collection" is a digital echo of Sumi-e, the ancient painting technique embodying simplicity and tranquility.
          \nThis artwork seeks to echo the quiet conversations between the artist and nature, as witnessed in the classic brushstrokes of Sumi-e. It is a dance between the digital and the traditional, with the echo of the ink resonating in the algorithmic strokes that paint landscapes of the mind.`,
          previews: [
            {
              prev: "/assets/FFE/2.png",
            },
            {
              prev: "https://cdn.discordapp.com/attachments/987378128106168403/1141364515825528862/1104.png",
            },
            {
              prev: "https://cdn.discordapp.com/attachments/987378128106168403/1141364516488224869/1106.png",
            },
            {
              prev: "https://cdn.discordapp.com/attachments/987378128106168403/1141364516765057154/1199.png",
            },
            {
              prev: "https://cdn.discordapp.com/attachments/987378128106168403/1141364515011833988/1222.png",
            },
          ],
          collection: "https://www.fxhash.xyz/generative/28801",
        },

        {
          artist: "ykxotkx",
          twitter: "https://twitter.com/ykxotkx",
          cover: "/assets/FFE/4.png",
          alt: "Fuji",
          title: "Fuji",
          editions: "",
          description: `The creator of Fuji, draws its inspiration from Mt. Fuji, the highest and most famous sacred mountain in Japan. Crafted with the sense of awe that the artist feels every time they see Mt. Fuji, this piece captures its essence.
          \nThroughout history, Mt. Fuji has been portrayed in numerous Japanese paintings and ukiyo-e. This art piece is a homage to the accomplishments of those who came before.`,
          previews: [
            {
              prev: "/assets/FFE/4.png",
            },
            {
              prev: "https://cdn.discordapp.com/attachments/987378128106168403/1141366550700507166/2.png",
            },
            {
              prev: "https://cdn.discordapp.com/attachments/987378128106168403/1141366550947967126/3.png",
            },
            {
              prev: "https://cdn.discordapp.com/attachments/987378128106168403/1141366551220592700/4.png",
            },
            {
              prev: "https://cdn.discordapp.com/attachments/987378128106168403/1141366549295411370/5.png",
            },
          ],
          collection: "https://www.fxhash.xyz/generative/28812",
        },

        {
          artist: "gpitombo",
          twitter: "https://twitter.com/gpitombo",
          cover: "/assets/FFE/5.png",
          alt: "Grove",
          title: "Grove",
          editions: "",
          description: `An ode to the past comes to life within this artwork, drawing inspiration from the hand-drawn landscapes of ancient times. Through detailed brushstrokes, Grove unfolds as if it were a long-lost treasure, evealing a serene landscape.
          \nThe color palette it's smooth, reminiscent of the nostalgic tones found in antique artistic mediums, conveying a sense of timeless tranquility.`,
          previews: [
            {
              prev: "/assets/FFE/5.png",
            },
            {
              prev: "https://cdn.discordapp.com/attachments/987378128106168403/1141367606335508621/2.png",
            },
            {
              prev: "https://cdn.discordapp.com/attachments/987378128106168403/1141367606851416136/3.png",
            },
            {
              prev: "https://cdn.discordapp.com/attachments/987378128106168403/1141367607434416249/4.png",
            },
            {
              prev: "https://cdn.discordapp.com/attachments/987378128106168403/1141367605400178759/5.png",
            },
          ],
          collection: "https://www.fxhash.xyz/generative/28800",
        },

        {
          artist: "Alba G. Corral",
          twitter: "https://twitter.com/albagcorral",
          cover: "/assets/FFE/6.png",
          alt: "Motorbike Rides",
          title: "Motorbike Rides",
          editions: "",
          description: `"Motorbike Rides" is inspired by the artist's exploration of fields nearby home, where serene rice fields, delicate flowers, and butterflies fuel their creativity.
          \nThe artist captures flowers and grass while infusing butterflies with lifelike motion.`,
          previews: [
            {
              prev: "/assets/FFE/6.png",
            },
            {
              prev: "https://cdn.discordapp.com/attachments/987378128106168403/1142132106613489664/2.png",
            },
            {
              prev: "https://cdn.discordapp.com/attachments/987378128106168403/1142133579216867418/6.png",
            },
            {
              prev: "https://cdn.discordapp.com/attachments/987378128106168403/1142132108119253104/4.png",
            },
            {
              prev: "https://cdn.discordapp.com/attachments/987378128106168403/1142132108668711032/5.png",
            },
          ],
          collection: "https://www.fxhash.xyz/generative/28802",
        },
      ],
    };
  },
};
</script>
<style></style>
