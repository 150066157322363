//! bigmap_key is the parameter that is used to call the smart contract
import { gql, request } from 'graphql-request';
import { OBKJT_API } from '@/constants';

const get_objkt_listing = async (fa2) => {
  const query = gql`
    {
    token(
      where: {fa_contract: {_eq: "${fa2}"}}
      order_by: {token_id: asc}
    ) {
      lowest_ask
      token_id
      listings(where: {status: {_eq: "active"}}, order_by: {price: asc}, limit: 1) {
        price
        status
        marketplace_contract
        id
        bigmap_key
      }
    }
  }
  `;
  let result = {};
  const response = await request(OBKJT_API, query);

  response.token.forEach((token) => {
      result[token.token_id] = {
        floor: token.lowest_ask,
        listing_id: token.listings.length ? token.listings[0].bigmap_key : null,
        listing_contract: token.listings.length ? token.listings[0].marketplace_contract : null
      }

  });

  console.warn(result)

  return result;

}

export default get_objkt_listing;
