import { first_edition_token_metadata, bear_edition_token_metadata } from '@/store/metadata/token_metadata'
// External APIs
export const TEZTOK = 'https://api.teztok.com/v1/graphql';
export const TZKT_API = 'https://api.tzkt.io/v1/accounts';
export const TZKT_API_GHOST = 'https://api.ghostnet.tzkt.io'
export const IPFS_URLS = ['https://ipfs.io/ipfs'];
export const OBKJT_API = 'https://data.objkt.com/v3/graphql';
export const FXHASH_API = 'https://api.fxhash.xyz/graphql';
export const TEZOS_PROFILES = 'https://indexer.tzprofiles.com/v1/graphql';

// Tezos
export const TEZOS_RCP_URI = 'https://mainnet.api.tez.ie';

export const better_call_dev_chain_names = {
  mainnet: 'mainnet',
  ithacanet: 'ithacanet',
  hangzhounet: 'hangzhou2net',
  ghostnet: 'ghostnet'
}

const tzkt_api_endpoints = {
  mainnet: 'https://api.mainnet.tzkt.io',
  ghostnet: 'https://api.ghostnet.tzkt.io'
}

const endpoints = {
  mainnet: 'https://rpc.tzkt.io/mainnet',
  granadanet: 'https://granadanet.smartpy.io',
  florencenet: 'https://florencenet.smartpy.io',
  hangzhounet: 'https://hangzhounet.smartpy.io',
  ithacanet: 'https://ithacanet.smartpy.io',
  ghostnet: 'https://rpc.ghostnet.teztnets.xyz'
}

const contracts = {
  mainnet: {
    first_collection: {
      fa2: 'KT1D8Q4HEMzoiBaGSVDdTTYMq9phz98WizVQ',
      address: 'KT195oHs7H3KcQ5qbDRvwo6zo5hGtfQ9qCK7',
      mint_pass_id: 0,
      certificate_id: 16,
      tokens : first_edition_token_metadata
    },
    second_collection: {
      fa2: 'KT1SiWsWp3JDDCkTaDZBkVahAxRQj1djX5Eg',
      address: 'KT1D1L1m1JUrqTCCrYd6cCL67Qhd6wTLsbwG',
      mint_pass_id: 19,
      certificate_id: 20,
      tokens : bear_edition_token_metadata
    },
    club: {
      fxhash_collection_id: 22532
    },
    third_collection: {
      address: 'KT1K9DtLFcsCret4kPZXuutoqr8V7E2UmKnC',
      fa2: 'KT1U6EHmNxJTkvaWJ4ThczG4FSDaHC21ssvi',
      fxhash_collection_id: 22532,
      fxhash_mint_pass_id: 24755,
      collections: [
        {sku: 'ciudad-central', fxhashCollectionId: 0},
        {sku: 'fraktur', fxhashCollectionId: 1},
        {sku: 'brutal-infection', fxhashCollectionId: 2},
        {sku: 'the-dance-of-the-machines', fxhashCollectionId: 3},
        {sku: 'kittos', fxhashCollectionId: 4},
      ]
    },
    sales: {
      address: 'KT1T8vxZSkQadVA4BbW9RpV6dLnCicqUuZfD'
    },
    badges: {
      fa2: 'KT1FEG1HkfBNhcggVCQruCE69Bw3CsYimPqU',
      names: {
        first_edition_artist: '1st Edition Collector',
        first_edition_collector: '1st Edition Artist',
        bear_artists: 'Bear Artist',
        bear_collector: 'Bear Collector',
        club_members: 'Club Member 2022',
        seeds_artist: 'Seed Artist',
        seeds_collector: 'Seed Collector',
        collectors_chat: 'Collectors Chat'
      },
      first_edition_artist: 0,
      first_edition_collector: 1,
      bear_artists: 2,
      bear_collector: 3,
      club_members: 5,
      seeds_artist: 7,
      seeds_collector: 6,
      collectors_chat: 9,
      blind_curator: 10,
      surreality_collector: 11,
    },
    voting: {
      address: 'KT1VsFKM374E13doYMwq1NvKuxQept2pzayc'
    }
  },
  ghostnet: {
    first_collection: {
      fa2: 'KT1K9BT8Wv18Ex1VFbvsvGVEP43nkK4S4oGn',
      address: 'KT1Uc9BfZk98iVbF1JY5XbhQ1TrZuvXWuBWo',
      mint_pass_id: 0,
      certificate_id: 16,
      tokens : bear_edition_token_metadata
    },
    batches: {
      fa2: 'KT1QJxoRAcALXS8X9HynrsZ9bJC51TG2oq4D',
    }
  },
}
export const BLIND_GALLERY_API = 'blind-gallery-api.herokuapp.com'
export const BlindGalleryContractAddress = 'KT1D8Q4HEMzoiBaGSVDdTTYMq9phz98WizVQ';
export const FxHashContractAddress = 'KT1BJC12dG17CVvPKJ1VYaNnaT5mzfnUTwXv';
export const ObjktContractAddress = 'KT1WvzYHCNBvDSdwafTHv7nJ1dWmZ8GCYuuC';
export const FxHashContractFa2 = 'KT1EfsNuqwLAWDd3o4pvfUx1CAh5GMdTrRvr'

export const admin_address = {
  'tz1ZLedXnXnPbk43LD1sHHG3NMXG7ZveZ1jr' : 'Tlalocman'
}

export const discord_id = '1047243535449063565';
export const discord_info = 'r_dJXJoUCU7C-Gm7lHcV9-AqwC_OANhx06CdztLWUDpAxeOKHGEwNOarJSOZkoJjD3Pr'

//! change this for dev - prod environments
export const CHAIN = 'mainnet';
export const DEFAULT_CONFIRMATION_BLOCKS = 1;

export const CHAIN_NAME = better_call_dev_chain_names[CHAIN]
export const ENDPOINT = endpoints[CHAIN]
export const CONTRACTS = contracts[CHAIN]

export const TZKT_API_ENDPOINT = tzkt_api_endpoints[CHAIN]

export const TLALOCMAN_PKH = 'tz1ZLedXnXnPbk43LD1sHHG3NMXG7ZveZ1jr'
export const BANK_PKH = "tz1UdddbVe3icmr5LRP1monxfR13ChsidcoX"
// Beacon
export const DEFAULT_MATRIX_NODE = 'beacon-node-1.sky.papers.tech'
