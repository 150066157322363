import { gql, request } from 'graphql-request';
import { FXHASH_API } from '@/constants';
import IPFSGatewayTools from "@pinata/ipfs-gateway-tools/dist/browser";
const gatewayTools = new IPFSGatewayTools();

const query = gql`
  query Query_root($userId: String, $filters: ObjktFilter) {
  user(id: $userId) {
    objkts(filters: $filters) {
      name
      metadata
      metadataUri
      rarity
    }
  }
}
`;

const get_fxhash_club_member = async (pkh) => {
  const response = await request(FXHASH_API, query, {
    userId: pkh,
    filters: {
      "searchQuery_eq": "Blind Gallery Club Membership"
    },
  });

  let benefits = { regular: 0, premium: 0, legendary: 0, reserves: 0, collectionsReserves: 0, airdrop: 0, total: 0, previews: [] };

  for (var i = 0; i < response.user.objkts.length; i++) {

    benefits.previews.push(gatewayTools.convertToDesiredGateway(response.user.objkts[i].metadata.displayUri, 'https://ipfs.io'))
    for (var j = 0; j < response.user.objkts[i].metadata.attributes.length; j++) {

      switch (response.user.objkts[i].metadata.attributes[j].value) {
        case "regular":
          benefits.regular += 1;
          benefits.total += 1;
          benefits.collectionsReserves += 1
          benefits.reserves += 2;
          break;

        case "premium":
          benefits.premium += 1;
          benefits.total += 1;
          benefits.collectionsReserves += 1
          benefits.reserves += 2;
          benefits.airdrop += 1;
          break;

        case "legendary":
          benefits.legendary += 1;
          benefits.total += 1;
          break;

        default:
          break;
      }
    }
  }

  return benefits;
};

export default get_fxhash_club_member;
