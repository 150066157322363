<template>
  <div>
    <!-- Transaction alert -->
    <b-modal id="confirm-transaction" centered hide-header ok-only hide-footer
      ><div class="d-flex flex-column align-items-center p-5">
        <Icon class="mb-2" width="3rem" icon="line-md:loading-twotone-loop" />

        <h3 class="text-center mb-2">Transaction in progress</h3>
        <p>This may take a few seconds...</p>
      </div>
    </b-modal>
    <!-- Success alert -->
    <b-alert
      class="success-alert"
      :show="successAlertTimeRemaining"
      variant="success"
      @dismissed="successAlertTimeRemaining = 0"
      @dismiss-count-down="reduceTimeRemaining"
    >
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <p style="color: white; margin: 0">{{ successMessage }}</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="white"
          class="bi bi-check-circle-fill"
          viewBox="0 0 16 16"
        >
          <path
            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
          />
        </svg>
      </div>
    </b-alert>

    <!-- Info alert -->
    <b-alert
      :show="infoAlertTimeRemaining"
      variant="info"
      @dismissed="infoAlertTimeRemaining = 0"
      @dismiss-count-down="reduceTimeRemaining"
      class="info-alert"
    >
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <p style="color: white; margin: 0">{{ infoMessage }}</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="white"
          class="bi bi-info-circle-fill"
          viewBox="0 0 16 16"
        >
          <path
            d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm0-1A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm-.002-4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm.002-3a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5z"
          />
        </svg>
      </div>
    </b-alert>

    <!-- Error alert -->
    <b-alert
      :show="errorAlertTimeRemaining"
      variant="warning"
      @dismissed="errorAlertTimeRemaining = 0"
      @dismiss-count-down="reduceTimeRemaining"
      class="error-alert"
    >
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <p style="color: white; margin: 0">{{ errorMessage }}</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="white"
          class="bi bi-exclamation-circle-fill"
          viewBox="0 0 16 16"
        >
          <path
            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
          />
        </svg>
      </div>
    </b-alert>
  </div>
</template>
<script>
import { Icon } from "@iconify/vue2";
export default {
  name: "PxAlerts",
  components: {
    Icon,
  },
  data() {
    return {
      dismissSecs: 6,

      errorMessage: "",
      infoMessage: "",
      successMessage: "",

      inTransaction: false,

      successAlertTimeRemaining: 0,
      infoAlertTimeRemaining: 0,
      errorAlertTimeRemaining: 0,
    };
  },

  methods: {
    toggleTransaction() {
      this.$bvModal.show("confirm-transaction");
      if (this.inTransaction === true) {
        this.$bvModal.show("confirm-transaction");
      }
      if (this.inTransaction === false) {
        this.$bvModal.hide("confirm-transaction");
      }
    },
    showSuccessAlert(successMessage) {
      this.successMessage = successMessage;
      this.successAlertTimeRemaining = this.dismissSecs;
    },
    showInfoAlert(infoMessage) {
      this.infoMessage = infoMessage;
      this.infoAlertTimeRemaining = this.dismissSecs;
    },
    showErrorAlert(errorMessage) {
      this.errorMessage = errorMessage;
      this.errorAlertTimeRemaining = this.dismissSecs;
    },
    reduceTimeRemaining(countdown) {
      if (this.successAlertTimeRemaining > 0) {
        this.successAlertTimeRemaining = countdown;
      }
      if (this.errorAlertTimeRemaining > 0) {
        this.errorAlertTimeRemaining = countdown;
      }
    },
  },

  created() {},
};
</script>

<style scoped>
.success-alert {
  background-color: #12b104;
  color: #ffffff;
  font-size: small;
  border: none;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  right: 1rem;
  z-index: 99;
  border-radius: 0.2rem;
  width: 20rem;
}

.info-alert {
  background-color: #0093a3;
  color: #ffffff;
  font-size: small;
  border: none;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  right: 1rem;
  z-index: 99;
  border-radius: 0.2rem;
  width: 20rem;
}

.error-alert {
  background-color: rgb(222, 36, 85);
  color: #ffffff;
  font-size: small;
  border: none;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  right: 1rem;
  z-index: 99;
  border-radius: 0.2rem;
  width: 20rem;
}

.close {
  display: none;
}
</style>
