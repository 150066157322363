<template>
  <div>
    <b-row class="main-row">
      <b-col lg="4">
        <template v-if="benefits.previews.length > 1">
          <swiper
            :modules="modules"
            :effect="'fade'"
            :spaceBetween="30"
            :pagination="{
              clickable: true,
            }"
          >
            <swiper-slide
              v-for="membership in benefits.previews"
              :key="membership"
              ><img
                style="padding-bottom: 3rem; width: 100%"
                :src="membership"
                alt="preview"
            /></swiper-slide>
          </swiper>
        </template>
        <div v-else><img width="100%" :src="benefits.previews" /></div>
        <div v-if="benefits.total === 0">
          <img
            width="100%"
            src="https://gateway.fxhash.xyz/ipfs/QmTuTT9dLkLXhnzTgRBSCmbtPyiZhzDwTux9J22GAwNY3c"
          />
        </div>
      </b-col>
      <b-col lg="8" style="padding: 2rem">
        <p>
          Status<br />
          <span class="span-titles" v-if="benefits.total >= 1">Active</span>
          <span class="span-titles" v-else>Inactive</span>
        </p>
        <p>
          Number of memberships<br /><span class="span-titles">{{
            this.benefits.total
          }}</span>
        </p>
        <p>
          Your reserves<br />
          <span
            class="span-titles"
            v-if="this.benefits.legendary && this.benefits.total === 1"
          >
            Full set of mint passes and limited collections airdropped.
          </span>
          <span class="span-titles" v-else>
            <span class="span-titles" v-if="this.benefits.reserves"
              >Editions x {{ this.benefits.reserves }}
              <span class="span-titles" v-if="this.benefits.airdrop"
                >, {{ this.benefits.airdrop }} airdrop</span
              >
              <br />Limited Collections x
              {{ this.benefits.collectionsReserves }}</span
            >

            <span class="span-titles" v-if="this.benefits.legendary"
              >, full set of mint passes airdropped.
            </span>
          </span>
        </p>
        <p>Club chat</p>
        <a href="https://discord.com/invite/JT4DBS95c7" target="_blank">
          <button style="margin-bottom: 1rem" class="primary-button">
            JOIN DISCORD
          </button></a
        >
        <div v-show="this.benefits.total < 1">
          <p>Join the Club</p>
          <a
            href="https://www.fxhash.xyz/generative/slug/blind-gallery-club-membership"
            target="_blank"
          >
            <button
              style="
                background-color: transparent;
                border: 1px solid rgb(0, 147, 163);
                color: rgb(0, 147, 163);
                font-size: small;
                font-weight: 600;
                padding: 0.5rem;
                border-radius: 0.2rem;
                width: 170px;
              "
            >
              COLLECT ON FX HASH
            </button></a
          >
        </div></b-col
      ></b-row
    ><b-row style="margin-top: 3rem"
      ><div
        style="
          background-color: rgb(0, 147, 163);
          color: #ffffff;
          width: 15%;
          padding: 0.5rem;
        "
      >
        General benefits
      </div>
      <hr />
      <b-col>
        <span
          style="font-size: 32px; color: #0093a3; margin-bottom: 0.5rem"
          class="material-symbols-sharp"
        >
          forum
        </span>

        <p style="font-weight: 600; margin-bottom: 0.2rem">
          Access the community
        </p>
        <p style="font-size: small">
          Club members get access to the Club area + Discord chat for members
          only.
        </p></b-col
      ><b-col>
        <span
          style="font-size: 32px; color: #0093a3; margin-bottom: 0.5rem"
          class="material-symbols-sharp"
        >
          how_to_vote
        </span>

        <p style="font-weight: 600; margin-bottom: 0.2rem">
          Art Incubator curation
        </p>
        <p style="font-size: small">
          Club members can be part of the decentralised curation process for the
          Art Incubator.
        </p></b-col
      ><b-col>
        <span
          style="font-size: 32px; color: #0093a3; margin-bottom: 0.5rem"
          class="material-symbols-sharp"
        >
          image
        </span>
        <p style="font-weight: 600; margin-bottom: 0.2rem">
          Pre-sale to Blind Gallery editions
        </p>
        <p style="font-size: small">
          Club members get access to pre-sale and discounts in each Blind
          Gallery edition.
        </p></b-col
      ><b-col>
        <span
          style="font-size: 32px; color: #0093a3; margin-bottom: 0.5rem"
          class="material-symbols-sharp"
        >
          workspace_premium
        </span>
        <p style="font-weight: 600; margin-bottom: 0.2rem">Earn Badges</p>
        <p style="font-size: small">
          Club members can earn badges and obtain future benefits based on their
          contributions and participation.
        </p></b-col
      ></b-row
    >
  </div>
</template>
<script>
import { Pagination, EffectFade } from "swiper";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";

import "swiper/swiper-bundle.css";
SwiperCore.use([Pagination]);

export default {
  name: "PxClubBenefits",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    benefits: {
      type: Object,
    },
  },
  setup() {
    return {
      modules: [Pagination, EffectFade],
    };
  },
};
</script>
<style></style>
