<template>
  <div>
    <div class="row mint-pass" id="mint-pass">
      <div class="col-sm-12 col-lg-6 info-mint">
        <h1 style="padding-bottom: 20px"><strong>Error</strong></h1>
      </div>

      <div class="col">
        <router-link to="/">Return to home page</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ViewError",
};
</script>

<style>
.mint-pass {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding-top: 90px;
}
</style>
