<!-- eslint-disable vue/no-parsing-error -->
<template>
  <main>
    <!--INCUBATOR VOTING BANNER-->
    <div v-if="false" style="position: relative">
      <a href="#reserve-collection"
        ><div v-show="banner === true" ref="feedback" class="banner">
          <p class="m-0 small">
            <span style="font-weight: 600"
              >Artists have been revealed! You can claim your artwork now</span
            >
            <Icon
              icon="material-symbols:keyboard-arrow-down"
              width="24"
              height="24"
            />
          </p>
        </div>
      </a>
      <div
        v-show="banner === true"
        style="
          cursor: pointer;
          color: white;
          position: absolute;
          right: 0;
          top: 0;
          padding: 0.5rem;
        "
      >
        <b-icon icon="x-lg" @click="banner = false" />
      </div>
    </div>

    <b-container fluid>
      <!--HEADER-->
      <header class="sticky-top">
        <!--NAVIGATOR-->
        <div class="mx-4">
          <b-navbar toggleable="lg">
            <!--LOGO-->
            <b-navbar-brand to="/"
              ><img
                src="https://moccasin-perfect-trout-941.mypinata.cloud/ipfs/QmUuXX1TqqZq6i3tcNvUckmSnsk8YNWYE2boysoySaCyMb"
                class="d-inline-block align-top"
                alt="Logo"
                width="120px"
            /></b-navbar-brand>
            <!--TOGGLE ICONS-->
            <b-navbar-toggle style="box-shadow: none" target="nav-collapse">
              <template #default="{ expanded }">
                <Icon
                  v-if="expanded"
                  icon="material-symbols:close"
                  width="32"
                  height="32"
                />
                <Icon
                  v-else
                  icon="material-symbols:menu"
                  width="32"
                  height="32"
                /> </template
            ></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav>
                <b-nav-item to="/art">Art</b-nav-item>
                <b-nav-item to="/club">Club</b-nav-item>
                <b-nav-item to="/incubator">Incubator</b-nav-item>
                <b-nav-item to="/FAQ">FAQ</b-nav-item>
                <b-nav-item
                  href="https://blindgallery.substack.com/"
                  target="_blank"
                  >Blog</b-nav-item
                >
              </b-navbar-nav>

              <!-- Right aligned nav items -->

              <b-navbar-nav
                ><button
                  v-show="!store.wallet | !store.user_address"
                  @click="store.createWallet()"
                  style="gap: 0.5rem"
                  class="primary-button small"
                >
                  Sync wallet
                </button>
                <button
                  class="tertiary-button small"
                  v-show="store.wallet && store.user_address"
                  @click="store.removeWallet()"
                >
                  Unsync wallet
                </button>
              </b-navbar-nav>
            </b-collapse>
          </b-navbar>
        </div>
      </header>

      <!-- This renders the views -->
      <router-view />

      <b-container>
        <b-row
          v-if="!$route.path.includes('club') && !$route.path.includes('art')"
          style="margin-top: 10rem"
        >
          <b-col cols="12" lg="6">
            <div class="container" style="position: relative">
              <iframe
                src="https://blindgallery.substack.com/embed"
                height="150"
                width="100%"
                frameborder="0"
                scrolling="no"
                class="substack-style"
              ></iframe>
            </div> </b-col
        ></b-row>
        <!-- FOOTER -->

        <footer
          v-if="!$route.path.includes('club') && !$route.path.includes('art')"
        >
          <hr class="w-100" />
          <div
            class="d-flex align-items-center justify-content-between small flex-wrap my-3"
          >
            <div>
              <div>
                <router-link to="/">
                  <img
                    src="https://moccasin-perfect-trout-941.mypinata.cloud/ipfs/QmdwJES22fzFsst88Tp9ASfLvGCpRxLWdjzF2HjVgug94V"
                    width="100px"
                  />
                </router-link>

                <a href="https://twitter.com/BlindGallery_" target="_blank">
                  <Icon
                    style="
                      padding: 0.2rem;
                      background-color: #000000;
                      border-radius: 50px;
                      margin: 0rem 0.5rem;
                    "
                    icon="ri:twitter-x-line"
                    color="#fff"
                    width="24"
                  />
                </a>

                <a href="https://discord.com/invite/JT4DBS95c7" target="_blank">
                  <Icon
                    style="
                      padding: 0.2rem;
                      background-color: #000000;
                      border-radius: 50px;
                    "
                    color="#fff"
                    icon="ic:baseline-discord"
                    width="24"
                  />
                </a>
              </div>

              <p class="small my-3 text-secondary partners">
                Copyright ⓒ 2023 • Blind Gallery
              </p>
            </div>

            <div>
              <div class="d-flex align-items-center" style="gap: 2rem">
                <div>
                  <a href="https://app.joyn.xyz/" target="_blank">
                    <img
                      style="width: 100px"
                      src="https://app.joyn.xyz/_next/static/media/logo-dark.1d2f9f52.svg"
                  /></a>
                </div>
                <div>
                  <a
                    href="https://autonomy.io/"
                    target="_blank"
                    style="margin-right: 0.5rem"
                  >
                    <img
                      style="width: 30px"
                      src="https://is4-ssl.mzstatic.com/image/thumb/Purple122/v4/10/33/af/1033afc4-c8f8-1f4c-90d1-4333c20182cb/AppIcon-1x_U007emarketing-0-10-0-85-220.png/230x0w.webp"
                    />
                  </a>
                  <a
                    style="
                      color: black;
                      font-weight: bold;
                      text-decoration: none;
                    "
                    href="https://autonomy.io/"
                    target="_blank"
                    >Autonomy</a
                  >
                </div>
              </div>

              <p class="my-3 small text-secondary partners">
                Official Partners
              </p>
            </div>

            <div>
              <div class="d-flex align-items-center" style="gap: 1rem">
                <a href="https://www.fxhash.xyz/" target="_blank">
                  <img
                    style="width: 100px"
                    src="https://fxhash.notion.site/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F84947e48-fba3-4cbb-b203-aaad507b315b%2Ffxhash_tbg_bt_01.png?table=block&id=b34cff0e-7784-40ec-9020-cea678fe9d41&spaceId=f9d1e984-50ed-4650-a543-95d2b6c0ba90&width=1860&userId=&cache=v2"
                /></a>
                <div>
                  <a href="https://tezos.com/" target="_blank">
                    <img
                      style="width: 70px"
                      src="https://imgs.search.brave.com/Ni4B87Cq07txaSZWBtEEf8YjF5llOTY-L8WPXow5tZs/rs:fit:1200:778:1/g:ce/aHR0cHM6Ly9icmFu/ZHBhbGV0dGVzLmNv/bS93cC1jb250ZW50/L3VwbG9hZHMvMjAy/MS8wNS90ZXpvcy0w/Mi5wbmc"
                    />
                  </a>
                </div>
              </div>

              <p class="small my-3 text-secondary partners">Powered by</p>
            </div>
          </div>
        </footer></b-container
      >
    </b-container>
  </main>
</template>

<script>
import store from "@/store/index";
import { Icon } from "@iconify/vue2";

export default {
  name: "App",
  components: {
    Icon,
  },
  data() {
    return {
      banner: true,
      store,
    };
  },

  methods: {
    toClubView() {
      if (!store.wallet || !store.user_address) {
        store.createWallet().then(() => {
          this.$router.push("/club");
          this.banner = false;
        });
      } else {
        this.$router.push("/club");
        this.banner = false;
      }
    },
  },
  created() {
    store.autoLogin();
    store.getContracts();
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap");

body {
  --bs-body-font-family: "Poppins", sans-serif;
}

#app {
  font-family: Poppins, Made SUNFLOWER, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

header {
  width: 100%;
  z-index: 1;
  background-color: #ffffff;
}

.substack-style {
  padding: 0;
  margin-top: -10rem;
  left: 19rem;
  position: absolute;
}

.partners {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner {
  background-color: #0093a3;
  color: #fff;
  padding: 0.5rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
}

.banner:hover {
  background: #007c8a;
}

/* BUTTONS */
.primary-button {
  background: #0093a3;
  border: none;
  color: white;
  padding: 0.5rem 1.5rem;
  width: auto;
  transition: all 0.3s;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 5px;
}

.primary-button:hover {
  background: #007c8a;
}
.secondary-button {
  padding: 0px;
  gap: 6px;
  color: #000;
  border: none;
  background: none;
  transition: all 0.3s;
}

.secondary-button:hover {
  text-decoration: underline;
}

.tertiary-button {
  border: 1px solid #0093a3;
  background-color: transparent;
  color: #0093a3;
  font-weight: 600;
  border-radius: 5px;
  padding: 0.5rem 1.5rem;
  width: auto;
}

@media (max-width: 1024px) {
  .substack-style {
    left: -5rem;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
    justify-content: flex-end;
  }
}

@media (max-width: 768px) {
  .substack-style {
    left: 0rem;
  }

  .partners {
    display: block;
    flex-direction: initial;
  }
}

@media (max-width: 425px) {
  .banner p {
    max-width: 270px;
  }
} ;
</style>
