<template>
  <div>
    <b-container
      ><b-row
        ><b-col cols="12" lg="8">
          <h1
            style="
              font-weight: 900;
              margin: 4rem 0rem 4rem 0rem;
              font-size: 3em;
            "
          >
            Frequently <span style="color: rgb(0, 147, 163)">asked</span>
            questions
          </h1>
          <h5>What is the Blind Gallery?</h5>
          <p>
            The Blind Gallery aims to put art first by showcasing artworks from
            mysterious artists (artists are revealed after the event).
          </p>
          <hr />

          <h5>What is the Blind Gallery Club?</h5>
          <p>
            The Blind Gallery Club is an art community that focuses on digital
            and generative art. Besides accessing the community, members get
            early access and discounts to the Blind Gallery events and special
            ways to experience the blind concept.
          </p>
          <hr />
          <h5>Blind Gallery Club details:</h5>
          <p>
            <a
              href="https://open.substack.com/pub/kaloh/p/blind-gallery-club"
              target="_blank"
              >Announcement</a
            ><br />
            <a href="https://www.blindgallery.xyz/club" target="_blank"
              >Club Area & Benefits</a
            ><br />
            <a
              href="https://www.fxhash.xyz/marketplace/generative/22532"
              target="_blank"
              >Join the Club</a
            >
          </p>
          <hr />
          <h5>What is the Blind Gallery cadence?</h5>
          <p>
            The Blind Gallery Editions are a themed event that happens every 4
            to 6 months, featuring multiple artists and collections.
            <br /><br />
            The Blind Gallery Limited Collections are released on a recurrent
            basis, featuring one artist and one collection only.
          </p>
          <hr />
          <h5>Where can I see the previous Blind Gallery pieces & artists?</h5>
          <p>
            You can find the first two Blind Gallery editions
            <a href="https://www.blindgallery.xyz/#editions" target="_blank"
              >here</a
            >
          </p>
          <hr />
          <h5>What is the Art Incubator?</h5>
          <p>
            The Art Incubator is a place for artists pushing the boundaries
            between technology and art to experiment and release artworks using
            the blind concept.<br /><br />
            All digital artists are welcome to apply. Club members will vote to
            decide who joins the incubator.
          </p>
          <hr />
          <h5>What are the Badges?</h5>
          <p>
            The Badges are soul bound (non transferable) tokens that show your
            Blind Gallery collecting and creating journey. <br /><br />
            You can earn Badges by participating in Blind Gallery events.
          </p>
          <hr />

          <h5>Who created the Blind Gallery?</h5>
          <p>
            Kaloh is the founder - Check his newsletter at
            <a href="https://www.kaloh.xyz/" target="_blank"> www.kaloh.xyz</a>
            , but we are now a team of 4 developers, designers and art
            enthusiasts.
          </p>
          <hr />
          <h5>Why are you doing this on Tezos?</h5>
          <p>
            Tezos is the home of outstanding digital art and a vibrant
            community.
          </p>
          <hr />
          <h5>Where can I purchase Tezos?</h5>
          <p>
            You can buy Tezos in your favorite crypto exchange like
            <a href="https://www.coinbase.com/" target="_blank">coinbase.com</a>
          </p>
          <hr />
          <h5>How do I find a Tezos wallet?</h5>
          <p>
            We recommend
            <a href="https://autonomy.io/" target="_blank">Autonomy</a> wallet
            (Official Partner),<a
              href=" https://templewallet.com/"
              target="_blank"
            >
              Temple</a
            >
            or <a href="https://wallet.kukai.app/" target="_blank"> Kukai</a>
          </p>
        </b-col></b-row
      ></b-container
    >
  </div>
</template>
<script>
export default {
  name: "ViewFAQ",
};
</script>
<style>
h5 {
  font-weight: 600;
}

a {
  text-decoration: none;
}
</style>
