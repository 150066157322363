import { TZKT_API_ENDPOINT, TZKT_API_GHOST, better_call_dev_chain_names } from '@/constants'

const get_contract_storage = async (contract, chain = better_call_dev_chain_names.mainnet) => {

    switch (chain) {
      case better_call_dev_chain_names.mainnet:
        return fetch(`${TZKT_API_ENDPOINT}/v1/contracts/${contract}/storage`)
          .then(res => res.json())
      case better_call_dev_chain_names.ghostnet:
        return fetch(`${TZKT_API_GHOST}/v1/contracts/${contract}/storage`)
          .then(res => res.json())
      default:
        console.log(`${chain} invalid chain name`);
    }
}

export default get_contract_storage;
