import { reactive } from 'vue'
import { BeaconWallet } from "@taquito/beacon-wallet";
import { Tezos, get_contract } from "@/tezos/wallet";

import { ENDPOINT, DEFAULT_MATRIX_NODE, CHAIN_NAME, CONTRACTS, FxHashContractAddress, ObjktContractAddress, FxHashContractFa2 } from "@/constants";
import get_contract_storage from '@/api/get_contract_storage';


const options = {
  name: 'Blind Gallery',
  matrixNodes: [DEFAULT_MATRIX_NODE],
  preferredNetwork: CHAIN_NAME,
  featuredWallets: ['autonomy', 'kukai', 'temple', 'naan'],
  disableDefaultEvents: false
}

const store = reactive({
  user_address: '',
  fxhashExchanges: {
    'ciudad-central': 0,
    'fraktur': 0,
    'brutal-infection': 0,
    'the-dance-of-the-machines': 0
  },
  fxhashSwaps: {
    'ciudad-central': 0,
    'fraktur': 0,
    'brutal-infection': 0,
    'the-dance-of-the-machines': 0
  },
  blind_gallery_storage: new Object(),
  contracts: new Object(),
  wallet: new BeaconWallet(options),
  async createWallet() {
    await this.autoLogin();

    Tezos.setWalletProvider(this.wallet);
    await this.wallet.requestPermissions({
      network: {
        type: CHAIN_NAME,
        rpcUrl: ENDPOINT,
      },
    })
    this.user_address = await this.wallet.getPKH()

  },
  async autoLogin() {
    const active_account = await this.wallet.client.getActiveAccount();
    if (active_account) {
      this.wallet.client.setActiveAccount(active_account);
      this.user_address = active_account.address;
      Tezos.setWalletProvider(this.wallet);

      return;
    }
  },
  async removeWallet() {
    if (this.wallet) {
      this.wallet.client.clearActiveAccount();
      this.wallet.client.removeAllAccounts();
      this.wallet.client.removeAllPeers();
      this.wallet.client.destroy();
      this.wallet = undefined;
      this.user_address = undefined;

      this.wallet = new BeaconWallet(options)
    }
  },
  async updateStorage() {
    get_contract_storage(CONTRACTS.third_collection.address).then((contract) => {
      this.blind_gallery_storage = contract;
    })
    const contract = await Tezos.wallet.at(CONTRACTS.third_collection.address)
    const storage = await contract.storage()

    this.fxhashSwaps.total = 0

    for (const collection of CONTRACTS.third_collection.collections) {
      this.fxhashExchanges[collection.sku] = 0
      if (this.user_address) {
        const exchanges = storage.FXExchanges.get({ 0: this.user_address, 1: collection.sku })
        if (exchanges) {
          this.fxhashExchanges[collection.sku] = exchanges.c[0]
        }
      }

      // update the global swaps from each collection
      this.fxhashSwaps[collection.sku] = 0
      const swaps = storage.FXcollections.get(collection.sku)
      if (swaps) {
        this.fxhashSwaps[collection.sku] = swaps.c[0]
        this.fxhashSwaps.total += swaps.c[0]
      }

    }


  },
  async getContracts() {
    get_contract(ObjktContractAddress).then((contract) => {
      this.contracts['objkt'] = contract;
    })

    get_contract(FxHashContractAddress).then((contract) => {
      this.contracts['fxhash'] = contract;
    })

    get_contract(CONTRACTS.sales.address).then((contract) => {
      this.contracts['bg'] = contract;
    })

    get_contract(CONTRACTS.first_collection.fa2).then((contract) => {
      this.contracts[CONTRACTS.first_collection.fa2] = contract;
    })

    get_contract(CONTRACTS.second_collection.fa2).then((contract) => {
      this.contracts[CONTRACTS.second_collection.fa2] = contract;
    })

    get_contract(FxHashContractFa2).then((contract) => {
      this.contracts['fxhashFA2'] = contract;
    })

    get_contract(CONTRACTS.voting.address).then((contract) => {
      this.contracts['voting'] = contract;
    })

    get_contract_storage(CONTRACTS.third_collection.address).then((storage) => {
      this.blind_gallery_storage = storage;
    })

    get_contract_storage(CONTRACTS.voting.address).then((storage) => {
      this.voting_storage = storage;
    })

  }
})

export default store
