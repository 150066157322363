import { BLIND_GALLERY_API } from '@/constants'

const getPendingBadges = async (wallet) => {

  let url = `https://${BLIND_GALLERY_API}/v1/get/pending?wallet=${wallet}`;

  const data = await fetch(url)
  const badges = await data.json()
  return badges
}

export default getPendingBadges;
