import { gql, request } from 'graphql-request';
import { FXHASH_API } from '@/constants';

const query = gql`
  query GenerativeToken($generativeTokenId: Float) {
    generativeToken(id: $generativeTokenId) {
      id
      name
      supply
      balance
      originalSupply
      objktsCount
      reserves {
        data
      }
    }
  }
`;

const get_fxhash_collection_info = async (collection_id) => {
  const response = await request(FXHASH_API, query, { generativeTokenId: collection_id });
  return response.generativeToken;
};

export default get_fxhash_collection_info;
