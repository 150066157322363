<template>
  <div>
    <p style="margin: 0">
      <b v-if="timeRemaining > 0">
        {{ days }}d {{ hours }}h {{ minutes }}m {{ seconds }}s
      </b>
      <b v-else-if="activeAuction === false">
        {{ nextCountdown.days }}d {{ nextCountdown.hours }}h
        {{ nextCountdown.minutes }}m {{ nextCountdown.seconds }}s
      </b>
    </p>
    <p style="font-size: small; margin: 0" v-if="timeRemaining > 0">
      Remaining time to start the pre sale
    </p>
    <p style="font-size: small; margin: 0" v-else-if="activeAuction === false">
      Remaining time to public sale
    </p>
    <div v-if="activeAuction === true">
      <p style="margin: 0">
        <b>{{ timer }}</b>
      </p>
      <p style="font-size: small; margin: 0">Dutch auction in progress</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PxCountdown",
  props: {
    activeAuction: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      startDate: new Date(),
      endDate: new Date("2023-06-12T16:00:00Z"),
      nextCountdown: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      timeRemaining: 0,
      timer: '',
    };
  },
  mounted() {
    this.startTimer();
  },
  created() {
    setInterval(() => {
      const now = new Date().getTime();
      const distance = this.endDate - now;

      if (distance > 0) {
        this.timeRemaining = distance / 1000;
      } else {
        this.timeRemaining = 0;
        this.startNextCountdown();
      }
    }, 1000);
  },

  methods: {
    startTimer() {
      const currentTime = new Date();
      const currentMinutes = currentTime.getMinutes();
      const nextTenMinutes = Math.ceil(currentMinutes / 10) * 10;
      const targetTime = new Date();
      targetTime.setMinutes(nextTenMinutes, 0, 0);

      // this.updateTimer(targetTime);

      setInterval(() => {
        const currentTime = new Date();
        const currentMinutes = currentTime.getMinutes();
        const nextTenMinutes = Math.ceil(currentMinutes / 10) * 10;
        const targetTime = new Date();
        targetTime.setMinutes(nextTenMinutes, 0, 0);
        this.updateTimer(targetTime);
      }, 1000);
    },
    updateTimer(targetTime) {
      const currentTime = new Date();
      const remainingTime = targetTime - currentTime;
      console.log(remainingTime)
      if (remainingTime <= 900) {
        const minutesLeft = 9 - (currentTime.getMinutes() % 10);
        const secondsLeft = 60 - currentTime.getSeconds();
        this.timer = `${minutesLeft.toString().padStart(2, '0')}:${secondsLeft.toString().padStart(2, '0')}`;
      } else {
        const minutes = Math.floor(remainingTime / (60 * 1000));
        const seconds = Math.floor((remainingTime % (60 * 1000)) / 1000);
        this.timer = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      }
    },
    formatTime(time) {
      const minutes = Math.floor(time / 60);
      const seconds = time % 60;
      return `${minutes}m ${seconds < 10 ? "0" : ""}${seconds}s`;
    },
    startNextCountdown() {
      const nextEndDate = new Date("2023-06-13T16:00:00Z");
      const now = new Date().getTime();
      const distance = nextEndDate - now;

      this.nextCountdown = {
        days: Math.floor(distance / 86400000),
        hours: Math.floor((distance % 86400000) / 3600000),
        minutes: Math.floor((distance % 3600000) / 60000),
        seconds: Math.floor((distance % 60000) / 1000),
      };

      setInterval(() => {
        const now = new Date().getTime();
        const distance = nextEndDate - now;

        this.nextCountdown = {
          days: Math.floor(distance / 86400000),
          hours: Math.floor((distance % 86400000) / 3600000),
          minutes: Math.floor((distance % 3600000) / 60000),
          seconds: Math.floor((distance % 60000) / 1000),
        };
      }, 1000);

      if (nextEndDate <= now) {
        // this.activeAuction = true;
        this.contador = this.contador > 0 ? this.contador - 1 : 61;
      }
    },
  },
  computed: {
    days() {
      return Math.floor(this.timeRemaining / 86400);
    },
    hours() {
      return Math.floor((this.timeRemaining % 86400) / 3600);
    },
    minutes() {
      return Math.floor((this.timeRemaining % 3600) / 60);
    },
    seconds() {
      return Math.floor(this.timeRemaining % 60);
    },
  },
};
</script>
<style></style>
