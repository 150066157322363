import { Tezos } from "@/tezos/wallet";
import { OpKind } from "@taquito/taquito";
import store from "@/store/index";
import { DEFAULT_CONFIRMATION_BLOCKS } from "@/constants.js";


export const mint = async (_referrer, _reserve_input, issuer_id, c = DEFAULT_CONFIRMATION_BLOCKS) => {
  if (!store.contracts.fxhash) {
    await store.getContracts();
  }
  const batch_operation = await Tezos.wallet
    .batch([
      {
        kind: OpKind.TRANSACTION,
        ...store.contracts.fxhash.methods
          .mint(issuer_id)
          .toTransferParams(),
      }
    ])
    .send()

  const confirmation = await batch_operation.confirmation(c);
  return confirmation
}

// {
//   "branch": "BLqn3Y6fDv9y3YvTQC3qXge5bZDwZkf1tMvqxs8BvQ9ctnkk8fb",
//   "contents": [
//     {
//       "kind": "transaction",
//       "source": "tz1ZLedXnXnPbk43LD1sHHG3NMXG7ZveZ1jr",
//       "fee": "3009",
//       "counter": "26989796",
//       "gas_limit": "26990",
//       "storage_limit": "650",
//       "amount": "0",
//       "destination": "KT1BJC12dG17CVvPKJ1VYaNnaT5mzfnUTwXv",
//       "parameters": {
//         "entrypoint": "mint",
//         "value": {
//           "prim": "Pair",
//           "args": [
//             {
//               "int": "14770"
//             },
//             {
//               "prim": "Pair",
//               "args": [
//                 {
//                   "prim": "None"
//                 },
//                 {
//                   "prim": "None"
//                 }
//               ]
//             }
//           ]
//         }
//       }
//     }
//   ]
// }



// {
//   "branch": "BLg72rDTg6HWMsh2p5DKSQktW9ExCU8J2Jd4W7FmMXS4XtngVPN",
//   "contents": [
//     {
//       "kind": "transaction",
//       "source": "tz1ZLedXnXnPbk43LD1sHHG3NMXG7ZveZ1jr",
//       "fee": "632",
//       "counter": "26989796",
//       "gas_limit": "2489",
//       "storage_limit": "269",
//       "amount": "0",
//       "destination": "KT1Ezht4PDKZri7aVppVGT4Jkw39sesaFnww",
//       "parameters": {
//         "entrypoint": "update_profile",
//         "value": {
//           "prim": "Pair",
//           "args": [
//             {
//               "bytes": "697066733a2f2f516d57415677616d4e595976614a5a79766363534c326d7154724b4b54537876474b4e7a69676e58746e334b6f31"
//             },
//             {
//               "bytes": "546c616c6f636d616e"
//             }
//           ]
//         }
//       }
//     }
//   ]
// }
