//! create your queries using the GraphQL Playground
import { gql, request } from 'graphql-request';
import { OBKJT_API } from '@/constants';

const get_objkt_balance = async (pkh, fa2, token_id) => {
  const query = gql`
    {
      token_holder(where: {holder_address: {_eq: "${pkh}"}, token: {fa_contract: {_eq: "${fa2}"}, token_id: {_eq: "${token_id}"}}}) {
        quantity
        token {
          token_id
        }
      }
    }
  `;
  const response = await request(OBKJT_API, query);
  const balance = response.token_holder.length ? response.token_holder[0].quantity : 0;

  return balance;
};

export default get_objkt_balance;
