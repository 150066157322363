<template>
  <div>
    <PxAlerts ref="alerts" />
    <b-row class="mb-4">
      <h1 style="font-weight: 600; color: #0093a3">Your badges</h1>
      <p class="text-secondary">
        You can earn Badges by participating in Blind Gallery events.
      </p>
      <a
        class="secondary-button"
        href="https://blindgallery.substack.com/p/clubarea"
        target="_blank"
        style="width: auto"
        >Learn more <b-icon icon="chevron-right" font-scale="0."></b-icon
      ></a>
    </b-row>
    <b-row>
      <b-col
        v-for="(badgeFront, index) in badgesFront"
        :key="index"
        sm="12"
        md="6"
        lg="4"
      >
        <div
          class="shadow-sm rounded p-3 d-flex align-items-center mb-3"
          style="gap: 1rem"
        >
          <div>
            <img
              v-bw="!badges.includes(badgeFront.title)"
              width="120px"
              :src="badgeFront.img"
            />
          </div>
          <div>
            <p class="m-0" style="font-weight: 600; color: #0093a3">
              {{ badgeFront.title }}
            </p>
            <p class="w-100 small" v-b-toggle="`accordion-${index}`">
              See more
            </p>
            <b-collapse class="mb-2" :id="`accordion-${index}`">
              <p style="font-size: 12px" class="text-secondary m-0">
                {{ badgeFront.description }}
              </p>
            </b-collapse>
            <button
              @click.once="claimBadge(badgeFront.id)"
              v-if="badgeFront.isPending === true"
              class="primary-button small"
            >
              Claim
            </button>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import PxAlerts from "@/components/PxAlerts.vue";

import store from "@/store";
import claimPendingBadges from "@/api/post_claim_badge";

export default {
  name: "PxClubBadges",
  components: {
    PxAlerts,
  },

  data() {
    return {
      store,
      buttonPressed: false,
      badgesFront: [
        {
          img: "https://ipfs.io/ipfs/QmVggqU5rxv2VQehgNEDUiU27GmVjxR8247by4mcJgUieS",
          title: "Club Member 2022",
          description:
            "This badge was given to all Blind Gallery Club Members. The snapshot was taken on the 31st of January.",
          id: "",
        },
        {
          img: "https://ipfs.io/ipfs/QmNqKWuG5yWGC4tPhP6ifSXQdThfhSVxqwTVKC6td8QJeA",
          title: "1st Edition Collector",
          description:
            "This badge was given to all the collectors that exchanged a Mint Pass in the Blind Gallery 1st Edition.",
          id: "first_collector",
          isPending: this.pendingBadges.first_collector,
        },
        {
          img: "https://ipfs.io/ipfs/QmSey5Gqb3iGARzLd4aG4h78eVrLZSFu5XC7TW3ZJ16JAL",
          title: "1st Edition Artist",
          description:
            "This badge was given to all the artists that participated in the Blind Gallery 1st Edition.",
          id: "first_artist",
          isPending: this.pendingBadges.first_artist,
        },
        {
          img: "https://ipfs.io/ipfs/QmPRA11erDL9hQA9pdUJM1FxnGaXuDPLQ9TcGNefuQBPJz",
          title: "Bear Artist",
          description:
            "This badge was given to all the artists that participated in the Blind Gallery Bear Market Edition.",
          id: "bear_artist",
          isPending: this.pendingBadges.bear_artist,
        },
        {
          img: "https://ipfs.io/ipfs/QmPsWFsxmncSbjKfhfjnoMTH2Z7MRZaywSs9qsupVmNVNB",
          title: "Bear Collector",
          description:
            "This badge was given to all the collectors that exchanged a Mint Pass in the Blind Gallery Bear Market Edition",
          id: "bear_collector",
          isPending: this.pendingBadges.bear_collector,
        },
        {
          img: "https://ipfs.io/ipfs/QmXvuyUvFw9UkHnfFqYGovK1zqRzsJ5y9FUthiPGFswyLt",
          title: "Seed Collector",
          description:
            "This badge was given to all the collectors that exchanged a Mint Pass in the Blind Gallery Seed Edition",
          id: "seeds_collector",
          isPending: this.pendingBadges.seeds_collector,
        },
        {
          img: "https://ipfs.io/ipfs/Qmb6qsyYDY1CfEoZoZefAESKu2fb7hiARfwK9c393UKtgM",
          title: "Seed Artist",
          description:
            "This badge was given to all the artists that participated in the Blind Gallery Seed Edition.",
          id: "seeds_artist",
          isPending: this.pendingBadges.seeds_artist,
        },
        {
          img: "https://ipfs.io/ipfs/QmUbzNqpSybwpRkSVuQLM3MpSYXgQSjHVmQXFSiyPNpjkM",
          title: "Collectors Chat",
          description:
            "This badge was given to all the people that participated in the Blind Gallery Collectors Space.",
          id: "collectors_chat",
          isPending: this.pendingBadges.collectors_chat,
        },
        {
          img: "https://ipfs.io/ipfs/QmfCkqcdm7UQAZB3sBWhkmSmUDqzDLuHXwpybcg2nUZ3ic",
          title: "Blind Curator",
          description:
            "This badge was given to all the people who voted in the first round of voting for the Blind Gallery Club.",
          id: "blind_curator",
          isPending: this.pendingBadges.blind_curator,
        },
        {
          img: "https://ipfs.io/ipfs/Qmc5fJkC4We2oyzXyTdKSMoZeWSFAFjd1LDy3JzTR7k7jo",
          title: "Surreality Collector",
          description:
            "This Badge is available to all Surreality Collectors (minters).",
          id: "surreality_collector",
          isPending: this.pendingBadges.surreality_collector,
        },
        {
          img: "https://ipfs.io/ipfs/Qmf3LJwM57g28Js5sGQ4V9Ed2vhYEuGez7Cdjuyj3UhpKe",
          title: "Frequency of Affection Collector",
          description:
            "This Badge is available to all Frequency of Affection Collectors (minters).",
          id: "frequency_of_affection_collector",
          isPending: this.pendingBadges.frequency_of_affection_collector,
        },
        {
          img: "https://ipfs.io/ipfs/QmSBYdHMi565aUCmgft52TyX2qhRboVPtukhLeJVrQS61g",
          title: "Humans Being Collector",
          description:
            "This Badge is available to all Frequency of Affection Collectors (minters).",
          id: "humans_being_collector",
          isPending: this.pendingBadges.humans_being_collector,
        },
        {
          img: "https://ipfs.io/ipfs/QmX6AfoB1zW6hUgFh2n8XDkq7ySDKAgHhBq5vtngUwL4Vf",
          title: "Vistas Edition Artist",
          description:
            "This badge was given to all the artists that participated in the Blind Gallery Vistas Edition.",
          id: "vistas_edition_artist",
          isPending: this.pendingBadges.vistas_edition_artist,
        },
        {
          img: "https://ipfs.io/ipfs/QmQTsWdWevSykn3r6cnchzVyCanFrx4U5fYmgK4hbxMycj",
          title: "Vistas Edition Collector",
          description:
            "This badge was given to all the collectors that exchanged a Mint Pass in the Blind Gallery Vistas Edition",
          id: "vistas_edition_collector",
          isPending: this.pendingBadges.vistas_edition_collector,
        },
      ],
    };
  },

  directives: {
    bw: {
      bind(el, binding) {
        if (binding.value) {
          el.style.filter = "grayscale(100%)";
        } else {
          el.style.filter = "none";
        }
      },
      update(el, binding) {
        if (binding.value) {
          el.style.filter = "grayscale(100%)";
        } else {
          el.style.filter = "none";
        }
      },
    },
  },
  props: {
    badges: {
      type: Array,
      default: () => [],
    },
    pendingBadges: {
      type: Object,
    },
  },

  watch: {
    pendingBadges: {
      deep: true,
      handler(newVal) {
        this.badgesFront.forEach((badge) => {
          badge.isPending = newVal[badge.id];
        });
      },
    },
  },

  methods: {
    async claimBadge(badge) {
      if (!store.user_address) {
        return;
      }
      if (this.buttonPressed) {
        return;
      }
      this.buttonPressed = true;
      this.$refs.alerts.showInfoAlert("Claiming badge, please wait...");
      const data = await claimPendingBadges(store.user_address, badge);

      if (data.error) {
        this.$refs.alerts.showErrorAlert(data.error);
        this.buttonPressed = false;
        return;
      }

      this.$refs.alerts.showSuccessAlert(data.message);

      this.$emit("update-badge", badge);
      this.buttonPressed = false;
    },
  },
};
</script>
<style></style>
