var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-nav',{staticClass:"bottom-menu",staticStyle:{"justify-content":"space-around"},attrs:{"horizontal":""}},[_c('b-nav-item',{on:{"click":function($event){_vm.showRow = true}}},[_c('span',{class:_vm.showRow === true
            ? 'material-symbols-sharp'
            : 'material-symbols-outlined',staticStyle:{"font-size":"26px"}},[_vm._v(" image ")]),_c('span',{staticStyle:{"font-size":"small"}},[_vm._v("Editions")])]),_c('b-nav-item',{on:{"click":function($event){_vm.showRow = false}}},[_c('span',{class:_vm.showRow === false
            ? 'material-symbols-sharp'
            : 'material-symbols-outlined',staticStyle:{"font-size":"26px"}},[_vm._v(" filter_none ")]),_c('span',{staticStyle:{"font-size":"small"}},[_vm._v("Collections")])])],1),_c('b-row',[_c('b-col',{staticClass:"side-nav-container",attrs:{"lg":"1"}},[_c('b-nav',{staticClass:"side-nav",attrs:{"vertical":""}},[_c('b-nav-item',{on:{"click":function($event){_vm.showRow = true}}},[_c('span',{class:_vm.showRow === true
                ? 'material-symbols-sharp'
                : 'material-symbols-outlined',staticStyle:{"font-size":"26px"}},[_vm._v(" image ")]),_c('span',{staticStyle:{"font-size":"small"}},[_vm._v("Editions")])]),_c('b-nav-item',{on:{"click":function($event){_vm.showRow = false}}},[_c('span',{class:_vm.showRow === false
                ? 'material-symbols-sharp'
                : 'material-symbols-outlined',staticStyle:{"font-size":"26px"}},[_vm._v(" filter_none ")]),_c('span',{staticStyle:{"font-size":"small"}},[_vm._v("Collections")])])],1)],1),_c('b-col',{staticClass:"art-container",attrs:{"cols":"12","lg":"11"}},[(_vm.showRow === true)?_c('b-row',_vm._l((_vm.editions),function(edition){return _c('b-col',{key:edition.id,attrs:{"md":"6","lg":"4"}},[_c('b-card',{staticClass:"mb-4 shadow-sm",staticStyle:{"max-width":"37rem","border":"none"},attrs:{"img-top":""}},[_c('div',[_c('b-img',{staticStyle:{"height":"350px","width":"100%","object-fit":"cover"},attrs:{"src":edition.cover,"alt":"Preview"}}),_c('h5',{staticClass:"pt-4"},[_vm._v(_vm._s(edition.title))]),_c('b-card-text',{staticClass:"mb-4"},[_vm._v(" "+_vm._s(edition.description)+" ")])],1),_c('div',{staticClass:"editions-buttons"},[_c('router-link',{attrs:{"to":edition.route}},[_c('button',{staticClass:"primary-button"},[_vm._v(" View gallery ")])]),_c('a',{staticClass:"secondary-button",attrs:{"href":edition.link,"target":"_blank"}},[_vm._v("View on "+_vm._s(edition.marketplace)+" "),_c('b-icon',{attrs:{"icon":"chevron-right","font-scale":"0."}})],1)],1)])],1)}),1):_c('b-row',_vm._l((_vm.collections),function(collection){return _c('b-col',{key:collection.id,attrs:{"md":"6","lg":"4"}},[_c('b-card',{staticClass:"mb-4 shadow-sm",staticStyle:{"max-width":"37rem","border":"none"},attrs:{"img-top":""}},[_c('div',[_c('b-img',{staticStyle:{"height":"350px","width":"100%","object-fit":"cover"},attrs:{"src":collection.cover,"alt":"Preview"}}),_c('h5',{staticClass:"pt-4"},[_vm._v(_vm._s(collection.title))]),_c('b-card-text',{staticClass:"mb-4"},[_vm._v(" "+_vm._s(collection.description)+" ")])],1),_c('a',{staticClass:"secondary-button",attrs:{"href":collection.link,"target":"_blank"}},[_vm._v("Learn more "),_c('b-icon',{attrs:{"icon":"chevron-right","font-scale":"0."}})],1)])],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }