import numeral from "numeral";

const dollarFilter = (value) => {
  if (!value) return '$ 0.00';
  return `$ ${numeral(value).format('0,0.00')}`;
}

const tezosFilter = (value) => {
  if (!value) return 'ꜩ 0.00';
  return `ꜩ ${numeral(value).format('0,0.00')}`;
}


const mutezFilter = (value) => {
  if (!value) return 'ꜩ 0.00';
  return `ꜩ ${numeral(value/1000000).format('0,0')}`;
}

const entryFilter = (value) => {
  if (!value) return '0';
  return value;
}

export {
  dollarFilter,
  tezosFilter,
  mutezFilter,
  entryFilter
}
