import { TezosToolkit, PollingSubscribeProvider } from "@taquito/taquito";
import { ENDPOINT } from "@/constants";

export const Tezos = new TezosToolkit(ENDPOINT)

Tezos.setStreamProvider(
  Tezos.getFactory(PollingSubscribeProvider)({
    shouldObservableSubscriptionRetry: true,
    pollingIntervalMilliseconds: 1500,
  })
)

export function subscribeEvent(tag, address, excludeFailedOperations = true) {
  try {
    const sub = Tezos.stream.subscribeEvent({
      tag,
      address,
      excludeFailedOperations,
    })
    sub.on('data', console.log)

    return sub

  } catch (e) {
    console.log(e)
  }

}


// ACCOUNT BALANCE
export function get_balance(pkh) {
  return Tezos.tz.getBalance(pkh)
    .then(balance => balance.toNumber() / 1000000)
}

export function get_contract(address) {
  return Tezos.wallet.at(address);
}
