<template>
  <div>
    <PxAlerts ref="alerts" />
    <b-row
      ><b-col cols="12" lg="7">
        <template>
          <swiper
            :modules="modules"
            :effect="'fade'"
            class="prev-container"
            :spaceBetween="30"
            :pagination="{
              clickable: true,
            }"
          >
            <swiper-slide :key="preview.id" v-for="preview in previews"
              ><img
                style="padding-bottom: 3rem"
                :src="preview.prev"
                alt="preview"
            /></swiper-slide>
          </swiper>
        </template> </b-col
      ><b-col cols="12" lg="5">
        <div style="display: flex; flex-direction: column; gap: 1rem">
          <h3 style="margin: 0">
            <b
              ><a
                style="color: #0093a3"
                href="https://twitter.com/thisis0xbenj"
                target="_blank"
                >0xbenj</a
              ></b
            >
          </h3>
          <h2><b>Frequency of Affection</b></h2>

          <p class="paragraph-style">
            A generative abstract art collection inspired by the interplay
            between signals and noise in physical and digital systems.
            <br />
            <br />Each piece explores the delicate balance between clarity and
            distortion, resulting in visual elements that oscillate, merge, or
            diverge in interesting ways.
          </p>
          <div class="countdown-container">
            <!-- <a href="https://blindgallery.substack.com/p/frequency-of-affection" target="_blank" class="secondary-button">
              LEARN MORE
              <b-icon icon="chevron-right
" font-scale="0."></b-icon>
            </a> -->
            <button
              class="primary-button"
              @click="
                store.user_address != undefined && store.user_address != ''
                  ? buy()
                  : store.createWallet()
              "
            >
              COLLECT FOR {{ price | mutez }}
            </button>
            <p v-show="false"><b>SOLD OUT</b></p>

            <a
              v-if="false"
              class="secondary-button"
              href="https://www.fxhash.xyz/generative/27617"
              target="_blank"
              >VIEW ON FXHASH
              <b-icon
                icon="chevron-right
"
                font-scale="0."
              ></b-icon>
            </a>
            <PxCountdown />
          </div>
          <div class="specification-container">
            <div class="specification-item">
              <span class="material-symbols-sharp"> schedule </span>
              <p>Pre Sale: 12/06/2023 | Public sale: 13/06/2023</p>
            </div>
            <div class="specification-item">
              <span class="material-symbols-sharp"> circle </span>
              <p>Total editions: 100</p>
            </div>
            <div class="specification-item">
              <span class="material-symbols-sharp"> incomplete_circle </span>
              <p>Available: {{ supply }}</p>
            </div>
            <div class="specification-item">
              <span class="material-symbols-sharp"> category </span>
              <p>Category: Coded Art</p>
            </div>
            <div class="specification-item">
              <span class="material-symbols-sharp"> store </span>
              <p>Secondary Market: fxhash</p>
            </div>
            <div class="specification-item">
              <span class="material-symbols-sharp"> shopping_cart </span>
              <p>
                Pre sale: 30 Tezos <br />
                Public sale (Dutch Auction): 300>250>200>150>100>75>50 Tezos
              </p>
            </div>
            <div class="specification-item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M9 12a2 2 0 1 0 0-4a2 2 0 0 0 0 4Zm3-11l9.5 5.5v11L12 23l-9.5-5.5v-11L12 1ZM4.5 7.653v8.694l2.372 1.373l8.073-5.92l4.555 2.734v-6.88L12 3.31L4.5 7.653Z"
                />
              </svg>
              <p>Collected: {{ collected_pieces }}</p>
            </div>
            <p>
              <b
                >Frequency of Affection pieces will be distributed after the
                public sale in fxhash</b
              >
            </p>
            <hr />

            <p style="font-weight: 600; color: #0093a3">Club benefits</p>
            <p>
              All Blind Gallery Club members get 1 reserve spot (pre sale),
              while Legendary Memberships get 1 airdrop.
            </p>
          </div>
        </div>
      </b-col></b-row
    >
  </div>
</template>
<script>
import PxCountdown from "./PxCountdown.vue";
import PxAlerts from "@/components/PxAlerts.vue";

import store from "@/store";

import get_contract_storage from "@/api/get_contract_storage";
import { buyArtPiece } from "@/tezos/bg_calls.js";
import { CONTRACTS } from "@/constants";
const signalR = require("@microsoft/signalr");

import { Pagination, EffectFade } from "swiper";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";

import "swiper/swiper-bundle.css";
SwiperCore.use([Pagination]);

export default {
  components: {
    PxCountdown,
    PxAlerts,
    Swiper,
    SwiperSlide,
  },
  name: "PxLimitedCollections",
  setup() {
    return {
      modules: [Pagination, EffectFade],
    };
  },
  data() {
    return {
      connection: null,
      swiperOptions: {
        slidesPerView: "auto",
        spaceBetween: 0,
        freeMode: true,
        scrollbar: true,
        mousewheel: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },

      store,
      contract_storage: undefined,
      supply: 0,
      price: 0,
      sale_price: 0,
      presale_price: 0,
      collected_pieces: 0,
      paused: false,
      auctionIsActive: true,

      previews: [
        {
          prev: "https://cdn.discordapp.com/attachments/987378128106168403/1113469193573113899/FxceTTvWIAAjP47.jpg",
        },
        {
          prev: " https://cdn.discordapp.com/attachments/987378128106168403/1113470260360777848/Sin-titulo-1.png",
        },
        {
          prev: "https://cdn.discordapp.com/attachments/987378128106168403/1113843070836945058/Frequency_of_Affection_9060622.png",
        },
        {
          prev: "https://cdn.discordapp.com/attachments/987378128106168403/1113842981846392953/Frequency_of_Affection_4259976.png",
        },
        {
          prev: "https://cdn.discordapp.com/attachments/987378128106168403/1113843121814503505/Frequency_of_Affection_9706598.png",
        },
      ],

      currentIndex: 0,
    };
  },

  methods: {
    async initSubscription() {
      // open connection
      await this.connection.start();
      // subscribe to account transactions
      await this.connection.invoke("SubscribeToOperations", {
        address: CONTRACTS.sales.address,
        types: "transaction",
      });
      await this.connection.invoke("SubscribeToBigMaps", {
        contract: CONTRACTS.sales.address,
      });
    },
    async buy() {
      this.$refs.alerts.toggleTransaction();
      try {
        const op = await buyArtPiece("testAuction", 1, this.price);
        if (op.completed) {
          this.$refs.alerts.showSuccessAlert("Transaction successfully");
        } else {
          this.$refs.alerts.showErrorAlert("Transaction failed");
        }
      } catch (e) {
        this.$refs.alerts.showErrorAlert(e.description);
      }
      this.$refs.alerts.toggleTransaction();
    },

    nextPrev() {
      this.previews[this.currentIndex].prev;
      this.currentIndex = (this.currentIndex + 1) % this.previews.length;
    },
    lastPrev() {
      this.previews[this.currentIndex].prev;
      if (this.currentIndex > 0) {
        this.currentIndex = (this.currentIndex - 1) % this.previews.length;
      }
    },

    startInterval() {
      setInterval(this.nextPrev, 8000);
    },

    goToPreview(index) {
      this.currentIndex = index;
      this.previews.forEach((preview, i) => {
        preview.selected = i === index;
      });
    },
  },
  created() {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl("https://api.tzkt.io/v1/ws")
      .build();

    this.connection.onclose(this.initSubscription);

    this.connection.on("operations", (msg) => {
      this.supply = msg.data[0].storage["ObjktSupply"]["testAuction"];

      if (msg.data[0].storage.ObjktState == 0) {
        this.paused = true;
      } else {
        this.paused = false;
      }
      if (msg.data[0].storage.ObjktState == 2) {
        this.price = msg.data[0].storage.ObjktPresalePrice;
      } else {
        this.price = msg.data[0].storage.ObjktPrice;
      }

      this.collected_pieces = Object.values(
        msg.data[0].storage["ObjktExchanges"]
      ).filter((x) => x == store.user_address).length;
    });

    this.initSubscription();

    get_contract_storage(CONTRACTS.sales.address).then((storage) => {
      this.contract_storage = storage;
      this.supply = storage["ObjktSupply"]["testAuction"];
      this.sale_price = storage["ObjktPrice"];
      this.presale_price = storage["ObjktPresalePrice"];

      if (storage["ObjktState"] === "2") {
        this.price = storage["ObjktPresalePrice"];
        this.paused = false;
      } else {
        this.price = storage["ObjktPrice"];
        this.paused = false;
        if (storage["ObjktState"] === "0") {
          this.paused = true;
        }
      }

      this.collected_pieces = Object.values(storage["ObjktExchanges"]).filter(
        (x) => x == store.user_address
      ).length;
    });
  },
};
</script>
<style>
#division-line {
  width: 43px;
  height: 0px;
  border: 3px solid #000000;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 1rem 0rem;
}

#limitedCollection-title {
  font-size: 3rem;
  font-weight: 700;
}

.specification-container {
  border: 1px solid #dcdcdc;
  padding: 1rem;
}

.specification-container p {
  font-size: 14px;
}

.specification-item {
  display: flex;
  gap: 0.5rem;
}

#specifications-column {
  padding: 1rem;
}

.art-prev {
  transition: ease-out 0.3s;
}

.art-prev:hover {
  transform: scale(1.5);
  border: 1px solid #ffff;
}

.countdown-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.prev-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.prev-container img {
  object-fit: contain;
  max-height: 100%;
  width: 100%;
  position: absolute;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 990px) {
  .prev-container img {
    object-fit: inherit;
    max-height: inherit;
    width: 100%;
    position: inherit;
  }

  .scrollable-row {
    overflow-x: scroll;
    white-space: nowrap;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .scroll-item {
    flex: 340px 0 0;
  }

  #specifications-column {
    margin-top: 1rem;
  }
}

@media (max-width: 425px) {
  .scrollable-row {
    overflow-x: scroll;
    white-space: nowrap;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .countdown-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .scroll-item {
    flex: 360px 0 0;
  }
}

@media (max-width: 375px) {
  .scroll-item {
    flex: 315px 0 0;
  }
}

@media (max-width: 320px) {
  .scroll-item {
    flex: 275px 0 0;
  }
}
</style>
